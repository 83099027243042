import React, {useEffect, useRef, useState, useMemo} from "react";
import {useLoader} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {connect} from "react-redux";
import action from "../../../store/action";
import * as THREE from 'three'
import {getGumData, getToothData} from "../../../dataInterface";
import {cloneDeep} from "lodash";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";


/**
 * @description create gum function. In order to batch creation gum
 * @param props The parameter passed by the attribute within the tag
 * */
const Attachment = React.memo(function Attachment(props) {
    const {attachmentCheckboxValue, opacity, url, fileType} = props

    const ref = useRef()

    //load model
    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })


    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            // matrix={toothMatrix}
            // matrixAutoUpdate={false}
            renderOrder={4}
            visible={attachmentCheckboxValue}
        >
            {/*<meshPhysicalMaterial*/}
            {/*    color={'#BBABFF'}*/}
            {/*    transparent={true}*/}
            {/*    opacity={opacity}*/}
            {/*/>*/}
            <meshStandardMaterial
                color={'#BBABFF'}
                opacity={opacity}
                transparent={true}
                depthWrite={opacity > 0.5 ? true : false}
                depthTest={true}
                roughness={0.4}
                metalness={0}
                reflectivity={0.1}
            />

        </mesh>
    )
})

const mapStateToProps = state => ({
    attachmentCheckboxValue: state.webViewer.attachmentCheckboxValue,
})

const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(Attachment)
