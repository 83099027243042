import {
    setGridCheckboxValue,
    setSliderValue,
    setView,
    setCurrentClickItemId,
    setKeyframeNumber,
    setOverlayCheckboxValue,
    setCollisionCheckboxValue,
    setToothData,
    setIPRCheckboxValue,
    setDragging,
    setIsDragCamera,
    setIsLoading,
    setPivotData,
    setAttachmentCheckboxValue,
    setCutoutsCheckboxValue,
    setPanelData,
    setIprData,
    setAttachmentData,
    setGumData,
    setCameraControlRef,
    setCurrentPatient,
    setCutoutsData,
    setHooksData,
    setPonticData,
    setPonticCheckboxValue,
    setLoadingData,
    setPopoverVisible,
    setCurrentClickItemRef,
    setEditMode,
    setMovementMode,
    setCurrentClickInitItemRef,
    setIsMobile,
    setBiteStopCheckboxValue,
    setBiteStopData,
    setCurrentClickPontic,
    setReduxMovementData,
    setStageCount,
    setDentalModels, setMovementData, setIsCombine
} from './webViewer/index'

import {
    setConfirmModalVisible,
    setSetupModalVisible,
    setTextAreaValue
} from './setup/index'

import {setCurrentToothInformation, setDataTool, setUpperOrLower} from "./dataTool";


export default {
    setGridCheckboxValue,
    setSliderValue,
    setView,
    setCurrentClickItemId,
    setKeyframeNumber,
    setOverlayCheckboxValue,
    setCollisionCheckboxValue,
    setToothData,
    setIPRCheckboxValue,
    setPonticCheckboxValue,
    setDragging,
    setIsDragCamera,
    setIsLoading,
    setPivotData,
    setAttachmentCheckboxValue,
    setCutoutsCheckboxValue,
    setPanelData,
    setIprData,
    setAttachmentData,
    setCutoutsData,
    setHooksData,
    setGumData,
    setCameraControlRef,
    setCurrentPatient,
    setPonticData,
    setLoadingData,
    setPopoverVisible,
    setCurrentClickItemRef,
    setEditMode,
    setMovementMode,
    setCurrentClickInitItemRef,
    setIsMobile,
    setBiteStopCheckboxValue,
    setBiteStopData,
    setCurrentClickPontic,
    setReduxMovementData,
    setConfirmModalVisible,
    setSetupModalVisible,
    setTextAreaValue,
    setStageCount,
    setDentalModels,
    setMovementData,
    setDataTool,
    setUpperOrLower,
    setCurrentToothInformation,
    setIsCombine
}


