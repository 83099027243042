import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLoader} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {connect} from "react-redux";
import action from "../../../store/action";
import * as THREE from 'three'
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";


/**
 * @description create gum function. In order to batch creation gum
 * @param props The parameter passed by the attribute within the tag
 * */
function Cutouts(props) {
    const {cutoutsCheckboxValue, opacity, toothNumber, isUpper, type, url, fileType} = props


    const ref = useRef()

    //load model
    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })

    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            // matrix={toothMatrix}
            // matrixAutoUpdate={false}
            renderOrder={1}
            visible={cutoutsCheckboxValue}
        >
            {/*<meshPhysicalMaterial*/}
            {/*    color={'cyan'}*/}
            {/*    // depthTest={false}*/}
            {/*    transparent={true}*/}
            {/*    opacity={opacity}*/}

            {/*/>*/}
            <meshStandardMaterial
                color={'cyan'}
                transparent={true}
                opacity={opacity}
                depthWrite={opacity > 0.5 ? true : false}
                depthTest={true}
                roughness={0.4}
                metalness={0}
                reflectivity={0.3}
            />

        </mesh>
    )
}

const mapStateToProps = state => ({
    cutoutsCheckboxValue: state.webViewer.cutoutsCheckboxValue
})

const mapDispatchToProps = dispatch => ({
    setCutoutsCheckboxValue: val => dispatch(action.setCutoutsCheckboxValue(val))
})


export default connect(mapStateToProps, mapDispatchToProps)(Cutouts)
