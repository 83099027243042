/*
* action     create function
*/
import Types from '../types';

/**
 * set grid checkbox value
 * @param {Boolean} bool
 */
export function setGridCheckboxValue(bool) {
    return { type: Types.GRID_CHECKBOX_VALUE_SET, gridCheckboxValue: bool };
}

/**
 * set overlay checkbox value
 * @param {Boolean} bool
 */
export function setOverlayCheckboxValue(bool) {
    return { type: Types.OVERLAT_CHECKBOX_VALUE_SET, overlayCheckboxValue: bool };
}

/**
 * set collision checkbox value
 * @param {Boolean} bool
 */
export function setCollisionCheckboxValue(bool) {
    return { type: Types.COLLISION_CHECKBOX_VALUE_SET, collisionCheckboxValue: bool };
}

/**
 * set IPR checkbox value
 * @param {Boolean} bool
 */
export function setIPRCheckboxValue(bool) {
    return { type: Types.IPR_CHECKBOX_VALUE_SET, IPRCheckboxValue: bool };
}

/**
 * set attachment Checkbox Value
 * @param {Boolean} bool
 */
export function setAttachmentCheckboxValue(bool) {
    return { type: Types.ATTACHMENT_CHECKBOX_VALUE_SET, attachmentCheckboxValue: bool };
}

/**
 * set cutouts Checkbox Value
 * @param {Boolean} bool
 */
export function setCutoutsCheckboxValue(bool) {
    return { type: Types.CUTOUTS_CHECKBOX_VALUE_SET, cutoutsCheckboxValue: bool };
}

/**
 * set pontic Checkbox Value
 * @param {Boolean} bool
 */
export function setPonticCheckboxValue(bool) {
    return { type: Types.PONTIC_CHECKBOX_VALUE_SET, ponticCheckboxValue: bool };
}

/**
 * set biteStop Checkbox Value
 * @param {Boolean} bool
 */
export function setBiteStopCheckboxValue(bool) {
    return { type: Types.BITESYOP_CHECKBOX_VALUE_SET, biteStopCheckboxValue: bool };
}


/**
 * set slider value
 * @param {Number} val
 */
export function setSliderValue(val) {
    return { type: Types.SLIDER_VALUE, sliderVal: val };
}

/**
 * set View
 * @param {String} val
 */
export function setView(val) {
    return { type: Types.VIEW, view: val };
}

/**
 * set currentClickItemId
 * @param {String} val
 */
export function setCurrentClickItemId(val) {
    return { type: Types.CURRENT_CLICK_ITEM_ID, currentClickItemId: val };
}

/**
 * set current Click Pontic
 * @param {String} val
 */
export function setCurrentClickPontic(val) {
    return { type: Types.CURRENT_CLICK_PONTIC, currentClickPontic: val };
}

/**
 * set current click item ref
 * @param {Object} val
 */
export function setCurrentClickItemRef(val) {
    return { type: Types.CURRENT_CLICK_ITEM_REF, currentClickItemRef: val };
}

/**
 * set current click init item ref
 * @param {Object} val
 */
export function setCurrentClickInitItemRef(val) {
    return { type: Types.CURRENT_CLICK_INIT_ITEM_REF, currentClickInitItemRef: val };
}

/**
 * set keyframe number
 * @param {String} val
 */
export function setKeyframeNumber(val) {
    return { type: Types.KEYFRAME_NUMBER, keyframeNumber: val };
}

/**
 * set tooth data
 * @param {String} val
 */
export function setToothData(val) {
    return { type: Types.TOOTH_DATA, toothData: val };
}

/**
 * set dragging
 * @param {Boolean} val
 */
export function setDragging(val) {
    return { type: Types.DRAGGING, dragging: val };
}

/**
 * set is drag camera
 * @param {Boolean} val
 */
export function setIsDragCamera(val) {
    return { type: Types.IS_DRAG_CAMERA, isDragCamera: val };
}

/**
 * set is loading
 * @param {Boolean} val
 */
export function setIsLoading(val) {
    return { type: Types.IS_Loading, isLoading: val };
}

/**
 * set pivot data
 * @param {Object} val
 */
export function setPivotData(val) {
    return { type: Types.PIVOT_DATA, pivotData: val };
}

/**
 * set panel data
 * @param {Array} val
 */
export function setPanelData(val) {
    return { type: Types.PANEL_DATA, panelData: val };
}

/**
 * set ipr data
 * @param {Array} val
 */
export function setIprData(val) {
    return { type: Types.IPR_DATA, iprData: val };
}

/**
 * set attachment data
 * @param {Object} val
 */
export function setAttachmentData(val) {
    return { type: Types.ATTACHMENT_DATA, attachmentData: val };
}

/**
 * set cutouts data
 * @param {Object} val
 */
export function setCutoutsData(val) {
    return { type: Types.CUTOUTS_DATA, cutoutsData: val };
}

/**
 * set hooks data
 * @param {Object} val
 */
export function setHooksData(val) {
    return { type: Types.HOOKS_DATA, hooksData: val };
}

/**
 * set biteStop data
 * @param {Object} val
 */
export function setBiteStopData(val) {
    return { type: Types.BITESTOP_DATA, biteStopData: val };
}

/**
 * set pontic data
 * @param {Array} val
 */
export function setPonticData(val) {
    return { type: Types.PONTIC_DATA, ponticData: val };
}

/**
 * set gum data
 * @param {Array} val
 */
export function setGumData(val) {
    return { type: Types.GUM_DATA, gumData: val };
}

/**
 * set camera control ref
 * @param {Object} val
 */
export function setCameraControlRef(val) {
    return { type: Types.CAMERA_CONTROL_REF, cameraControlRef: val };
}

/**
 * set current Patient
 * @param {Object} val
 */
export function setCurrentPatient(val) {
    return { type: Types.CURRENT_PATIENT, currentPatient: val };
}

/**
 * set loading data
 * @param {Boolean} val
 */
export function setLoadingData(val) {
    return { type: Types.LOADING_DATA, loadingData: val };
}

/**
 * set popover visible
 * @param {Boolean} val
 */
export function setPopoverVisible(val) {
    return { type: Types.POPOVER_VISIBLE, popoverVisible: val };
}

/**
 * set edit mode
 * @param {Boolean} val
 */
export function setEditMode(val) {
    return { type: Types.EDIT_MODE, editMode: val };
}

/**
 * set movement mode
 * @param {Boolean} val
 */
export function setMovementMode(val) {
    return { type: Types.MOVEMENT_MODE, movementMode: val };
}

/**
 * set is mobile
 * @param {Boolean} val
 */
export function setIsMobile(val) {
    return { type: Types.ISMOBILE, isMobile: val };
}

/**
 * set movement data
 * @param {Array} val
 */
export function setReduxMovementData(val) {
    return { type: Types.REDUX_MOVEMENT_DATA, reduxMovementData: val };
}

/**
 * set stage count
 * @param {number} val
 */
export function setStageCount(val) {
    return { type: Types.STAGE_COUNT, stageCount: val };
}

/**
 * set dental models
 * @param {Array} val
 */
export function setDentalModels(val) {
    return { type: Types.DENTAL_MODELS, dentalModels: val };
}

/**
 * set movement data
 * @param {Object} val
 */
export function setMovementData(val) {
    return { type: Types.MOVEMENT_DATA, movementData: val };
}

/**
 * set is combine
 * @param {Boolean} val
 */
export function setIsCombine(val) {
    return { type: Types.IS_COMBINE, isCombine: val };
}









