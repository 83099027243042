import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";
import action from "../../store/action";
import Option from "../Option";
import Scene from "../Scene";
import {Canvas} from "@react-three/fiber";
import JSZip from "jszip";
import {cloneDeep} from "lodash";
import WebDataTool from './Components/WebDataTool/index'
import ByteDataTool from "./Components/ByteDataTool";
import {message} from "antd";
import {useUpdateEffect} from "../../lib/customHooks";
import { useDispatch } from 'react-redux';
import {
    uploadPatientFileAdaption,
    uploadWebviewerPatientFileAdaption,
    getWebviewerModelAdaptionZip
} from '../../http/Adaption/apiAdapter'
import {getObjectByPatientsList, getPropertyByPatientsListObject} from "../../http/Adaption/queryData";
import {getEncryptDataFromPath} from "../../utils";
import { getMode } from '../../utils'
import { N8AO, EffectComposer } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'

/**
 * @description
 * Backup DataProcessing（./index_temp.js）
 *   New DataProcessing Changes
 *      1. Remove Simple Mock Data
 *      2. Remove useUpdateEffect
 *      3. Remove dataTool Relevance Judgment
 */

function DataProcessing(props) {

    const {
        currentPatient,
        stageCount,
        setLoadingData,
        setIsLoading,
        setCurrentClickItemId,
        currentDataTool,
        setCurrentPatient,
        setKeyframeNumber,
        isCombine,
        upperOrLower,
        buildTarget,
        setGridCheckboxValue,
        setOverlayCheckboxValue,
        setCollisionCheckboxValue,
        setIPRCheckboxValue,
        setAttachmentCheckboxValue,
        setCutoutsCheckboxValue,
        setPonticCheckboxValue,
        setBiteStopCheckboxValue,
    } = props

    const [sceneKey, setSceneKey] = useState(0);

    const dispatch = useDispatch();
    const [isUpdated, setIsUpdated] = useState(false)

    const [superimpositionMatrixList, setSuperimpositionMatrixList] = useState([])

    const [dentalData, setDentalData] = useState([])

    const [fileData, setFileData] = useState(new Map())

    const [mexicaliData, setMexicaliData] = useState([])

    const [smarteeOrByteData, setSmarteeOrByteData] = useState([])

    const [currentRequest, setCurrentRequest] = useState(1)

    const [requestEnd, setRequestEnd] = useState(false)

    const [patientsList, setPatientsList] = useState([])

    const [stageList, setStageList] = useState({})

    const [byteStageList, setByteStageList] = useState({})

    /**
     * @description    Synchronize the onload method
     * @param {Blob} blob
     * */
    const loadSync = (blob) => {
        return new Promise((resolve, reject)=> {
            // convert to base64
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            let raw = ''
            reader.onload = function () {
                // delete 'data:application/octet-stream;base64', Because it needs to be properly converted to buffer
                raw = reader.result.replace("data:application/octet-stream;base64,", '')
                resolve(raw)
            };
        })
    }

    /**
     * @description    Reading files
     * @param {Object} zip
     * */
    const readFiles = async (zip) => {
        const fileMap = new Map();
        for (let file in zip.files) {
            // console.log('>>>---', file)
            // In order to skip a folder. (file contains folders)
            if (!zip.files[file].dir) {
                await zip.file(file).async("arraybuffer")
                    .then(async content => {
                        let blob2 = new Blob([content])
                        if(sessionStorage.getItem('dataTool') === '1' || currentDataTool.key === 1) {
                            if (file.includes('info.json')) {
                                // console.log('>>>info.json', file)
                                let obj = await loadByteJson(blob2)
                                detectPattern(file, obj, fileMap)
                            } else if(file !== 'config.json'){
                                // console.log('>>>config.json', file)
                                let modelUrl = window.URL.createObjectURL(blob2)
                                detectPattern(file, modelUrl, fileMap)
                            }
                        }
                        else {
                            if (file.includes('.raw')) {
                                fileMap.set(file,  await loadSync(blob2))
                            } else {
                                let modelUrl = window.URL.createObjectURL(blob2)
                                fileMap.set(file, modelUrl)

                            }
                        }

                    })

            }
        }
        return fileMap
    }

    /**
     * @description    Create Stage Json Data
     * */
    const createStageInfoData = async (zip) => {
        let stage = {}
        const stageFolderContents = []
        // Get stage folder files name arr
        zip.forEach((relativePath, zipEntry) => {
            if(zipEntry.dir && relativePath.includes('STAGE')) {
                const fileNames = []
                zip.folder(relativePath).forEach((relativeFilePath, fileEntry) => {
                    fileNames.push(relativeFilePath)
                })
                stageFolderContents.push({
                    folder: relativePath,
                    fileNames: fileNames
                })
            }
        })
        stageFolderContents.forEach(item => {
          let num = Number(item.folder.replace(currentPatient.fileName, '').replace(currentPatient.fileName, '').match(/\d+/)[0])
          stage[`stage${num}`] = {
            upper: {
              visible: item.fileNames.some(name => name.includes('__UPPER__'))
            },
            lower: {
              visible: item.fileNames.some(name => name.includes('__LOWER__'))
            }
          }
        })
        return stage
    }

    const createPatientListData = (myMap) => {
        const filePath = myMap.keys().next().value
        const { path } = getEncryptDataFromPath()
        const patientListData = [{
            fileName: filePath.split('/')[0],
            id: 0,
            // Note: candid defualt uuid
            uuid: "uuid", // path.split("/").pop().split('.')[0]
        }]
        setPatientsList(patientListData)
        if(sessionStorage.getItem('listItemId') !== null) {
            let params = {id: sessionStorage.getItem('listItemId')}
            const currentData = getObjectByPatientsList(params, patientListData);
            setCurrentPatient(currentData)
        }else {
            const currentData = patientListData[0]
            setCurrentPatient(currentData)
            let id = getPropertyByPatientsListObject('id', currentData)
            sessionStorage.setItem('listItemId', id)
        }
    }
    /**
     * @description   get data from interface.
     *                1. Decompress the archive and convert it into a format based on its contents, such as stl files to URl and raw files to base64
     *                2. format data.
     *                3.Read data bitwise according to the functions defined by the dataConversion.
     * */
    const getData = async () => {
        setSceneKey((pre) => pre + 1)

        let fileMap = new Map();
        let stageInfo = {}
        //set loading. when reload model, we need reset our redux param and component param
        await setCurrentClickItemId(null)
        await setIsUpdated(false)
        await setLoadingData(true)

        let modelData;

        if(buildTarget === "webviewer") {
            let result = await getWebviewerModelAdaptionZip()
            if (null != result) modelData = result.data
        }

        if(modelData === null) {
            setIsUpdated(false)
            setIsLoading(false)
            setCurrentRequest(4)
            return
        }

        try {
            // zip.js loads the file stream to generate the corresponding file
            let blob = new Blob([modelData])
            const zip = new JSZip();
            await zip.loadAsync(blob)
            stageInfo = await createStageInfoData(zip)
            fileMap = await readFiles(zip)
            createPatientListData(fileMap)
            setCurrentRequest(4)
        }
        catch (err) {
            setIsUpdated(false)
            setIsLoading(false)
            setCurrentRequest(4)
        }
        setStageList(stageInfo)
        setFileData(fileMap)
    }

    /**
     * @description   load byte json
     * @param {Blob} blob
     * */
    const loadByteJson = (file) => {

        return new Promise((resolve, reject)=> {
            // convert to base64
            let reader = new FileReader();

            let jsonData = ''

            reader.onload = function (e) {
                // delete 'data:application/octet-stream;base64', Because it needs to be properly converted to buffer
                jsonData = JSON.parse(e.target.result)

                resolve(jsonData)
            };

            reader.readAsText(file);

        })
    }

    /**
     * @description    Reading byte files
     * @param {Object} zip
     * */


    const detectPattern = (str, value, data) => {
        if(currentPatient.mode === 0) {
            return formatMexicaliData(str, value, data)
        }else {
            return switchByteOrSmarteeDataType(str, value, data)
        }
    }

    const formatMexicaliData = (key, value, fileMap) => {


        const regex = /\d+/g
        const num = regex.exec(key.replace(currentPatient.fileName, '').replace(currentPatient.fileName, ''))[0]

        console.log(fileMap, num)
        if(fileMap.has('stage' + num)) {
            let resValue = fileMap.get('stage' + num)

            let upperOrLowerItem = resValue.get(key.includes('upper') ? 'upper' : 'lower')

            let keySplit = key.split('/')
            upperOrLowerItem.set(keySplit[keySplit.length - 1], value)


        }else {
            let resItem = new Map()

            if(key.includes('upper')) {
                let keySplit = key.split('/')

                resItem.set('upper', new Map().set(keySplit[keySplit.length - 1], value))
                resItem.set('lower', new Map())

            }else if(key.includes('lower')) {
                let keySplit = key.split('/')
                resItem.set('upper', new Map())
                resItem.set('lower', new Map().set(keySplit[keySplit.length - 1], value))
            }

            fileMap.set('stage' + num, resItem)

        }

    }

    const switchByteOrSmarteeDataType = (key, value, fileMap) => {
        fileMap.set(key,value)
    }


    const fetchData = async () => {
        await getData()
        setRequestEnd(true)
    }

    // click upload button
    const loadDataHandler = (file) => {
        const promises = file.map((item)=>{
            return new Promise(async (resolve,reject)=> {
                let params = new FormData();
                params.append('file', item);
                let code;
                console.log(buildTarget, 'buildTarget')
                if(buildTarget === "webviewer") {
                    code = await uploadWebviewerPatientFileAdaption(params, item.name)
                }
                else {
                    code = uploadPatientFileAdaption(params, item.name)
                }

                if(code == 200) {
                    resolve(true)
                }

            })
        })

        Promise.all(promises)
            .then(res=> {
                // window.alert("ooooooooooo")
                console.log(res)
                setTimeout(()=> {
                    // fetchPatientListData(4)
                }, 2000)

            })
            .catch(err=> {
                console.log(err)
            })
    }

    const handleDataChange = (newData) => {

        setDentalData(cloneDeep(newData))
    }

    const setDentalDataHandler = async (val) => {

        await setDentalData(cloneDeep(val))

        setTimeout(()=> {
            setIsUpdated(true)
        }, 1000)
    }

    const setByteDataHandler = async (val) => {
        await setMexicaliData(val)
        setIsUpdated(true)
    }

    const setSmarteeOrByteDataHandler = async (val) => {
        await setSmarteeOrByteData(val)
        setIsUpdated(true)
    }

    const clickCombineOrSeparate = async () => {

        setIsUpdated(false)

        setCurrentRequest(2)
    }

    // click the patient list open button
    const loadModel = async () => {
        setIsLoading(true)
        setIsUpdated(false)
        setCurrentRequest(2)
    }

    /**
     * @description Layer - Enable 'Attachment' 'Pontic' 'Bite Stop' in Read-Only mode
     */
    const initLayer = () => {
        setGridCheckboxValue(false)
        setOverlayCheckboxValue(false)
        setCollisionCheckboxValue(false)
        setIPRCheckboxValue(false)
        setAttachmentCheckboxValue(true)
        setCutoutsCheckboxValue(false)
        setPonticCheckboxValue(true)
        setBiteStopCheckboxValue(true)
    }
    

    useEffect(async () => {
        setIsLoading(true)
        try {
            setKeyframeNumber(0)
        }
        catch (e) {
            setIsLoading(false)
            message.error(e.code)
        }
    }, []);

    useEffect( () => {
        setFileData(new Map())
        console.log(currentRequest, "currentRequest")
        fetchData()
    }, [currentRequest]);

    useEffect(() => {
        const { readOnlyMode } = getMode()
        if(readOnlyMode) {
            console.log('...Read-Only Mode, Init Layer')
            initLayer()
        }
    }, [])

    return (
        <>
            {/* Determine which component to use to format the data based on the file identifier */}
            {
                currentDataTool.key !== 1 && requestEnd ? (
                    <WebDataTool getDentalData={setDentalDataHandler} fileData={fileData} />
                ) : currentDataTool.key === 1 && requestEnd ? (
                    <ByteDataTool getByteData={setByteDataHandler} getSmarteeOrByteData={setSmarteeOrByteDataHandler} fileData={fileData} />
                ) : <></>
            }
            <Option
                isUpdated={isUpdated}
                dentalData={dentalData}
                clickCombineOrSeparate={clickCombineOrSeparate}
                setDentalData={handleDataChange}
                setPatientsList={setPatientsList}
                patientsList={patientsList}
                stageList={stageList}
                byteStageList={byteStageList}
                setCurrentRequest={setCurrentRequest}
                loadDataHandler={loadDataHandler}
                loadModel={loadModel}
                buildTarget={buildTarget}
            />
            {
                <Canvas key={sceneKey} style={{width: '100vw', height: '100vh'}} className={'canvasStyle'} id={'display'} gl={{logarithmicDepthBuffer: true}} tabIndex={0}>
                    {
                        requestEnd ? (
                            <Scene
                                isUpdated={isUpdated}
                                mexicaliData={mexicaliData}
                                setMexicaliData={setMexicaliData}
                                smarteeOrByteData={smarteeOrByteData}
                                dentalData={cloneDeep(dentalData)}
                                superimpositionMatrixList={superimpositionMatrixList}
                                setDentalData={handleDataChange}
                            />
                        ) : <></>
                    }
                    <EffectComposer disableNormalPass multisampling={4}>
                        <N8AO aoRadius={20} aoSamples={64} denoiseSamples={16} denoiseRadius={8} distanceFalloff={0.2} intensity={8} screenSpaceRadius halfRes />
                    </EffectComposer>
                </Canvas>
            }
        </>
    );
}

const mapStateToProps = state => ({
    stageCount: state.webViewer.stageCount,
    currentClickItemId: state.webViewer.currentClickItemId,
    isMobile: state.webViewer.isMobile,
    currentPatient: state.webViewer.currentPatient,
    currentDataTool: state.dataTool.currentDataTool,
    isCombine: state.webViewer.isCombine,
    upperOrLower: state.dataTool.upperOrLower,
})

const mapDispatchToProps = dispatch => ({
    setLoadingData: val => dispatch(action.setLoadingData(val)),
    setIsLoading: val => dispatch(action.setIsLoading(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
    setCurrentPatient: val => dispatch(action.setCurrentPatient(val)),
    setKeyframeNumber: val => dispatch(action.setKeyframeNumber(val)),
    setGridCheckboxValue: val => dispatch(action.setGridCheckboxValue(val)),
    setOverlayCheckboxValue: val => dispatch(action.setOverlayCheckboxValue(val)),
    setCollisionCheckboxValue: val => dispatch(action.setCollisionCheckboxValue(val)),
    setIPRCheckboxValue: val => dispatch(action.setIPRCheckboxValue(val)),
    setAttachmentCheckboxValue: val => dispatch(action.setAttachmentCheckboxValue(val)),
    setCutoutsCheckboxValue: val => dispatch(action.setCutoutsCheckboxValue(val)),
    setPonticCheckboxValue: val => dispatch(action.setPonticCheckboxValue(val)),
    setBiteStopCheckboxValue: val => dispatch(action.setBiteStopCheckboxValue(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataProcessing)
