// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*css init */
html,
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
}
fieldset,
img,
input,
button {
  border: none;
  padding: 0;
  margin: 0;
  outline-style: none;
}
ul,
ol {
  list-style: none;
}
input {
  padding-top: 0;
  padding-bottom: 0;
}
select,
input {
  vertical-align: middle;
}
select,
input,
textarea {
  font-size: 12px;
  margin: 0;
}
textarea {
  resize: none;
}
img {
  border: 0;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
body {
  font-size: 12px;
  color: #666;
  background: #fff;
}
.clearfix:before {
  content: "";
  display: block;
  clear: both;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  font-weight: normal;
  font-size: 100%;
}
s,
i,
em {
  font-style: normal;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/style/base.less"],"names":[],"mappings":"AAAA,YAAY;AAEZ;;;;;;;;;;;;;;;;;;;EACE,SAAA;EACA,UAAA;AAkBF;AAdA;;EACE,kMAAA;EACA,yBAAA;EACA,2BAAA;EACA,iBAAA;AAiBF;AAdA;;;;EACE,YAAA;EACA,UAAA;EACA,SAAA;EACA,mBAAA;AAmBF;AAhBA;;EACE,gBAAA;AAmBF;AAhBA;EACE,cAAA;EACA,iBAAA;AAkBF;AAfA;;EACE,sBAAA;AAkBF;AAfA;;;EACE,eAAA;EACA,SAAA;AAmBF;AAhBA;EACE,YAAA;AAkBF;AAdA;EACE,SAAA;EACA,sBAAA;AAgBF;AAbA;EACE,yBAAA;AAeF;AAXA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;AAaF;AAVA;EACE,WAAA;EACA,cAAA;EACA,WAAA;AAYF;AARA;EACE,qBAAA;AAUF;AANA;;;;;;EACE,qBAAA;EACA,mBAAA;EACA,eAAA;AAaF;AAVA;;;EACE,kBAAA;EACA,qBAAA;AAcF","sourcesContent":["\n/*css init */\nhtml, body, ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img {\n  margin: 0;\n  padding: 0;\n}\n\n\nhtml, body {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';\n  -webkit-user-select: none;\n  -webkit-touch-callout: none;\n  user-select:none;\n}\n\nfieldset, img, input, button {\n  border: none;\n  padding: 0;\n  margin: 0;\n  outline-style: none;\n}\n\nul, ol {\n  list-style: none;\n}\n\ninput {\n  padding-top: 0;\n  padding-bottom: 0;\n}\n\nselect, input {\n  vertical-align: middle;\n}\n\nselect, input, textarea {\n  font-size: 12px;\n  margin: 0;\n}\n\ntextarea {\n  resize: none;\n}\n\n\nimg {\n  border: 0;\n  vertical-align: middle;\n}\n\ntable {\n  border-collapse: collapse;\n}\n\n\nbody {\n  font-size: 12px;\n  color: #666;\n  background: #fff\n}\n\n.clearfix:before {\n  content: \"\";\n  display: block;\n  clear: both;\n}\n\n\na {\n  text-decoration: none;\n}\n\n\nh1, h2, h3, h4, h5, h6 {\n  text-decoration: none;\n  font-weight: normal;\n  font-size: 100%;\n}\n\ns, i, em {\n  font-style: normal;\n  text-decoration: none;\n}\n\n\n\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
