import React, {useRef, useState, useEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
import { Input } from 'antd';
import action from '../../../../store/action/index'
import { DownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import Icon from '@ant-design/icons';
import ArchPopover from "../../../../Components/ArchPopover";
import DragModal from '../../../../Components/DragModal/index'
import eventBus from '../../../../lib/eventEmitter'
import {setConfirmModalVisible, setSetupModalVisible} from "../../../../store/action/setup";
import HintModal from '../../../../Components/HintModal/index'

const { TextArea } = Input;

function Setup(props) {

    const {
        isMobile,
        confirmModalVisible,
        setupModalVisible,
        textAreaValue,
        setConfirmModalVisible,
        setSetupModalVisible,
        setTextAreaValue
    } = props

    const text1 = ['Accept this setup version']
    const text2 = [
        `Decline this setup version <br/>and leave a comment`
    ]

    /**
     * @description   The following code is about the Setup needs changes.
     * */
    const [isTrigger, setIsTrigger] = useState(false)

    // open modal
    const clickSetupNeedsChangesBtn = () => {
        setSetupModalVisible(true)

    }

    // close modal
    const cancelHandler = () => {

        if(textAreaValue !== '') {
            setConfirmModalVisible(true)
        }else {
            setSetupModalVisible(false)
        }

    }

    const confirmHandler = () => {
        setSetupModalVisible(false)
        setConfirmModalVisible(false)

        if(isTrigger) {

            eventBus.emit('confirmSwitch')

            setIsTrigger(false)
        }
    }


    const confirmModalCancelHandler = () => {
        setConfirmModalVisible(false)

        setIsTrigger(false)
    }

    useEffect(() => {

        if(textAreaValue !== '' && isTrigger) {
            setConfirmModalVisible(true)

        }else if(textAreaValue === '' && isTrigger) {
            setSetupModalVisible(false)
            setIsTrigger(false)
        }

    }, [isTrigger])

    useEffect(() => {
        if(textAreaValue !== '') {
            eventBus.emit('verifySwitch')
        }
    }, [textAreaValue]);


    useEffect(() => {

        eventBus.addListener('switchPatient', ()=> {
            setIsTrigger(true)
        })

        return () => {
            eventBus.removeListener('', () => {})
        }

    }, []);


    useEffect(()=> {
        if(textAreaValue.length === 1) {
            window.onbeforeunload = function (e) {
                e.preventDefault()

                if(textAreaValue !== '') {
                    e.returnValue = '123333'
                    // onbeforeunload The returnValue property is no longer allowed under the new rules
                }
            };
        }else if(textAreaValue.length === 0) {
            window.onbeforeunload = null
        }
    }, [textAreaValue])


    return (
        <>
            <div className={"setupBox"}  style={{display: isMobile ? 'none' : 'block'}}>
                <div className={'setupWrapper'}>
                    <div className={'setupContainer'}>
                        <ArchPopover right={'-10px'} top={'-10px'} width={'310px'} height={'25px'}
                                     placement={'bottomLeft'} overlayClassName={'acceptPopover'} text={text1}/>
                        <div className={'setupTitle'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                            <Icon component={CheckCircleOutlined} style={{marginRight: '4px'}} />
                            <span>Accept Setup</span>
                        </div>
                    </div>
                    <div className={'setupContainer'} style={{width: '185px', marginLeft: '12px'}} onClick={clickSetupNeedsChangesBtn}>
                        <ArchPopover right={'-10px'} top={'-10px'} width={'400px'} height={'35px'}
                                     placement={'bottomLeft'} overlayClassName={'setupPopover'} text={text2}/>
                        <div className={'setupTitle'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                            <Icon component={CloseCircleOutlined} style={{marginRight: '4px'}} />
                            Setup Needs Changes
                        </div>
                    </div>
                </div>

            </div>
            <DragModal visible={setupModalVisible} wrapClassName={'setupModal'} width={'301px'} titleText={'Setup Needs Changes'} target={'.setupModal .ant-modal-content'}>
                <TextArea
                    value={textAreaValue}
                    onChange={(e) => setTextAreaValue(e.target.value)}
                    placeholder="Please leave comments about how do you wish to change this setup."
                    autoSize={{
                        minRows: 10,
                        maxRows: 10,
                    }}
                />
                <div className={'buttonGroup'}>
                    <div onClick={cancelHandler}>
                        Cancel
                    </div>
                    <div>
                        Submit
                    </div>
                </div>
            </DragModal>
            <HintModal
                visible={confirmModalVisible}
                okButtonHandler={confirmHandler}
                cancelHandler={confirmModalCancelHandler}
                okButtonText={'Yes'}
                content={'Detected uncommitted messages, still want to close the window?'}
                titleText={'Warning'}
            />
        </>
    );
}

const mapStateToProps = state => ({
    isMobile: state.webViewer.isMobile,
    confirmModalVisible: state.setup.confirmModalVisible,
    setupModalVisible: state.setup.setupModalVisible,
    textAreaValue: state.setup.textAreaValue,
})

const mapDispatchToProps = dispatch => ({
    setConfirmModalVisible: val => dispatch(action.setConfirmModalVisible(val)),
    setSetupModalVisible: val => dispatch(action.setSetupModalVisible(val)),
    setTextAreaValue: val => dispatch(action.setTextAreaValue(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Setup)
