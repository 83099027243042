
/**
 * @description
 * */
export const isDataExist = (data) => {

    if(gettype(data) === 'Array') {
        return data.length === 0 ? false : true
    }else if(gettype(data) === 'Object') {
        return Object.getOwnPropertyNames(data).length === 0 ? false : true
    }
}

/**
 * @description    Determine the data type accurately
 * */
export const gettype = (obj) => {
    let type = typeof obj;

    if (type !== 'object') {
        return type;
    }

    return Object.prototype.toString.call(obj).replace(/^\[object (\S+)\]$/, '$1');
}

/**
 * @description
 * */
export const verifyUpperOrLower = (bool) => {
    return bool ? 0 : 1
}
