import { combineReducers } from 'redux';
import webViewer from './webViewer/index'
import setup from './setup/index'
import dataTool from './dataTool/index'

/**
 *
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const index = combineReducers({
  webViewer: webViewer,
  setup: setup,
  dataTool
});

export default index;
