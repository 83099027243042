/*
* action
*/

export default {
  RESET_APP: 'RESET_APP',
  // grid checkbox value
  GRID_CHECKBOX_VALUE_SET: 'GRID_CHECKBOX_VALUE_SET',
  SLIDER_VALUE: 'SLIDER_VALUE',
  VIEW: 'VIEW',
  CURRENT_CLICK_ITEM_ID: 'CURRENT_CLICK_ITEM_ID',
  CURRENT_CLICK_PONTIC: 'CURRENT_CLICK_PONTIC',
  CURRENT_CLICK_ITEM_REF: 'CURRENT_CLICK_ITEM_REF',
  CURRENT_CLICK_INIT_ITEM_REF: 'CURRENT_CLICK_INIT_ITEM_REF',
  KEYFRAME_NUMBER: 'KEYFRAME_NUMBER',
  OVERLAT_CHECKBOX_VALUE_SET: 'OVERLAT_CHECKBOX_VALUE_SET',
  COLLISION_CHECKBOX_VALUE_SET: 'COLLISION_CHECKBOX_VALUE_SET',
  IPR_CHECKBOX_VALUE_SET: 'IPR_CHECKBOX_VALUE_SET',
  ATTACHMENT_CHECKBOX_VALUE_SET: 'ATTACHMENT_CHECKBOX_VALUE_SET',
  CUTOUTS_CHECKBOX_VALUE_SET: 'CUTOUTS_CHECKBOX_VALUE_SET',
  PONTIC_CHECKBOX_VALUE_SET: 'PONTIC_CHECKBOX_VALUE_SET',
  BITESYOP_CHECKBOX_VALUE_SET: 'BITESYOP_CHECKBOX_VALUE_SET',
  TOOTH_DATA: 'TOOTH_DATA',
  DRAGGING:'DRAGGING',
  IS_DRAG_CAMERA: 'IS_DRAG_CAMERA',
  IS_Loading: 'IS_Loading',
  PIVOT_DATA: 'PIVOT_DATA',
  PANEL_DATA: 'PANEL_DATA',
  IPR_DATA: 'IPR_DATA',
  ATTACHMENT_DATA: 'ATTACHMENT_DATA',
  CUTOUTS_DATA: 'CUTOUTS_DATA',
  HOOKS_DATA: 'HOOKS_DATA',
  PONTIC_DATA: 'PONTIC_DATA',
  BITESTOP_DATA: 'BITESTOP_DATA',
  GUM_DATA: 'GUM_DATA',
  CAMERA_CONTROL_REF: 'CAMERA_CONTROL_REF',
  CURRENT_PATIENT: 'CURRENT_PATIENT',
  LOADING_DATA: 'LOADING_DATA',
  POPOVER_VISIBLE: 'POPOVER_VISIBLE',
  EDIT_MODE: 'EDIT_MODE',
  MOVEMENT_MODE: 'MOVEMENT_MODE',
  ISMOBILE: 'ISMOBILE',
  REDUX_MOVEMENT_DATA: "REDUX_MOVEMENT_DATA",
  CONFIRM_MODAL_VISIBLE: 'CONFIRM_MODAL_VISIBLE',
  SETUP_MODAL_VISIBLE: 'SETUP_MODAL_VISIBLE',
  TEXTAREA_VALUE: 'TEXTAREA_VALUE',
  STAGE_COUNT: 'STAGE_COUNT',
  DENTAL_MODELS: 'DENTAL_MODELS',
  MOVEMENT_DATA: 'MOVEMENT_DATA',
  DATA_TOOL: 'DATA_TOOL',
  UPPER_OR_LOWER: 'UPPER_OR_LOWER',
  CURRENT_TOOTH_INFORMATION: 'CURRENT_TOOTH_INFORMATION',
  IS_COMBINE: 'IS_COMBINE'
}
