import Types from '../../action/types';

// Define a default value for the state, and be careful to be consistent with the values of the data structures here
const defaultState = {
    confirmModalVisible: false,
    setupModalVisible: false,
    textAreaValue: ''
}

/**
 * action handlers，The processing action returns the state
 */
export default function setup(state = defaultState, action) {
    switch (action.type) {
        case Types.CONFIRM_MODAL_VISIBLE:
            return  {
                ...state,
                confirmModalVisible: action.confirmModalVisible
            }
        case Types.SETUP_MODAL_VISIBLE:
            return  {
                ...state,
                setupModalVisible: action.setupModalVisible
            }
        case Types.TEXTAREA_VALUE:
            return  {
                ...state,
                textAreaValue: action.textAreaValue
            }
        default:
            return state;
    }
}
