/*
* action     create function
*/
import Types from '../types';


/**
 * set data tool
 * @param {Object} val
 */
export function setDataTool(val) {
    return { type: Types.DATA_TOOL, currentDataTool: val };
}

/**
 * set upper or lower
 * @param {Object} val
 */
export function setUpperOrLower(val) {
    return { type: Types.UPPER_OR_LOWER, upperOrLower: val };
}

/**
 * set current tooth information
 * @param {Object} val
 */
export function setCurrentToothInformation(val) {
    return { type: Types.CURRENT_TOOTH_INFORMATION, currentToothInformation: val };
}

