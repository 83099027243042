import React, {useEffect, createContext, useState} from 'react'
import {connect} from "react-redux";
import action from "../../../../store/action";
import {cloneDeep} from "lodash";
import {computeModalData} from "../../../../lib/threeLib";
import {
    createGumMatrix,
    createIPRData,
    createNumbersData,
    createTeethMatrices,
    createVisibilityData
} from "../../../../lib/dataConversion";
import * as THREE from 'three'


function ByteDataTool(props) {

    const {
        currentPatient,
        stageCount,
        setLoadingData,
        fileData,
        getByteData,
        getSmarteeOrByteData
    } = props

    let defaultData = [
        {
            isUpper: true,
            gum: {
                matrixList: [],
                urlList: [],
                tooth: []
            }
        },
        {
            isUpper: false,
            gum: {
                matrixList: [],
                urlList: [],
                tooth: []
            }
        }
    ]

    const [mexicaliFormatData, setMexicaliDataFormatData] = useState(cloneDeep(defaultData))



    const formatMexicaliData = (data) => {
        let copyData = cloneDeep(defaultData)

        for(let [key, value] of data.entries()) {

            let returnUpperData = mexicaliData(value.get('upper'))
            let returnLowerData = mexicaliData(value.get('lower'))


            copyData[0].gum.tooth.push(returnUpperData.toothList)
            copyData[0].gum.urlList.push(returnUpperData.gumData)
            copyData[1].gum.tooth.push(returnLowerData.toothList)
            copyData[1].gum.urlList.push(returnLowerData.gumData)

        }

        setMexicaliDataFormatData(copyData)

        getByteData(copyData)

    }

    const mexicaliData = (data) => {

        const toothList = []

        const gumData = {
            url: '',
            fileType: 'stl'
        }

        for(let [key, value] of data.entries()) {


            if(key.includes('gingiva.')) {
                const regex = /(\.drc$)|(\.stl$)|(\.obj$)/

                gumData.url = value
                gumData.fileType = key.match(regex)[0].replace('.','')

            }else if(key.match(/(\d+)./)) {
                const regex = /(\.drc$)|(\.stl$)|(\.obj$)/

                const toothObj = {
                    url: value,
                    fileName: key,
                    fileType: key.match(regex)[0].replace('.',''),
                    center: [],
                    axis: [],
                    visible: true,
                    isPontic: false,
                    number: 18,
                    movement: {},
                }

                toothList.push(toothObj)
            }
        }


        getToothDataByJson(data.get('info.json'), toothList)

        return {
            toothList,
            gumData
        }
    }

    const getToothDataByJson = (jsonData, data) => {


        data.map(item=> {
            jsonData.teeth.map(val=> {

                if(val !== null && val['tooth_file'] === item.fileName) {
                    item.number = val.tooth_iso_number
                    item.isPontic = val.is_pontic
                    item.axis = val.axis
                    item.center = val.center
                }
            })
        })
    }

    const formatByteOrSmarteeData = (data) => {
        let array = [
            {
                isUpper: true,
                data: []
            },
            {
                isUpper: false,
                data: []
            }
        ]

        for(let [key, value] of data.entries()) {
            const regex = /\d+/g

            const num = regex.exec(key.replace(currentPatient.fileName, '').replace(currentPatient.fileName, ''))[0]

            const regex2 = /(\.drc$)|(\.stl$)|(\.obj$)/
            let fileType = key.match(regex2)[0].replace('.','')

            let isUpper = key.includes('_upper_') || key.includes('_UPPER_')

            let obj = {
                url: value,
                fileType,
                stage: num
            }

            let subscript = isUpper ? 0 : 1

            array[subscript].data.push(obj)
        }

        getSmarteeOrByteData(array)
    }

    useEffect(() => {
        console.log(fileData,'>>>>')
        if(currentPatient.mode === 0 && fileData && fileData.size !== 0) {
            console.log('hhlolo', fileData)
            formatMexicaliData(fileData)
        }else if(currentPatient.mode > 0 && fileData.size !== 0) {
            formatByteOrSmarteeData(fileData)
        }
    }, [fileData]);





    return (
        <></>
    );
}

const mapStateToProps = state => ({
    stageCount: state.webViewer.stageCount,
    currentClickItemId: state.webViewer.currentClickItemId,
    isMobile: state.webViewer.isMobile,
    currentPatient: state.webViewer.currentPatient,
})

const mapDispatchToProps = dispatch => ({
    setLoadingData: val => dispatch(action.setLoadingData(val)),
    setIsLoading: val => dispatch(action.setIsLoading(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ByteDataTool)
