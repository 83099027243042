import React, {useRef, useState, useEffect, useCallback, useMemo} from "react";
import {Line, PivotControls, Center} from "@react-three/drei";
import {connect} from "react-redux";
import action from "../../../store/action";
import {
    getGumData, getGumMatrix,
    getToothData,
    getToothMatrix,
    getToothMatrixByNumber,
    setToothMatrixAndMovementHandler
} from '../../../dataInterface/index'
import * as THREE from 'three'
import {useFrame} from "@react-three/fiber";
import {cloneDeep} from "lodash";
import {computeModalData} from "../../../lib/threeLib";
import {setMovementData} from "../../../store/action/webViewer";


/**
 * @description create tooth function. In order to batch creation tooth
 * @param props The parameter passed by the attribute within the tag
 * */
function Gizmo(props) {
    /**
     * @param itemId String    component itemId
     * @param url String     model path
     * @param color String     model material color
     * */
    const {
        currentClickItemId,
        setDragging,
        keyframeNumber,
        cameraControlRef,
        modelRef,
        data,
        setDentalData,
        setMovementData,
        editMode
    } = props

    const pivotRef = useRef()

    const [hoverColor, setHoverColor] = useState('yellow')

    const dragMat = useRef()

    const saveMovement = useRef()

    const [toothData, setToothData] = useState([])

    const [gumData, setGumData] = useState([])

    const [toothMatrix, setCompToothMatrix] = useState(new THREE.Matrix4())

    useEffect(()=> {
        let isUpper = true

        if(currentClickItemId < 30) {
            isUpper = true
        }else {
            isUpper = false
        }

        let copyGumData = cloneDeep(getGumData(data, isUpper))

        let copyToothData = cloneDeep(getToothData(copyGumData))

        setGumData(copyGumData)

        setToothData(copyToothData)

        setCompToothMatrix(getToothMatrixByNumber(copyToothData, keyframeNumber, currentClickItemId))

    }, [currentClickItemId])


    /**
     * @description   Drag gizmos
     * */
    const dragPivot = (l, d, w, dw) => {
        cameraControlRef.cancel()

        //Change data in the Movement panel in real time
        // let cloneData = cloneDeep(panelData)
        // cloneData[keyframeNumber][currentClickItemId].mat4 = w
        // setPanelData(cloneData)   // In order to show current data

        // change hover color
        setHoverColor('')

        // Record the last matrix
        dragMat.current = w

        // Sync the data into toothData in redux
        console.log(modelRef.current.children[0].children[1].children[keyframeNumber].children[1], currentClickItemId)
        modelRef.current.children[0].children[1].children[keyframeNumber].children[1].children.map(item=> {
            if(item.name === currentClickItemId) {
                item.matrix = w.clone()
            }
        })

        let obj = computeModalData(getToothMatrixByNumber(toothData, 0, currentClickItemId), dragMat.current.clone(), currentClickItemId, getGumMatrix(gumData, 0), getGumMatrix(gumData, keyframeNumber))
        setMovementData(obj)

        saveMovement.current = obj


    }

    /**
     * @description   Finish dragging gizmos
     * */
    const dragEndPivot = () => {
        setDragging(false)
        setHoverColor('yellow')

        let newData = setToothMatrixAndMovementHandler(data, currentClickItemId, keyframeNumber, dragMat.current.clone(), saveMovement.current)

        setDentalData(cloneDeep(newData))


        pivotRef.current?.removeEventListener('dragstart', dragStartPivot);
        pivotRef.current?.removeEventListener('drag', dragPivot);
        pivotRef.current?.removeEventListener('dragend', dragEndPivot);
    }

    /**
     * @description    cancelDragging can stop camera-control dragging behavior. We can't use redux's cameraControlRef
     *                 directly. If using it would prevent us from immediately executing the CameraControl's cancel event
     * */
    const dragStartPivot = () => {
        setDragging(true)
    }


    /**
     * @description    Add renderOrder to each mesh or Line under pivotControl
     * */
    useEffect(() => {
        if (pivotRef.current) {
            pivotRef.current.children.forEach(a => {
                a.children.forEach((b, i) => {
                    b.children.forEach((c, j) => {
                        c.renderOrder = 2
                        // c.layers.set(1)
                    })
                })
            })
        }

    }, [])


    return (
        <PivotControls
            ref={pivotRef}
            // currentClickItemId == itemId && !initTooth ? 15 : 0
            scale={currentClickItemId !== null && editMode ? 10 : 0}
            visible={currentClickItemId !== null && editMode ? true : false}
            matrix={toothMatrix}
            rotation={[0, Math.PI / 2, 0]}
            depthWrite={false}
            depthTest={false}
            // anchor={[1, 0, 0]}
            annotationsClass={'threeAnnotations'}
            hoveredColor={hoverColor}
            onDrag={(l, d, w, dw) => dragPivot(l, d, w, dw)}
            onDragEnd={dragEndPivot}
            onDragStart={dragStartPivot}
            renderOrder={1}
        >
        </PivotControls>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    currentClickItemId: state.webViewer.currentClickItemId,
    dragging: state.webViewer.dragging,
    toothData: state.webViewer.toothData,
    keyframeNumber: state.webViewer.keyframeNumber,
    attachmentCheckboxValue: state.webViewer.attachmentCheckboxValue,
    panelData: state.webViewer.panelData,
    cameraControlRef: state.webViewer.cameraControlRef,
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    isMobile: state.webViewer.isMobile,
    editMode: state.webViewer.editMode,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
    setCurrentClickPontic: val => dispatch(action.setCurrentClickPontic(val)),
    setCurrentClickItemRef: val => dispatch(action.setCurrentClickItemRef(val)),
    setCurrentClickInitItemRef: val => dispatch(action.setCurrentClickInitItemRef(val)),
    setDragging: val => dispatch(action.setDragging(val)),
    setToothData: val => dispatch(action.setToothData(val)),
    setPivotData: val => dispatch(action.setPivotData(val)),
    setPanelData: val => dispatch(action.setPanelData(val)),
    setMovementData: val => dispatch(action.setMovementData(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Gizmo)
