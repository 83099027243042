import React, {useRef, useState, useEffect, useLayoutEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action'
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {Image, Slider, Carousel, Radio} from 'antd';
import {cloneDeep, debounce} from "lodash";
import DragModal from '../../../../Components/DragModal'

import BScroll from 'better-scroll'
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
import SvgIcon from "../../../../Components/SvgIcon";

import {useFirstLoadingEffect, useUpdateEffect} from '../../../../lib/customHooks'


BScroll.use(MouseWheel)
BScroll.use(ScrollBar)



const photosDefaultList = [
    {
        name: 'FULL FACE SMILING',
        src: '',
        defaultPicture: 'fullFaceSmiling'
    },
    {
        name: 'FULL FACE NOT SMILING',
        src: '',
        defaultPicture: 'fullFaceNotSmiling'
    },
    {
        name: 'PROFILE',
        src: '',
        defaultPicture: 'Profile'
    },
    {
        name: 'UPPER OCCLUSAL',
        src: '',
        defaultPicture: 'upperOcclusal'
    },
    {
        name: 'LOWER OCCLUSAL',
        src: '',
        defaultPicture: 'lowerOcclusal'
    },
    {
        name: 'RIGHT LATERAL',
        src: '',
        defaultPicture: 'rightlateral'
    },
    {
        name: 'FRONT VIEW',
        src: '',
        defaultPicture: 'frontView'
    },
    {
        name: 'LEFT LATERAL',
        src: '',
        defaultPicture: 'leftlateral'
    },
    {
        name: 'PANORAMIC',
        src: '',
        defaultPicture: 'Panoramic'
    },
    {
        name: 'CEPHALOMETRIC',
        src: '',
        defaultPicture: 'Cephalometric'
    },
]



function Photo(props) {

    const { visible=false, closeModal } = props

    const photoRef = useRef()
    const resizeW = useRef(window.innerWidth / 3)

    const [photoList, setPhotoList] = useState(cloneDeep(photosDefaultList))

    const [width, setWidth] = useState(window.innerWidth / 3)
    const [height, setHeight] = useState(window.innerWidth / 3.3)

    const [sliderVal, setSliderVal] = useState(100)

    const [onContainer, setOnContainer] = useState(false)

    const [title, setTitle] = useState(photoList[0].name)

    useEffect(() => {
        document.addEventListener('resize', () => {
            setWidth(window.innerWidth / 4)
        })


    }, []);

    /**
     * @description   Initialize BScroll
     * */
    const [bScroll, setBScroll] = useState(null);

    const initBScroll = async (ref, set) => {
        // console.log(ref)
        // bScroll?.destroy()
        await set(
            new BScroll(ref, {
                probeType: 3,
                // scrollX: true,
                freeScroll: true,
                eventPassthrough: 'horizontal',
                click: true,
                observeDOM: true,
                stopPropagation: true,
                scrollbar: {
                    fade: false,
                    fadeInTime: 500,
                    fadeOutTime: 1000,
                    interactive: true,
                    scrollbarTrackClickable: true,
                    minSize: 20,
                },
                bounce: false,
                useTransition: true,
                mouseWheel: {
                    speed: 20,
                    throttleTime: 200,
                    discreteTime: 200
                },
                outOfBoundaryDampingFactor: 0
            })
        )
    }

    // The useUpdateEffect hook is used to initialize a BScroll instance and
    // attach it to the modal window's photo gallery.
    useFirstLoadingEffect(async ()=> {


        if(visible) {
            console.log('hihihi')
            let div = document.createElement('div')

            div.className = 'customWrap'

            div.append(document.querySelector('.slick-track'))

            document.querySelector('.slick-list').appendChild(div)


            await initBScroll(document.querySelector('.photoAndXRaysWrapper .slick-list'), setBScroll)

            let dom = document?.querySelectorAll('.slick-list .bscroll-vertical-scrollbar')
            dom[0].remove()

        }

    }, [visible])


    /**
     * @description    The onResize function is used to adjust the size of the photo gallery when the
     * user resizes the modal window. The onSilderChange function is used to handle slider value changes.
     * */

    const onResize = (e,dir,dom,size) => {

        let d = 1.1

        let computedWidth = document.querySelector(('.photoBox')).getBoundingClientRect().width

        let computedHeight = computedWidth / d

        resizeW.current = computedWidth


        setHeight(computedHeight)

        // bScroll.refresh()
    }

    /**
     * @description    Adjust the transparency of the modal based on the slider changes
     * */
    const onSilderChange = (val) => {

        document.querySelector('.photoAndXRaysWrapper .ant-modal-content').style.opacity = 1

        setSliderVal(val)
    }

    useEffect(() => {
        if(!document.querySelector('.photoAndXRaysWrapper .ant-modal-body')) {
            return
        }
        document.querySelector('.photoAndXRaysWrapper .ant-modal-body').style.backgroundColor = `rgba(247, 247, 247, ${sliderVal / 100})`
    }, [sliderVal]);

    /**
     * @description    Check if the mouse is inside the modal. Shows the scroll bar if
     * the mouse is inside the modal box, otherwise hides it
     * */

    const adjust = () => {
        const dom = document.querySelector('.photoAndXRaysWrapper .ant-modal-body')

        const mouseover = (e) => {
            setOnContainer(true)

        }

        const mouseleave = (e) => {
            setOnContainer(false)

        }

        if(dom) {
            dom.removeEventListener('mouseover', mouseover)
            dom.removeEventListener('mouseleave', mouseleave)

            dom.addEventListener('mouseover', mouseover)
            dom.addEventListener('mouseleave', mouseleave)
        }


    }

    const changeScrollBarOpacity = (domName) => {

        const dom = document.querySelector(`.${domName} .bscroll-vertical-scrollbar`)

        if(dom && onContainer) {
            dom.style.opacity = 1
        }else if(dom && !onContainer) {
            dom.style.opacity = 0
        }
    }

    useEffect(() => {
        adjust()
    }, [visible]);


    useEffect(() => {

        changeScrollBarOpacity('slick-list')

    }, [onContainer])



    /**
     * @description    Thumbnails are styled accordingly when the mouse is over them.
     * */
    const [mouseoverPage, setMouseoverPage] = useState(0)

    const [currentPage, setCurrentPage] = useState(0)

    const pageRef = useRef(0)

    const dom = document.querySelector('.slick-dots')

    const mouseoverHandler = (index) => {
        setMouseoverPage(index)
    }

    useEffect(() => {

        pageRef.current = currentPage

    }, [currentPage]);


    useEffect(() => {


        if(dom) {
            dom.addEventListener('mouseleave', (e) => {
                console.log(currentPage)
                setMouseoverPage(pageRef.current)
            })
        }

    }, [dom]);


    const customPaging = (i) => {

        return (
            <div className={'thumbWrapper ' + (currentPage === i ? 'activePage' : '')} style={{left: Number(i) > 0 ? `-${i}px` : "unset"}} onMouseOver={()=> mouseoverHandler(i)}>
                <SvgIcon iconClass={photoList[i].defaultPicture}  />
            </div>
        )
    }



    const beforeChange = (from, to) => {
        console.log(from, to)
        setCurrentPage(to)
    }

    useEffect(()=> {
        setTitle(photoList[mouseoverPage].name)
    }, [mouseoverPage])

    useEffect(() => {
        setTitle(photoList[currentPage].name)
    }, [currentPage]);


    /**
     * @description    dom header of modal box
     * */
    const domElement = (
        <div style={{position: 'relative', padding: '8px 4px 18px 4px'}} className={'photoHeader'}>
            <p style={{float: 'left', width: 'calc(100% - 180px)', textAlign: 'center',  pointerEvents: 'none'}}>{title}</p>
            <div style={{position: 'absolute', top: '16px', right: '34px', cursor: 'default'}}>
                <p style={{fontSize: '14px', textAlign: 'center', color: '#8A8F98', paddingRight: '20px'}}>Opacity</p>
                <Slider
                    min={15}
                    max={100}
                    onChange={onSilderChange}
                    // onAfterChange={onAfterChange}
                    // value={typeof sliderVal === 'number' ? sliderVal : 0}
                    tooltip={{
                        open: false
                    }}
                    value={sliderVal}
                />
            </div>
        </div>
    )



    return (
        <div className={'sliderWrap'}>
            <DragModal
                wrapClassName={'photoAndXRaysWrapper'}
                width={width}
                titleText={domElement}
                visible={visible}
                minWidth={window.innerWidth / 3}
                closeModal={closeModal}
                // onResizeStart={onResizeStart}
                // onResizeStop={onResizeStop}
                onResize={debounce(onResize, 200)}
                needResize={true}
                target={'.photoAndXRaysWrapper .ant-modal-content'}
            >
                {
                    <Carousel
                        dotPosition={'top'}
                        infinite={true}
                        arrows={true}
                        nextArrow={<RightOutlined />}
                        prevArrow={<LeftOutlined />}
                        customPaging={customPaging}
                        beforeChange={beforeChange}
                    >
                        {
                            photoList.map((item, i)=> {
                                return(
                                    <div className={'photoContainer'} style={{backgroundColor: "transparent"}}>
                                        <div className={'photoBox'} style={{width: '100%', position: 'relative'}} ref={photoRef}>
                                            <div>
                                                <div className={'photo ' + (photoList.length - 1 === i ? 'lastItem' : '')} style={{height, opacity: sliderVal / 100}}>
                                                    <div className={'picture'}>
                                                        <SvgIcon iconClass={item.defaultPicture}   />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                }
            </DragModal>

        </div>
    );
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Photo)
