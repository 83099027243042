// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadWrapper {
  position: absolute;
  top: 16px;
  left: 410px;
  z-Index: 12;
  display: inline-block;
  margin-right: 12px;
}
.uploadWrapper > span {
  float: left;
}
.ant-btn {
  border-radius: 8px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-btn .anticon-upload {
  display: none;
}
.ant-btn span {
  margin-left: 0 !important;
}
.uploadBtn {
  width: 120px;
  height: 32px;
  background-color: #F3F4F5 !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 4px;
  transition: 0.3s;
}
.uploadBtn span {
  font-weight: 500;
  font-size: 14px;
  color: black;
}
.uploadModal .ant-modal-header,
.uploadModal .ant-modal-body,
.uploadModal .ant-modal-footer {
  background-color: #F3F4F5;
}
.uploadModal .ant-modal-body {
  padding-top: 0 !important;
  padding-bottom: 8px;
}
.uploadPopover .ant-popover-inner {
  height: 52px;
}
.uploadPopover .ant-popover-content {
  top: -28px;
  left: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/View/Option/Components/Upload/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;AACJ;AAIA;EACE,6BAAA;EACA,2BAAA;EACA,0BAAA;EACA,qBAAA;EAOA,gBAAA;EACA,uBAAA;EACA,mBAAA;AARF;AALA;EAMI,aAAA;AAEJ;AARA;EASI,yBAAA;AAEJ;AAKA;EACE,YAAA;EACA,YAAA;EACA,oCAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AAHF;AAPA;EAaI,gBAAA;EACA,eAAA;EACA,YAAA;AAHJ;AAOA;;;EAEI,yBAAA;AAJJ;AAEA;EAMI,yBAAA;EACA,mBAAA;AALJ;AAYA;EAEI,YAAA;AAXJ;AASA;EAMI,UAAA;EACA,UAAA;AAZJ","sourcesContent":[".uploadWrapper {\n  position: absolute;\n  top: 16px;\n  left: 410px;\n  z-Index: 12;\n  display: inline-block;\n  margin-right: 12px;\n\n  & > span {\n    float: left;\n  }\n\n}\n\n.ant-btn {\n  border-radius: 8px !important;\n  font-weight: 500 !important;\n  font-size: 14px !important;\n  padding: 0 !important;\n  .anticon-upload {\n    display: none;\n  }\n  span {\n    margin-left: 0 !important;\n  }\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.uploadBtn {\n  width: 120px;\n  height: 32px;\n  background-color: #F3F4F5 !important;\n  border-radius: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  margin: 0 4px;\n  transition: .3s;\n\n  span {\n    font-weight: 500;\n    font-size: 14px;\n    color: black;\n  }\n}\n\n.uploadModal {\n  .ant-modal-header, .ant-modal-body, .ant-modal-footer {\n    background-color: #F3F4F5;\n  }\n\n  .ant-modal-body {\n    padding-top: 0 !important;\n    padding-bottom: 8px;\n  }\n\n}\n\n\n\n.uploadPopover {\n  .ant-popover-inner {\n    height: 52px;\n  }\n\n  .ant-popover-content {\n    top: -28px;\n    left: 18px;\n  }\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
