import React, {useMemo, useRef, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import action from '../../store/action/index'
import {extend, useFrame, useThree} from '@react-three/fiber'
import {shaderMaterial, Plane, Billboard, Line} from "@react-three/drei";
import { v4 as uuidv4 } from 'uuid';

import vertexShader from '../../shader/Grid/vertexShader.js'
import fragmentShader from '../../shader/Grid/fragmentShader.js'
// import {InfiniteGridHelper} from './InfiniteGridHelper'

import * as THREE from "three";


const GridMaterial = shaderMaterial(
    {
        cellSize: 0.5,
        sectionSize: 1,
        fadeDistance: 100,
        fadeStrength: 0,
        cellThickness: 1,
        sectionThickness: 1.5,
        cellColor: new THREE.Color(),
        sectionColor: new THREE.Color(),
        infiniteGrid: 1,
        followCamera: 0
    },
    vertexShader,
    fragmentShader
)
extend({ GridMaterial })

/**
 * @description  This is the component that creates the grid
 * */

function Grid2(props) {

    const {gridCheckboxValue, cameraControl} = props

    const lineRef = useRef()

    const [length, setLength] = useState(0);
    const { camera, size } = useThree();

    const { width, height } = size;

    const [cellSize, setCellSize] = useState(2)
    const [sectionSize, setSectionSize] = useState(10)

    useEffect(() => {
        if(cameraControl) {
            cameraControl.addEventListener('control',()=> {
                requestAnimationFrame(()=> {
                    const start = new THREE.Vector4(0,0,0);
                    const end = new THREE.Vector4(10,0,0);


                    const startClip = start.clone().applyMatrix4(camera.projectionMatrix);
                    const endClip = end.clone().applyMatrix4(camera.projectionMatrix);

                    const startScreen = new THREE.Vector2((startClip.x + 1) * width / 2, (1 - startClip.y) * height / 2);
                    const endScreen = new THREE.Vector2((endClip.x + 1) * width / 2, (1 - endClip.y) * height / 2);
                    const lengthScreen = startScreen.distanceTo(endScreen);

                    let zoom = lengthScreen / 5
                    let zoomLv = Math.floor(Math.log10(zoom))


                    if(zoomLv <= 3) {
                        let newSize = Math.pow(0.1, zoomLv)*10

                        setSectionSize(newSize * 10)
                        setCellSize(newSize * 2)

                    }

                })


            })
        }

        if(gridCheckboxValue) {
            requestAnimationFrame(()=> {
                const start = new THREE.Vector4(0,0,0);
                const end = new THREE.Vector4(10,0,0);


                const startClip = start.clone().applyMatrix4(camera.projectionMatrix);
                const endClip = end.clone().applyMatrix4(camera.projectionMatrix);

                const startScreen = new THREE.Vector2((startClip.x + 1) * width / 2, (1 - startClip.y) * height / 2);
                const endScreen = new THREE.Vector2((endClip.x + 1) * width / 2, (1 - endClip.y) * height / 2);
                const lengthScreen = startScreen.distanceTo(endScreen);


                let zoom = lengthScreen / 5
                let zoomLv = Math.floor(Math.log10(zoom))

                let newSize = Math.pow(0.1, zoomLv)*10

                setSectionSize(newSize * 10)
                setCellSize(newSize * 2)


            })
        }
    }, [cameraControl, gridCheckboxValue]);


    return (
        <>
            <Line ref={lineRef} points={[[0, 0, 0], [10,0,0]]} color="white" lineWidth={1} visible={false}/>
            <Billboard>
                <Plane args={[1,1]} rotation={[Math.PI / 2, 0, 0]} position={[0,0,0]} renderOrder={10} visible={gridCheckboxValue}>
                    {/*<meshBasicMaterial color={'0xffff00'} />*/}
                    <gridMaterial
                        transparent={true}
                        depthTest={false}
                        side={THREE.DoubleSide}
                        cellColor={'#aaa'}
                        sectionColor={'#444'}
                        followCamera={false}
                        infiniteGrid={true}
                        cellSize={cellSize}
                        sectionSize={sectionSize}
                        fadeDistance={1000}
                    />
                </Plane>
            </Billboard>
        </>
    );
}

const mapStateToProps = state => ({
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Grid2)
