import {createStore} from 'redux';

import reducers from './reducer/index.js';

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__&&window.__REDUX_DEVTOOLS_EXTENSION__());

/**
 * @description create store. Modularity is used
 */
export default store
