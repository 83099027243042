import React, { useRef, useState, useEffect } from "react";
import "./index.less";
import { connect } from "react-redux";
import action from "../../../../store/action/index";
import {} from "../../../../http/api/webviewerApi";
import Icon, {
  TaobaoCircleOutlined,
  SettingOutlined,
  FormOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash";
import { setPopoverVisible } from "../../../../store/action/webViewer";
import Photos from "../Photo/index";
import Grid from "../Grid";
import SvgIcon from "../../../../Components/SvgIcon";
import DataPanel from "../DataPanel";

function CircleMenu(props) {
  const {
    setPopoverVisible,
    isMobile,
    setEditMode,
    isLoading,
    dentalData,
    setDentalData,
    currentDataTool,
    isUpdated,
    buildTarget,
  } = props;

  const circleRef = useRef();
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(false);
  const [iconList, setIconList] = useState([
    {
      iconName: "",
      backgroundColor: "#747CBE",
      name: "Tutorial Mode",
      active: false,
      type: "text",
    },
    // {
    //     iconName: FormOutlined,
    //     backgroundColor: '#71A647',
    //     name: 'Edit Mode',
    //     active: false,
    //     type: 'icon'
    // },
    // {
    //   iconName: "",
    //   backgroundColor: "#71A647",
    //   name: "Photos",
    //   active: false,
    //   type: "icon",
    // },
    {
      iconName: "gridIcon",
      backgroundColor: "#71A647",
      name: "Table View",
      active: false,
      type: "icon",
    },
  ]);

  /**
   * @description
   * @param {Number} x The x-coordinate of the center of the circle
   * @param {Number} y The y-coordinate of the center of the circle
   * @param {Number} r Radius of circle
   * @param {Number} num Total number of ICONS
   * @param {Number} index  subscript
   * @param {Number} initAngle Initial deflection Angle
   * */

  const getPosition = (x, y, r, num, index, initAngle) => {
    let angle = 300 / num;

    let currentAngle = angle * index;

    if (initAngle) {
      currentAngle = currentAngle + initAngle;
    }

    let disX = x + r * Math.cos((currentAngle * Math.PI) / 360);
    let disY = y + r * Math.sin((currentAngle * Math.PI) / 360);

    return { x: disX, y: disY };
  };

  /**
   * @description   click menu handler
   *
   * */
  const clickMenu = (e) => {
    e.preventDefault();

    setVisible(!visible);
  };

  /**
   * @description   click icon handler
   * */
  const clickIcon = (name) => {
    let arr = cloneDeep(iconList);
    arr.map((item) => {
      if (name === item.name) {
        item.active = !item.active;

        if (name === "Tutorial Mode") {
          setPopoverVisible(item.active);
        } else if (name === "Edit Mode") {
          setEditMode(item.active);
        } else if (name === "Photos") {
          clickPhotosHandler();
        } else if (name === "Table View") {
          openTableModal();
        }
      }
    });

    setIconList(arr);
  };

  /**
   * @description
   * */
  const clickPhotosHandler = () => {
    setModalVisible(!modalVisible);
  };

  const closeModal = (value) => {
    setModalVisible(value);

    let arr = cloneDeep(iconList);
    arr.map((item) => {
      if (item.name === "Photos") {
        item.active = false;
      }
    });
    setIconList(arr);
  };

  /**
   *
   * */

  // open modal
  const openTableModal = () => {
    setTableVisible(!tableVisible);
  };

  // close modal
  const closeTableModal = () => {
    setTableVisible(false);

    let arr = cloneDeep(iconList);
    arr.map((item) => {
      if (item.name === "Table View") {
        item.active = false;
      }
    });
    setIconList(arr);
  };

  useEffect(() => {
    if (buildTarget === "webviewer_dataTool") {
      let arr = [
        {
          iconName: "gridIcon",
          backgroundColor: "#71A647",
          name: "Table View",
          active: false,
          type: "icon",
        },
      ];

      setIconList(arr);
    } else {
      let arr = [
        {
          iconName: "",
          backgroundColor: "#747CBE",
          name: "Tutorial Mode",
          active: false,
          type: "text",
        },
        // {
        //   iconName: "",
        //   backgroundColor: "#71A647",
        //   name: "Photos",
        //   active: false,
        //   type: "icon",
        // },
        {
          iconName: "gridIcon",
          backgroundColor: "#71A647",
          name: "Table View",
          active: false,
          type: "icon",
        },
      ];

      setIconList(arr);
    }
  }, [buildTarget]);

  return (
    // <div class="circular-menu">
    //     <div className={'circle ' + (visible ? 'open' : '')} ref={circleRef}>
    //         {
    //             iconList.map((item, i) => {
    //                 return (
    //                     <div
    //                         className={'icon ' + item.name}
    //                         style={{
    //                             backgroundColor: item.active ? item.backgroundColor : '',
    //                             width: '26px',
    //                             height: '26px',
    //                             borderRadius: '50%',
    //                             top: getPosition(90, 90, 50, iconList.length, i, -30).y - 10 + 'px',
    //                             left: getPosition(90, 90, 50, iconList.length, i, -30).x - 10 + 'px'
    //                         }}
    //                         onClick={()=> clickIcon(item.name)}
    //                     >
    //                         {
    //                             item.type === 'icon' ? (
    //                                 <Icon
    //                                     component={item.iconName}
    //                                     style={{
    //                                         position: 'relative',
    //                                         fontSize: '16px',
    //                                         top: '50%',
    //                                         transform: 'translateY(-50%)',
    //                                         color: 'white',
    //                                         fontWeight: 500
    //                                     }}
    //
    //                                 />
    //                             ) : (
    //                                 <span
    //                                     style={{
    //                                         position: 'relative',
    //                                         fontSize: '20px',
    //                                         color: 'white',
    //                                         fontWeight: 'bold',
    //                                         top: '50%',
    //                                         transform: 'translateY(-50%)',
    //                                         display: 'inline-block'
    //                                     }}
    //                                     onClick={()=> clickIcon(item.name)}
    //                                 >?</span>
    //                             )
    //                         }
    //                     </div>
    //                 )
    //             })
    //         }
    //     </div>
    //     <a href="" className="menu-button" onClick={(e) => clickMenu(e)}
    //        style={{backgroundColor: visible ? '#70B1B1' : '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    //         <img src={require('../../../../Asset/image/Vector.png')} width={38} height={38} style={{position: 'absolute'}}></img>
    //         <img src={require('../../../../Asset/image/Vector_circle.png')} width={12} height={12} style={{position: 'absolute'}}></img>
    //     </a>
    // </div>
    <>
      <div
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
          display: isMobile ? "none" : "flex",
          flexDirection: "row",
          zIndex: "12",
          fontSize: "12px",
        }}
      >
        {iconList.map((item) => {
          return (
            <div
              className={
                "topButton " +
                (item.active
                  ? item.name == "Tutorial Mode"
                    ? "active tutorialButtonActive"
                    : "active otherButtonActive"
                  : "")
              }
              onClick={() => clickIcon(item.name)}
            >
              {item.iconName ? (
                <SvgIcon
                  iconClass={"gridIcon"}
                  fill={tableVisible ? "white" : "black"}
                  style={{ marginRight: "4px" }}
                />
              ) : (
                <></>
              )}
              {item.name}
            </div>
          );
        })}
      </div>
      {!isLoading ? (
        currentDataTool.key !== 1 ? (
          <Grid
            visible={tableVisible}
            closeModal={closeTableModal}
            dentalData={dentalData}
            setDentalData={setDentalData}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <Photos visible={modalVisible} closeModal={closeModal} />
    </>
  );
}

const mapStateToProps = (state) => ({
  isMobile: state.webViewer.isMobile,
  isLoading: state.webViewer.isLoading,
  currentDataTool: state.dataTool.currentDataTool,
});

const mapDispatchToProps = (dispatch) => ({
  setPopoverVisible: (val) => dispatch(action.setPopoverVisible(val)),
  setEditMode: (val) => dispatch(action.setEditMode(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CircleMenu);
