import React, {useRef, useState, useEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action/index'
import { DownOutlined } from '@ant-design/icons'
import ArchPopover from "../../../../Components/ArchPopover";
import OutsideClickHandler from 'react-outside-click-handler';

function SelectVersion(props) {

    const {isMobile} = props

    /**
     * @description    control panel visible or hide
     * */
    const [visible, setVisible] = useState(false)

    const clickIcon = () => {
        setVisible(!visible)
    }

    const text = ['Choose your satisfied setup version.']


    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                setVisible(false)
            }}
        >
            <div style={{position: 'relative', width: '100%', display: isMobile ? 'none' : 'block'}}>
                <div className={'selectVersionWrapper'}>
                    <div className={'selectVersionContainer'}>
                        <div className={'selectVersionTitle'}>Setup Version 2.0</div>
                    </div>
                    <div className={'dropDownIcon'} onClick={clickIcon}>
                        <DownOutlined style={{fontSize: '16px'}} />

                    </div>
                    <ArchPopover right={'-10px'} top={'-10px'} width={'240px'} height={'25px'} placement={'bottomRight'} overlayClassName={'versionPopover'} text={text} />
                    {
                        visible ? (
                            <div className={'dropDownWrapper'}>
                                <div className={'dropDownContainer'}>
                                    <ul className={'dropDown'}>
                                        <li className={'dropDownItem'}>
                                            Setup Version 2.0
                                            <span></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : null
                    }
                </div>

            </div>
        </OutsideClickHandler>
    );
}

const mapStateToProps = state => ({
    isMobile: state.webViewer.isMobile,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SelectVersion)
