import React, {useRef, useEffect, useCallback} from "react";
import {useLoader} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import {connect} from "react-redux";
import action from "../../../store/action";
import * as THREE from 'three'

import './index.less'
import {mergeVertices} from "three/addons/utils/BufferGeometryUtils";
import store from "../../../store";


/**
 * @description create tooth function. In order to batch creation tooth
 * @param props The parameter passed by the attribute within the tag
 * */
function Tooth(props) {
    /**
     * @param itemId String    component itemId
     * @param url String     model path
     * @param color String     model material color
     * */
    const {
        itemId,
        scale,
        setCurrentClickItemId,
        setCurrentClickInitItemRef,
        currentClickItemId,
        initTooth, // Determine if the tooth is in overlay mode
        dragging,
        keyframeNumber,
        setCurrentClickPontic,
        url,
        visible=false,
        opacity,
        fileType
    } = props

    const ref = useRef()


    useEffect(()=> {

        if(itemId === currentClickItemId && initTooth) {

            setCurrentClickInitItemRef(ref.current)
        }

    }, [currentClickItemId])


    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })



    const clickHandler = useCallback((e) => {

        if (dragging || initTooth) {
            return
        }

        if(e.eventObject.material.opacity === 1 && e.eventObject.visible) {

            e.stopPropagation()

            setCurrentClickItemId(itemId)

            setCurrentClickPontic(null)

        }

        // ToothDataPro prevent a bubble



    }, [])




    useEffect(()=> {
        if(ref.current) {

            ref.current.geometry.deleteAttribute("normal")
            ref.current.geometry = mergeVertices(ref.current.geometry, 0.1);
            ref.current.geometry.computeVertexNormals(true);
        }
    }, [model])



    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            renderOrder={initTooth ? 1 : 0}
            scale={scale}
            // matrix={toothMatrix}
            // matrixAutoUpdate={false}
            onClick={(event) => clickHandler(event)}
            name={itemId}
            >
            <meshPhysicalMaterial
                attach="material"
                color={currentClickItemId == itemId ? 'orange' : '#FFFFFF'}
                opacity={opacity}
                transparent={true}
                depthWrite={opacity > 0.5 ? true : false}
                depthTest={true}
                clearcoat={0.5}
                clearcoatRoughness={0.3}
                roughness={0.4}
                metalness={0}
                reflectivity={0.2}
                emissive={'#FFFFFF'}
                emissiveIntensity={0.05}
                flatShading={false}
            />
            {/*<meshStandardMaterial*/}
            {/*    color={currentClickItemId == itemId ? 'orange' : '#FFFFFF'}*/}
            {/*    opacity={opacity}*/}
            {/*    transparent={true}*/}
            {/*    depthWrite={opacity > 0.5 ? true : false}*/}
            {/*    depthTest={true}*/}
            {/*    roughness={0.4}*/}
            {/*    reflectivity={0.2}*/}
            {/*    emissive={'#FFFFFF'}*/}
            {/*    emissiveIntensity={0.05}*/}
            {/*/>*/}
        </mesh>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    currentClickItemId: state.webViewer.currentClickItemId,
    dragging: state.webViewer.dragging,
    toothData: state.webViewer.toothData,
    keyframeNumber: state.webViewer.keyframeNumber,
    attachmentCheckboxValue: state.webViewer.attachmentCheckboxValue,
    panelData: state.webViewer.panelData,
    cameraControlRef: state.webViewer.cameraControlRef,
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    isMobile: state.webViewer.isMobile,
    editMode: state.webViewer.editMode,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
    setCurrentClickPontic: val => dispatch(action.setCurrentClickPontic(val)),
    setCurrentClickItemRef: val => dispatch(action.setCurrentClickItemRef(val)),
    setCurrentClickInitItemRef: val => dispatch(action.setCurrentClickInitItemRef(val)),
    setDragging: val => dispatch(action.setDragging(val)),
    setToothData: val => dispatch(action.setToothData(val)),
    setPivotData: val => dispatch(action.setPivotData(val)),
    setPanelData: val => dispatch(action.setPanelData(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tooth)
