import React, {useRef, useState, useEffect, useLayoutEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action/index'
import { getPatientsListApi } from '../../../../http/api/webviewerApi'
import { getBytePatientsListApi } from '../../../../http/api/byteApi'
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';
import {Avatar, message} from 'antd';
import { useSpring, animated } from 'react-spring'
import BScroll from 'better-scroll'
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
import eventBus from '../../../../lib/eventEmitter'
import OutsideClickHandler from 'react-outside-click-handler';
import {cloneDeep} from "lodash";
import {setIsLoading} from "../../../../store/action/webViewer";

BScroll.use(MouseWheel)
BScroll.use(ScrollBar)

function PatientsList(props) {

    const {setCurrentPatient, currentPatient ,isMobile, currentDataTool, patientsList, setConfirmModalVisible, loadModel, textAreaValue, setupModalVisible} = props

    const byteModeString = ['[Mexicali]', '[Smartee]', '[Byte]']

    const ref = useRef()
    const listWrap = useRef()

    const [visible, setVisible] = useState(false)

    const [currentItem, setCurrentItem] = useState(null)
    const [bScroll, setBScroll] = useState(null);

    let init = useRef(true)

    // animation config
    const {opacity} = useSpring({ opacity: visible ? 1 : 0, config: {duration: visible ? 150 : 0}})
    const {height} = useSpring({height: visible ? '65vh' : '0' , config: {duration: visible ? 150 : 0}})

    //
    const scrollSingleton = {
        instance: null,

        getInstance() {
            if (!this.instance) {
                this.instance = new BScroll(ref.current, {
                    probeType: 3,
                    click: true,
                    observeDOM: true,
                    scrollbar: {
                        fade: false,
                        scrollbarTrackClickable: true,
                        interactive: true,
                    },
                    bounce: false,
                    useTransition: true,
                    mouseWheel: {
                        speed: 30,
                        easeTime: 300,
                    },
                    outOfBoundaryDampingFactor: 0
                })
            }
            return this.instance;
        },
    };

    /**
     * @description  click handler
     * */
    const clickList = () => {
        setVisible(!visible)
    }

    /**
     * @description  click handler
     * */
    const clickItem = (item) => {
        setCurrentItem(item)

        sessionStorage.setItem('listItemId', item.id)
    }


    /**
     * @description    custom scroll bar
     * */
    const initBScroll = async () => {
        bScroll?.destroy()
        await setBScroll(
            new BScroll(ref.current, {
                probeType: 3,
                click: true,
                observeDOM: true,
                scrollbar: {
                    fade: false,
                    scrollbarTrackClickable: true,
                    interactive: true,
                },
                bounce: false,
                useTransition: true,
                mouseWheel: {
                    speed: 30,
                    easeTime: 300,
                },
                outOfBoundaryDampingFactor: 0
            })
        )

        bScroll.refresh()
    }

    // Remove rendered scrollbars

    const firstLoading = useRef(0)

    const removeScrollBar = () => {
        let dom = document?.querySelectorAll('.listWrap .bscroll-vertical-scrollbar');

        if(dom.length < 2) {
            return
        }

        dom[0].remove();

        removeScrollBar();
    }

    useEffect(() => {

        firstLoading.current = firstLoading.current + 1

        if(firstLoading.current <= 2 && visible && patientsList.length !== 0) {
            removeScrollBar()
        }
    }, [visible]);


    //loading model
    const loadingModel = async (item) => {
        console.log(textAreaValue, setupModalVisible, "setupModalVisible")
        if(textAreaValue !== "" && setupModalVisible) {
            console.log("jj")
            setConfirmModalVisible(true)
            return
        }

        await setCurrentPatient(cloneDeep(item))
        loadModel()
        // await setCurrentRequest(2)
        await setVisible(false)
    }

    //delete model
    const deleteModel = () => {

    }

    useLayoutEffect(()=> {
        // init better scroll
        setTimeout(()=> {
            const scrollInstance = scrollSingleton.getInstance();

            setBScroll(scrollInstance);
        }, 1000)
    },[patientsList])

    useEffect(() => {
        try {
            bScroll.scroller.hooks.on('scrollToElement', (el, pos)=> {
            })

            bScroll.scrollToElement(document.querySelector(`#${currentPatient.fileName}`))
        }
        catch (e) {
            console.log(e)
        }
    }, [currentPatient, bScroll]);


    // Destroy the extra scroll bar
    useEffect(() => {
        // if(visible && init.current && patientsList.length !== 0) {
        //     bScroll.destroy()
        //     init.current = false
        // }

    }, [visible]);

    useEffect(()=> {
        if(patientsList.length !== 0) {
            setCurrentItem(patientsList[0])
        }
    }, [patientsList])

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setVisible(false)
                }}
            >
                <div className={isMobile ? 'mobileHeaderWrap' : 'headerWrap'}>
                    <span className={'webViewerName'}
                          title={(currentPatient.mode || currentPatient.mode === 0 ? byteModeString[currentPatient?.mode] : '') + currentPatient?.fileName || '--'}
                    >
                        {(currentPatient.mode || currentPatient.mode === 0 ? byteModeString[currentPatient?.mode] : '')}
                        {currentPatient?.fileName || '--'}
                    </span>

                    {/*<DownOutlined style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    transform: "translateY(-50%)",*/}
                    {/*    top: '50%',*/}
                    {/*    right: '20px',*/}
                    {/*    fontSize: '12px'*/}
                    {/*}}/>*/}
                </div>

                <animated.div className={'listWrap'} style={{
                    opacity: opacity,
                    height: height,
                    left: isMobile ? '50%' : 'none',
                    top: isMobile ? '36px' : '52px',
                    // transform: isMobile ? 'translateX(-50%)' : 'none'
                }}>
                    <div style={{
                        height: '65vh',
                        position: "absolute",
                        overflow: "hidden",
                        width: '100%',
                        padding: '4px 16px 4px 9px'
                    }} ref={ref}>
                        <ul style={{height: "auto"}} ref={listWrap}>
                            {
                                patientsList.map(item => {
                                    return (
                                        <li className={'listItem ' + (((item.mode || item.mode === 0 ? byteModeString[item?.mode] : '') + item?.fileName) === ((currentPatient?.mode || currentPatient?.mode === 0 ? byteModeString[currentPatient?.mode] : '') + currentPatient?.fileName) ? 'currentItemStyle' : '')}
                                            id={item?.fileName} onClick={() => clickItem(item)} key={item?.id}>
                                            <Avatar shape="square" size={40}
                                                    style={{margin: '7px 0px 7px 5px', cursor: 'pointer'}}/>
                                            <span className={'patientName'} title={item.fileName}>{(item.mode || item.mode === 0 ? byteModeString[item.mode] : '') + item.fileName}</span>
                                            <div className={'openBtn'} onClick={() => loadingModel(item)}>open</div>
                                            {/*<DeleteOutlined className={'deleteBtn'} onClick={()=> deleteModel(item)} />*/}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </animated.div>
            </OutsideClickHandler>
        </>
    );
}

const mapStateToProps = state => ({
    currentPatient: state.webViewer.currentPatient,
    isMobile: state.webViewer.isMobile,
    currentDataTool: state.dataTool.currentDataTool,
    textAreaValue: state.setup.textAreaValue,
    setupModalVisible: state.setup.setupModalVisible
})

const mapDispatchToProps = dispatch => ({
    setCurrentPatient: val => dispatch(action.setCurrentPatient(val)),
    setConfirmModalVisible: val => dispatch(action.setConfirmModalVisible(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientsList)
