import React from "react";
import PropTypes from "prop-types";

import styles from "./index.less";

const SvgIcon = props => {

    const { iconClass, fill, style } = props;

    return (

        <i aria-hidden="true" className="anticon">

            <svg className={"svg-class"} style={style}>

                <use xlinkHref={"#icon-" + iconClass} fill={fill} />

            </svg>

        </i>

    );

};

SvgIcon.propTypes = {

    // svg name

    iconClass: PropTypes.string.isRequired,

    // fill color

    fill: PropTypes.string

};

SvgIcon.defaultProps = {

    fill: "currentColor"

};

export default SvgIcon;
