import React, {useRef, useState, useEffect, useMemo} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action/index'
import {setIsCombine, setKeyframeNumber, setStageCount} from "../../../../store/action/webViewer";
import {getStageInfoApi} from "../../../../http/api/webviewerApi";
import {getByteStageInfoApi} from '../../../../http/api/byteApi'
import SvgIcon from "../../../../Components/SvgIcon";
import ArchPopover from "../../../../Components/ArchPopover";
import MobileSlider from '../MobileComponents/PlayBackControl'
import Grid from '../Grid/index'
import {useUpdateEffect} from "../../../../lib/customHooks";
import {object} from "prop-types";


/**
 * @description   stage component
 * */
const PlayBackControl = React.memo(function PlayBackControl(props) {
    /**
     * @param {Number} keyframeNumber
     * @param {Function} setKeyframeNumber
     * @param {Object} currentPatient
     * */
    const { keyframeNumber, setKeyframeNumber, currentPatient, isMobile, setStageCount, buildTarget, stageList, isCombine, setIsCombine, clickCombineOrSeparate } = props

    const iconArr = [
        {
            fileName: '171',
            id: 'firstKeyframe'
        },
        {
            fileName: '174',
            id: 'previousKeyframe'
        },
        {
            fileName: '172',
            id: 'playOrPause'
        },
        {
            fileName: '173',
            id: 'nextKeyframe'
        },
        {
            fileName: '175',
            id: 'lastKeyframe'
        }]  // save icon files name

    const [playBtnValue, setPlayBtnValue] = useState(true)
    const [isDrag, setIsDrag] = useState(false);  // Determines whether it is the keyframe drag Event


    const text = ['Use play buttons to view the treatment process.', 'Click on stages to switch.']

    const [mousedown, setMousedown] = useState(false);

    const maxW = useRef(500)
    const keyframePanelRef = useRef()
    const keyframeWrapperRef = useRef()
    const key1Ref = useRef()
    const key2Ref = useRef()

    /**
     * @description click handler
     * */
    const clickControlItem = (id) => {
        switch (id) {
            case 'nextKeyframe': nextKeyframeBtnHandler();
            break;
            case 'previousKeyframe': previousKeyframeBtnHandler();
            break;
            case 'firstKeyframe': firstKeyframeBtnHandler();
            break;
            case 'lastKeyframe': lastKeyframeBtnHandler();
            break;
            case 'playOrPause': playOrPauseBtnHandler();
            break;
        }
    }

    useEffect(() => {
        // let computeWidth = window.screen.width - 500
        let computeWidth = window.innerWidth - 500

        keyframeWrapperRef.current.style.maxWidth = computeWidth + "px"
        maxW.current = computeWidth

        // if(key1Ref.current && key2Ref.current) {
        //     let key1 = key1Ref.current.getBoundingClientRect();
        //     let key2 = key2Ref.current.getBoundingClientRect();
        //     const distanceX = Math.abs(key1.left - key2.left);
        //     const distanceY = Math.abs(key1.top - key2.top);
        //     const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
        //     console.log(distanceX, distanceY, distance, "keyframeWrapperRef.current")
        // }


    }, []);

    useUpdateEffect(()=> {
        if(keyframePanelRef.current) {

            // 30: panel margin
            let panelWidth = Number(window.getComputedStyle(keyframePanelRef.current).width.replace("px",""))

            let panelMaxWidth = maxW.current - 30

            if(panelWidth > panelMaxWidth) {
                // The width of the panel has reached the critical value, and the adaptation mode of the width needs to be modified.
                keyframePanelRef.current.style.width = "auto"
                panelWidth = Number(window.getComputedStyle(keyframePanelRef.current).width.replace("px",""))

                let dis = panelWidth - panelMaxWidth
                let count = Object.getOwnPropertyNames(stageList).length
                let minuend = dis / count / 2
                let padding = 8 - minuend < 1 ? 1 : 8 - minuend // 8 is the initial value of padding, see less file for details.



                let keyframeBoxList = document.querySelectorAll(".webviewerPlayBackControlWrapper .keyframeIconBox");
                console.log(keyframeBoxList, count, panelWidth, minuend)
                keyframeBoxList.forEach(item=> {
                    item.style.padding = `2px ${padding}px`
                })

                if(padding <= 1) {
                    panelWidth = Number(window.getComputedStyle(keyframePanelRef.current).width.replace("px",""))
                    dis = panelWidth - panelMaxWidth
                    minuend = dis / count

                    let keyframeIconList = document.querySelectorAll(".webviewerPlayBackControlWrapper .keyframeIcon");
                    console.log("padding",keyframeIconList )
                    let lineWidth = 6 - minuend < 1 ? 1 : 6 - minuend;

                    keyframeIconList.forEach(item=> {
                        item.style.width = `${lineWidth}px`
                    })

                }

            }

            console.log(panelWidth, "window.getComputedStyle(keyframePanelRef.current).width")
        }
    }, [stageList])


    /**
     * @description   next button handler. For jump to the next keyframe
     * */
    const nextKeyframeBtnHandler = () => {
        if(keyframeNumber == Object.getOwnPropertyNames(stageList).length - 1) {
            setKeyframeNumber(0)
            return
        }
        setKeyframeNumber(keyframeNumber + 1)

    }

    /**
     * @description   previous button handler. For jump to the previous keyframe
     * */
    const previousKeyframeBtnHandler = () => {
        if(keyframeNumber == 0) {
            setKeyframeNumber(Object.getOwnPropertyNames(stageList).length - 1)
            return
        }
        setKeyframeNumber(keyframeNumber - 1)

    }

    /**
     * @description   first keyframe button handler. For jump to the first keyframe
     * */
    const firstKeyframeBtnHandler = () => {
        setKeyframeNumber(0)

    }

    /**
     * @description   last keyframe button handler. For jump to the last keyframe
     * */
    const lastKeyframeBtnHandler = () => {
        setKeyframeNumber(Object.getOwnPropertyNames(stageList).length - 1)

    }

    /**
     * @description play button Handler. play or pause animation
     * */
    const playOrPauseBtnHandler = () => {
        setPlayBtnValue(!playBtnValue)
    }

    /**
     * @description    watch playBtnValue state. Timers are turned on or off depending on the playBtnValue status
     * */
    useEffect(() => {
        let timer = null // timer init
        let keyframeNum = keyframeNumber  // record keyframe number

        if(!playBtnValue) {
            // If the current keyframe is the last, start over
            if(keyframeNum == Object.getOwnPropertyNames(stageList).length - 1) {

                setKeyframeNumber(0)
                keyframeNum = 0
            }
            timer = setInterval(()=> {

                setKeyframeNumber(++keyframeNum)
                // Stop playing if it reaches the last frame
                if(keyframeNum >= Object.getOwnPropertyNames(stageList).length - 1) {
                    clearInterval(timer)
                    setPlayBtnValue(true)
                }
            }, 1000)
        }else {
            clearInterval(timer)
        }

        return () => clearInterval(timer)
    }, [playBtnValue]);

    /**
     * @description  Jumps to the current click keyframe
     * @param val Number
     * */
    const clickKeyframeHandler = (val) =>{
        setKeyframeNumber(val)
    }

    /**
     * @description  drag keyfame function
     * */
    const overKeyframe = (e, val) => {
        if (!isDrag) {
            return
        }

        setKeyframeNumber(val)
    }

    const mouseDownHandler = (e) => {
        setIsDrag(true)
    }

    /**
     * @description   The function used in mobile mode
     * */
    const pointerdown = () => {
        setMousedown(true);
    };

    const pointerup = () => {
        setMousedown(false);
    };

    // Move side slider drag function callback
    const handleNumbers = (index, action) => {
        if (action === "click" || (action === "drag" && mousedown)) {
            setKeyframeNumber(index);
        }
    };

    /**
     * @description   When the mouse is released globally, reset isDrag param
     * */
    window.addEventListener('mouseup', ()=> {
        setIsDrag(false)
    })

    useEffect(() => {
        // if(Object.getOwnPropertyNames(currentPatient).length) {
        //     // getStageInfoApi({fileName: currentPatient.fileName})
        //     //     .then(res=> {
        //     //         console.log(res,';;;;;;;;;;;;')
        //     //         if(res.code === 200 && res.data) {
        //     //             setStageList(res.data)
        //     //
        //     //             setStageCount(Object.getOwnPropertyNames(res.data).length)
        //     //         }
        //     //     })
        //     //     .catch(err=> {
        //     //         console.log(err)
        //     //     })
        //
        // }



    }, [currentPatient]);




    useEffect(() => {
        setKeyframeNumber(Object.getOwnPropertyNames(stageList).length - 1)

    }, [stageList]);

    useEffect(()=> {
        window.addEventListener("pointerdown", pointerdown, false);
        window.addEventListener("pointerup", pointerup, false);

        return () => {
            window.removeEventListener("pointerdown", pointerdown);
            window.removeEventListener("pointerup", pointerup);
        };
    }, [])

    useEffect(() => {
        if(!currentPatient.combineStageFlags) {
            setIsCombine(false)
        }else if(!currentPatient.separateStageFlags) {
            setIsCombine(true)
        }
    }, [currentPatient]);

    const adjust = (index) => {
        let count = Object.getOwnPropertyNames(stageList).length
        let flag = true

        if(count <= 50) {
            if(index % 5 === 0 || index === count - 1 || index === keyframeNumber) {
                flag = true
            }else {
                flag = false
            }
        }
        else if(count > 50) {
            if(index % 10 === 0 || index === count - 1 || index === keyframeNumber) {
                flag = true
            }else {
                flag = false
            }
        }

        return flag
    }

    const sortStageArrList = useMemo(() => {
        // console.log('stageList-----', stageList, Object.getOwnPropertyNames(stageList));
        if(!stageList) return []
        return Object.getOwnPropertyNames(stageList).sort((a, b) => {
            const numA = parseInt(a.replace("stage", ""));
            const numB = parseInt(b.replace("stage", ""));
            return numA - numB;
        })
    }, [stageList])

    return (
        <div className={'webviewerPlayBackControlWrapper'}>
            {
                buildTarget === "webviewer" ? <></> : (
                    <div className={'combineOrSeparateWrap'}>
                        <div className={'combine wrap ' + (currentPatient.combineStageFlags ? (isCombine ? 'active' : '') : 'disable' )} onClick={()=> {
                            setIsCombine(true)
                            clickCombineOrSeparate(true)
                        }}>
                            <span>Combine</span>
                        </div>
                        <div className={'separate wrap ' + (currentPatient.separateStageFlags ? (isCombine ? '' : 'active') : 'disable' )} onClick={()=> {
                            setIsCombine(false)
                            clickCombineOrSeparate(false)
                        }}>
                            <span>Separate</span>
                        </div>
                    </div>
                )
            }

            <div className={'playBack'}>
                {
                    iconArr.map(item => {
                        let btnClassName = ''
                        let fileName = item.fileName

                        if(item.fileName == '171') {
                            btnClassName = 'buttonStyle btnItemStyle1'
                        } else if(item.fileName == '175') {
                            btnClassName = 'buttonStyle btnItemStyle3'
                        } else {
                            btnClassName = 'buttonStyle btnItemStyle2'
                        }

                        if(item.fileName == 172 && !playBtnValue) {
                            item.fileName = 176
                        }

                        return (
                            <button className={btnClassName} onClick={() => clickControlItem(item.id)}>
                                <span className={'buttonLabelStyle'}>
                                    {/*<img src={require('../../../../icons/png/Frame ' + item.fileName + '.png')} width={24} height={24}/>*/}
                                    <SvgIcon iconClass={item.fileName} style={item.fileName == 173 || item.fileName == 174 ? {fontSize: 20} : {}} />
                                </span>
                            </button>

                        )
                    })
                }
                <ArchPopover right={'calc(50% - 132px)'} top={'8px'} width={'310px'} height={'43px'} placement={'topLeft'} text={text} overlayClassName={'playerPopover'} />
            </div>
            {
                isMobile ? (
                    <MobileSlider
                        max={Object.getOwnPropertyNames(stageList).length - 1}
                        value={keyframeNumber}
                        currentStep={keyframeNumber + 1}
                        min={0}
                        onChange={(val) => handleNumbers(val, "click")}
                    />
                ) : (
                    <div className={'keyframeControl'} ref={keyframeWrapperRef}>
                        <div className={'keyframePlane'} ref={keyframePanelRef}>
                            {
                                Object.getOwnPropertyNames(stageList).length === 0 ? <></> : (
                                    sortStageArrList.map((item, index) => {
                                        let num = Number(item.match(/\d+/))
                                        return(
                                            <div className={'keyframe'} onClick={() => clickKeyframeHandler(num - 1)} key={item} onMouseOver={(e)=> overKeyframe(e,num - 1)} onMouseDown={mouseDownHandler}>
                                                <div className={'keyframeIconBox'} style={{backgroundColor: keyframeNumber == num - 1 ? '#DCDDE0' : "transparent"}}>
                                                    {
                                                        num === 1 ? (
                                                            <div className={'keyframeIcon ' + `upper${num}`} ref={key1Ref} style={{backgroundColor: keyframeNumber == num - 1 ? '#8a8f98' : '#dcdde0', visibility: stageList[item]?.upper?.visible ? 'visible' : 'hidden'}}></div>
                                                        ) : num === 2 ? (
                                                            <div className={'keyframeIcon ' + `upper${num}`} ref={key2Ref} style={{backgroundColor: keyframeNumber == num - 1 ? '#8a8f98' : '#dcdde0', visibility: stageList[item]?.upper?.visible ? 'visible' : 'hidden'}}></div>
                                                        ) : <div className={'keyframeIcon ' + `upper${num}`} style={{backgroundColor: keyframeNumber == num - 1 ? '#8a8f98' : '#dcdde0', visibility: stageList[item]?.upper?.visible ? 'visible' : 'hidden'}}></div>
                                                    }
                                                    <div className={'keyframeIcon'} style={{backgroundColor: keyframeNumber == num - 1 ? '#8a8f98' : '#dcdde0', visibility: stageList[item]?.lower?.visible ? 'visible' : 'hidden'}}></div>
                                                </div>
                                                {
                                                    num - 1 < 10
                                                        ? <><span style={{position: "absolute", left: "50%", transform: "translateX(-50%)", display: adjust(index) ? "block" : "none"}}>{num - 1}</span></>
                                                        : <span style={{display: adjust(index) ? "inline-block" : "none", position: "absolute", left: "50%", transform: "translateX(-50%)"}}>{num - 1}</span>
                                                }
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
})

const mapStateToProps = state => ({
    keyframeNumber: state.webViewer.keyframeNumber,
    currentPatient: state.webViewer.currentPatient,
    isMobile: state.webViewer.isMobile,
    isCombine: state.webViewer.isCombine
})

const mapDispatchToProps = dispatch => ({
    setKeyframeNumber: val => dispatch(action.setKeyframeNumber(val)),
    setStageCount: val => dispatch(action.setStageCount(val)),
    setIsCombine: val => dispatch(action.setIsCombine(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayBackControl)
