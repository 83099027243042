import {verifyUpperOrLower} from "../lib/utils";
import store from "../store";
import {cloneDeep} from "lodash";
import {jawOrientationDetector} from "../lib/threeLib";

/**
 * @description  get gum data
 *
 * @param {Array} data  dental data
 * @param {Boolean} isUpper  The top half or the bottom half. The value is 'upper' or 'lower'
 * */
export const getGumData = (data, isUpper) => {

    return  data[verifyUpperOrLower(isUpper)].gum
}


/**
 * @description  get gum url
 *
 * @param {Object} data  gum data
 * @param {Number} stage  Current stage number
 * */
export const getGumUrl = (data, stage) => {

    return  data.urlList[stage].url
}


/**
 * @description  get gum file type
 *
 * @param {Object} data  gum data
 * @param {Number} stage  Current stage number
 * */
export const getGumFileType = (data, stage) => {

    return  data.urlList[stage].fileType
}

/**
 * @description  get gum matrix
 *
 * @param {Object} data  gum data
 * @param {Number} stage  Current stage number
 * */
export const getGumMatrix = (data, stage) => {

    return  data.matrixList[stage]
}


/**
 * @description  get tooth data
 *
 * @param {Object} data  gum data
 * */
export const getToothData = (data) => {

    return  data.tooth
}

/**
 * @description  get tooth matrix
 *
 * @param {Array} data  tooth data
 * @param {Number} stage  Current stage number
 * @param {Number} toothIndex  tooth index
 * */
export const getToothMatrix = (data, stage, toothIndex) => {

    return  data[toothIndex].matrix[stage]
}

/**
 * @description  get gum matrix
 *
 * @param {Object} data  tooth data
 * @param {Number} stage  Current stage number
 * @param {Number} number tooth number
 * */
export const getToothMatrixByNumber = (data, stage, number) => {
    let matrix

    data.map(item => {
        if (item.number === number) {
            matrix = item.matrix[stage]
        }
    })

    return matrix
}

/**
 * @description  get tooth movement
 *
 * @param {Array} data  tooth data
 * @param {Number} stage  Current stage number
 * @param {Number} number  tooth number
 * */
export const getToothMovement = (data, stage, number) => {

    let movement = {}

    data.map(item=> {
        if(item.number === number) {
            movement = item.movementData[stage]
        }
    })

    return  movement
}

/**
 * @description  get tooth url
 *
 * @param {Array} data  tooth data
 * @param {Number} toothIndex  tooth index
 * */
export const getToothUrl = (data, toothIndex) => {

    return  data[toothIndex].url
}

/**
 * @description  get tooth file type
 *
 * @param {Array} data  tooth data
 * @param {Number} toothIndex  tooth index
 * */
export const getToothFileType = (data, toothIndex) => {

    return  data[toothIndex].fileType
}

/**
 * @description  get tooth number
 *
 * @param {Array} data  tooth data
 * @param {Number} toothIndex  tooth index
 * */
export const getToothNumber = (data, toothIndex) => {

    return  data[toothIndex].number
}

/**
 * @description  get tooth visible
 *
 * @param {Array} data  tooth data
 * @param {Number} stage  Current stage number
 * @param {Number} toothIndex  tooth index
 * */
export const getToothVisible = (data, stage, toothIndex) => {

    return  data[toothIndex].visible[stage]
}


/**
 * @description  get IPR data
 *
 * @param {Object} data  gum data
 * */
export const getIPRData = (data) => {

    return  data.ipr
}

/**
 * @description  get ipr value
 *
 * @param {Array} data  ipr data
 * @param {Number} stage  Current stage number
 * @param {Number} index  The number of the current ipr, where the value is the index obtained by looping the ipr component
 * */
export const getIprValue = (data, stage, index) => {

    return  data[stage][index].IPRValue
}

/**
 * @description  get ipr tooth 1 index
 *
 * @param {Array} data  ipr data
 * @param {Number} stage  Current stage number
 * @param {Number} index  The number of the current ipr, where the value is the index obtained by looping the ipr component
 * */
export const getIPRToothOneIndex = (data, stage, index) => {

    return  data[stage][index].tooth1
}

/**
 * @description  get ipr tooth 2 index
 *
 * @param {Array} data  ipr data
 * @param {Number} stage  Current stage number
 * @param {Number} index  The number of the current ipr, where the value is the index obtained by looping the ipr component
 * */
export const getIPRToothTwoIndex = (data, stage, index) => {

    return  data[stage][index].tooth2
}

/**
 * @description  get ipr center position
 *
 * @param {Array} data  ipr data
 * @param {Number} stage  Current stage number
 * @param {Number} index  The number of the current ipr, where the value is the index obtained by looping the ipr component
 * */
export const getIPRCenterPosition = (data, stage, index) => {

    return  data[stage][index].centerPosition
}

/**
 * @description  get ipr vertices
 *
 * @param {Array} data  ipr data
 * @param {Number} stage  Current stage number
 * @param {Number} index  The number of the current ipr, where the value is the index obtained by looping the ipr component
 * */
export const getIPRVertices = (data, stage, index) => {

    return  data[stage][index].vertices
}


/**
 * @description  get pontic data
 *
 * @param {Object} data  gum data
 * */
export const getPonticData = (data) => {

    return  data.pontic
}

/**
 * @description  get pontic url list
 *
 * @param {Array} data  gum data
 * @param {Number} ponticIndex  pontic index
 * */
export const getPonticUrlList = (data, ponticIndex) => {

    return  data[ponticIndex].urlList
}

/**
 * @description  get pontic start stage
 *
 * @param {Array} data  gum data
 * @param {Number} ponticIndex  pontic index
 * */
export const getPonticStartStage = (data, ponticIndex) => {

    return  data[ponticIndex].startStage
}


/**
 * @description  get pontic end stage
 *
 * @param {Array} data  gum data
 * @param {Number} ponticIndex  pontic index
 * */
export const getPonticEndStage = (data, ponticIndex) => {

    return  data[ponticIndex].endStage
}


/**
 * @description  get pontic end stage
 *
 * @param {Array} data  gum data
 * @param {Number} urlIndex  pontic index
 * */
export const getPonticUrl = (data, urlIndex) => {

    return  data[urlIndex].url
}



/**
 * @description  get pontic end stage
 *
 * @param {Array} data  dental data
 * @param {Number} number  toothNumber
 * @param {Number} stage Current stage number
 * @param {Object} newMatrix new matrix
 * */
export const setToothMatrixAndMovementHandler = (data, number, stage, newMatrix, movement) => {

    let subscript = jawOrientationDetector(number) ? 0 : 1

    data[subscript].gum.tooth.forEach(item=> {
        if(item.number === number) {

            item.matrix[stage] = newMatrix
            item.movementData[stage] = movement
        }
    })

    return  data
}

/**
 * @description
 *
 * @param {Number} stage
 * @param {Boolean} isUpper
 * */
export const getTableViewMovementData = (data, stage) => {

    let array = []

    data.map(val=> {
        array.push({
            number: val.number,
            movement: val.movementData[stage],
            visible: val.visible[stage] === 1 ? true : false
        })
    })

    return array

}


/**
 * @description    get hooks model url
 *
 * @param {number} toothNumber  tooth number
 * @param {Boolean} isUpper
 * @param {String} type item type
 * */
export const getItemsModelUrl = (data, toothIndex) => {


    return data[toothIndex].children[0].url
}


