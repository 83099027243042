import React, {useRef, useState, useEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
// import action from '../../../../store/action/index'
import {Button, message, Radio, Upload} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadPatientFileApi } from '../../../../http/api/webviewerApi'
import ArchPopover from "../../../../Components/ArchPopover";
import {debounce} from "lodash";


function UploadFile(props) {

    const {isMobile, currentDataTool, setCurrentRequest, loadDataHandler} = props

    const [loading, setLoading] = useState(false);

    const [fileList, setFileList] = useState(null)


    const text = ['Upload a new patient’s data(.zip) <br/>for internal usage']


    const customRequest = (file, fileList) => {
        console.log('123')
        setFileList(null)
        loadDataHandler(fileList)
    }


    //style={{display: isMobile ? 'none' : 'inline-block'}}
    return (
        <div className={'uploadWrapper'} style={{display: isMobile ? 'none' : 'inline-block'}}>
            <Upload
                multiple
                fileList={fileList}
                // customRequest={customRequest}
                showUploadList={false}
                beforeUpload={debounce((file, fileList)=> customRequest(file, fileList), 200)}
            >
                <Button className={'uploadBtn'} icon={<UploadOutlined />}>Upload Patient</Button>
            </Upload>
            <ArchPopover right={'-10px'} top={'-10px'} width={'231px'} height={'45px'} placement={'bottomLeft'} overlayClassName={'uploadPopover'} text={text} />
        </div>
    );
}

const mapStateToProps = state => ({
    isMobile: state.webViewer.isMobile,
    currentDataTool: state.dataTool.currentDataTool,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile)
