// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-class {
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
  min-width: 14px;
  width: 1em;
  height: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SvgIcon/index.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;AACF","sourcesContent":[".svg-class {\n  display: inline-block;\n  overflow: hidden;\n  font-size: 14px;\n  min-width: 14px;\n  width: 1em;\n  height: 1em;\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
