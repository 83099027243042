// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:global .ant-checkbox-input {
  background-color: black;
}
.buttonStyle {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.buttonLabelStyle {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
.threeAnnotations {
  display: none !important;
}
.canvasStyle div {
  display: none !important;
}
.canvasStyle div:nth-of-type(1) {
  display: block !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/global.less"],"names":[],"mappings":"AAEA;EAEI,uBAAA;AAFJ;AAMA;EACE,cAAA;EACA,SAAA;EACA,eAAA;EACA,SAAA;EACA,oBAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;EACA,qBAAA;EACA,6BAAA;EACA,wBAAA;EACA,wCAAA;AAJF;AAOA;EACE,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,wBAAA;AALF;AAOA;EACE,wBAAA;AALF;AAQA;EAMI,wBAAA;AAXJ;AAOI;EACE,yBAAA;AALN","sourcesContent":["\n\n:global {\n  .ant-checkbox-input {\n    background-color: black;\n  }\n\n}\n.buttonStyle {\n  color: inherit;\n  border: 0;\n  cursor: pointer;\n  margin: 0;\n  display: inline-flex;\n  outline: 0;\n  padding: 0;\n  position: relative;\n  align-items: center;\n  user-select: none;\n  border-radius: 0;\n  vertical-align: middle;\n  -moz-appearance: none;\n  justify-content: center;\n  text-decoration: none;\n  background-color: transparent;\n  -webkit-appearance: none;\n  -webkit-tap-highlight-color: transparent;\n}\n\n.buttonLabelStyle {\n  width: 100%;\n  display: inherit;\n  align-items: inherit;\n  justify-content: inherit;\n}\n.threeAnnotations {\n  display: none !important;\n}\n\n.canvasStyle {\n  div {\n    &:nth-of-type(1) {\n      display: block !important;\n    }\n\n    display: none !important;\n  }\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
