import React, {useRef, useState, useEffect, useCallback, useMemo} from "react";
import {Line, PivotControls, Center} from "@react-three/drei";
import {connect} from "react-redux";
import action from "../../../../store/action";

import * as THREE from 'three'

import store from "../../../../store";


import {
    getGumData,
    getToothData,
    getIPRData,
    getIPRToothOneIndex,
    getIPRToothTwoIndex,
    getIPRCenterPosition,
    getIprValue,
    getIPRVertices
} from '../../../../dataInterface/index'

import {cloneDeep} from "lodash";
import IPR from "../../../../Components/CreateModal/IPR";
import CollisionArea from "../../../../Components/CreateModal/CollisionArea";
import {useFrame} from "@react-three/fiber";


function IPRDataPro(props) {

    const {
        data,
        stage,
        isUpper,
        cameraControl,
        sliderVal,
        IPRCheckboxValue,
        collisionCheckboxValue,
        keyframeNumber
    } = props

   const [iprData, setIPRData] = useState([])
   const [toothData, setToothData] = useState([])

   const iprRef = useRef()
   const collisionRef = useRef()




    /**
     * @description   set data
     * */

    useEffect(()=> {
        let gumData = getGumData(data, isUpper)

        let copyIprData = cloneDeep(getIPRData(gumData))

        let copyToothData = cloneDeep(getToothData(gumData))

        setToothData(copyToothData)

        setIPRData(copyIprData)

    }, [])


    /**
     * @description adjust model opacity
     * */
        // slider control model opacity
    const [opacity, setOpacity] = useState(1)  // model opacity

    useEffect(() => {
        if(!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal) * 5) * 0.01)

        }else if(isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal * 5) * 0.01)

        }else {
            setOpacity(1)

        }

    }, [sliderVal]);

    useEffect(() => {
        // console.log(iprRef)
    }, [keyframeNumber]);

    // console.log(iprData, IPRCheckboxValue, stage, keyframeNumber,"yoyoyoyssssss")


    return (
        iprData.length !== 0 && toothData.length !== 0 ? (
            <>
                <group name={'IPR'} renderOrder={1} transparent depthTest visible={IPRCheckboxValue && (stage === keyframeNumber)} ref={iprRef} stage={stage} keyframeNumber={keyframeNumber} isUpper={isUpper}>
                    {

                        iprData[stage].map((iprItem, iprIndex)=> {
                            return(
                                <>

                                    <IPR
                                        camera={cameraControl}
                                        isUpper={isUpper}
                                        stage={stage}
                                        tooth1={getIPRToothOneIndex(iprData, stage, iprIndex)}
                                        tooth2={getIPRToothTwoIndex(iprData, stage, iprIndex)}
                                        iprValue={getIprValue(iprData, stage, iprIndex)}
                                        centerPosition={getIPRCenterPosition(iprData, stage, iprIndex)}
                                        toothData={toothData}
                                        opacity={opacity}
                                    />
                                </>
                            )
                        })

                    }
                </group>
                <group name={'collisions'} visible={collisionCheckboxValue} ref={collisionRef}>
                    {
                        iprData[stage].map((iprItem, iprIndex)=> {
                            return(
                                <>
                                    <CollisionArea
                                        type='lower'
                                        isUpper={isUpper}
                                        stage={stage}
                                        tooth1={getIPRToothOneIndex(iprData, stage, iprIndex)}
                                        tooth2={getIPRToothTwoIndex(iprData, stage, iprIndex)}
                                        centerPosition={getIPRCenterPosition(iprData, stage, iprIndex)}
                                        vertices={getIPRVertices(iprData, stage, iprIndex)}
                                        toothData={toothData}
                                        opacity={opacity}
                                    />
                                </>

                            )
                        })
                    }
                </group>
            </>
        ) : <></>

    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    IPRCheckboxValue: state.webViewer.IPRCheckboxValue,
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    collisionCheckboxValue: state.webViewer.collisionCheckboxValue,
    keyframeNumber: state.webViewer.keyframeNumber,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(IPRDataPro)
