import {Spin, Modal} from 'antd'
import './index.less'
import action from "../../../../store/action";
import {connect} from "react-redux";
import {useProgress} from '@react-three/drei'
import React,{useEffect, useState} from 'react'

function Loading(props) {

    const {isLoading, loadingData, setIsLoading} = props

    let {progress} = useProgress()


    useEffect(() => {
        console.log(progress, 'progress')
        try {
            if(progress === 100) {
                setIsLoading(false)
            }
        }
        catch (e) {
            console.error(e, "loading error")
            setIsLoading(false)
        }


    }, [progress]);


    return (
        <>
            {
                isLoading ? (
                    <Modal open={isLoading} footer={null} mask={false} centered closeIcon={<></>}>
                        {
                            loadingData ? (
                                <>
                                    <div style={{fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px'}}>Loading data, please wait...</div>
                                    <Spin size="large" style={{marginLeft: '50%', transform: 'translate(-50%)'}}  />
                                </>
                            ) : (
                                <>
                                    <div style={{fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px'}}>Loading model, please wait...</div>
                                    <Spin size="large" style={{marginLeft: '50%', transform: 'translate(-50%)'}}  />
                                    {/*<div style={{fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px'}}>{Number(num).toFixed(0) + '%'}</div>*/}
                                </>
                            )
                        }
                    </Modal>
                ) : <></>
            }
        </>
    );
}

const mapStateToProps = state => ({
    isLoading: state.webViewer.isLoading,
    loadingData: state.webViewer.loadingData,
})

const mapDispatchToProps = dispatch => ({
    setIsLoading: val => dispatch(action.setIsLoading(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
