import React, {useEffect, useRef, useState} from 'react'
import OptionStyle from './index.less'
import Layer from './Components/Layers/index'
import PlayBackControl from './Components/PlayBackControl/index'
import View from './Components/View/index.js'
import DataPanel from './Components/DataPanel/index'
import SelectVersion from './Components/SelectVersion/index'
import Loading from './Components/Loading/index'
import Upload from './Components/Upload/index'
import PatientsList from './Components/PatientsList/index'
import CircleMenu from './Components/CircleMenu/index'
import {Slider} from 'antd';
import {connect} from "react-redux";
import action from "../../store/action";
import ArchPopover from "../../Components/ArchPopover";
import Setup from './Components/Setup/index'
import SelectDataTool from './Components/SelectDataTool/index'
import ByteControl from './ByteComponents/ByteControl/index'
import ArchSlider from "../../Components/Slider/index"
import { getMode } from '../../utils'


/**
 * @description control panel. Used to change config state and change 3D model
 * */

function Option(props) {
    const {
        setSliderValue,
        sliderVal,
        currentClickItemId,
        setIsMobile,
        isMobile,
        isLoading,
        dentalData,
        setDentalData,
        currentDataTool,
        patientsList,
        setPatientsList,
        stageList,
        byteStageList,
        setCurrentRequest,
        clickCombineOrSeparate,
        loadDataHandler,
        isUpdated,
        loadModel,
        buildTarget
    } = props

    const isMobileHandler = () => {
        let userAgentInfo = navigator.userAgent;
        let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        let getArr = Agents.filter(i => userAgentInfo.includes(i));
        return getArr.length ? true : false;
    }



    /**
     * @description   slider value change handler
     * */

    const [isDrag, setIsDrag] = useState(false)

    const onChange = (value) => {
        if (isNaN(value) || disabled) {
            return;
        }
        console.log(value)
        setIsDrag(true)


        if(value < 10) {

        }

        setSliderValue(value)
    };

    const onAfterChange = (value) => {
        // setIsDrag(false)
        //
        // if (value >= -10 && value <= 10) {
        //     setSliderValue(0)
        // }
    }


    const [disabled, setDisabled] = useState(false)

    useEffect(() => {

        let dom = document.querySelector(".ant-slider-handle")
        let wrapperDom = document.querySelector(".sliderWrapper")
        let railDom = document.querySelector(".ant-slider")

        if(dom && wrapperDom) {

            let wrapperLeft = wrapperDom.offsetLeft
            let railLeft = railDom.offsetLeft
            let railWidth = railDom.clientWidth

            const left = wrapperLeft + railLeft


            dom.addEventListener("mousedown", (e)=> {
                console.log(e, sliderVal)
                let initLeft = dom.current.offsetLeft
                let startX = e.clientX - initLeft

                let flag = true;

                let isFirst = true

                document.onmousemove = (e) => {
                    if(flag) {
                        return
                    }

                    let left = e.clientX - startX

                    if( 54 > initLeft || initLeft > 94) {
                        isFirst = false
                    }

                    const distanceX = Math.abs(left - 74)

                    if(isFirst && distanceX > 20) {
                        isFirst = false
                    }



                    if(distanceX < 20 && !isFirst) {
                        setSliderValue(0)
                        flag = true
                    }
                }

                document.onmouseup = (e) => {
                    document.onmousemove = null
                    document.onmouseup = null

                }
            })
        }


        //
        // if(isDrag) {
        //     let arr = []
        //     window.addEventListener("mousemove",(e)=> mousemoveHandler(e, arr))
        // }
        // else {
        //
        //     window.removeEventListener("mousemove", mousemoveHandler)
        // }
        //
        // return () => {
        //
        //     window.removeEventListener("mousemove", mousemoveHandler);
        // };
    }, []);

    const onSliderChange = (val) => {
        console.log(val)
        setSliderValue(val)
    }

    const text = ['Drag along the slider will change the transparency of upper/lower arches.']
    const text2 = ['Check comments.']

    useEffect(() => {
        const bool = isMobileHandler()
        setIsMobile(bool)
    }, []);

    useEffect(() => {
        console.log(dentalData)
    }, [dentalData]);

    const { advancedMode } = getMode()

    return (
        <div className={'sceneConfigWrap'} style={{width: '100vw', height: '100vh', position: 'absolute',}}>
            <div className={'container'}>
                {/* webviewer option */}
                <div style={{width: '100%'}}>
                    {advancedMode && <CircleMenu buildTarget={buildTarget} dentalData={dentalData} setDentalData={setDentalData} isUpdated={isUpdated} />}
                    {
                        buildTarget === "webviewer_dataTool" ? (
                            <SelectDataTool setPatientsList={setPatientsList} />
                        ) : <></>
                    }
                    {/* Old Note: webviewer name */}
                    {/* style={{transform: isMobile ? "translate(-50%)" : "translate(calc(-50% - 65px))"}} */}
                    {/*<Upload setCurrentRequest={setCurrentRequest} loadDataHandler={loadDataHandler}/>*/}
                    <PatientsList loadModel={loadModel} patientsList={patientsList} setCurrentRequest={setCurrentRequest}/>
                    {
                        advancedMode 
                            ? currentDataTool.key === 1 ? <></> : <Layer/>
                            : null
                    }
                    {
                        currentDataTool.key !== 1  ? (
                            <PlayBackControl stageList={stageList} buildTarget={buildTarget} clickCombineOrSeparate={(val)=> clickCombineOrSeparate(val)}/>
                        ) :  (
                            <ByteControl byteStageList={byteStageList} clickCombineOrSeparate={(val)=> clickCombineOrSeparate(val)} />
                        )
                    }

                    {
                        !isLoading ? (
                            currentDataTool.key !== 1 ? (
                                <DataPanel dentalData={dentalData} setDentalData={setDentalData}/>
                            ) : <></>
                        ) : <></>
                    }
                    <View/>
                    {/*{*/}
                    {/*    buildTarget === "webviewer_dataTool" ? (*/}
                    {/*        <></>*/}
                    {/*    ) : (*/}
                    {/*        <div style={{position: 'absolute', width: '100%', zIndex: 11}}>*/}
                    {/*            <SelectVersion/>*/}
                    {/*            <Setup/>*/}
                    {/*            /!*<div className={'commentWrapper'} style={{display: isMobile ? 'none' : 'flex'}}>*!/*/}
                    {/*            /!*    <ArchPopover right={'-10px'} top={'-10px'} width={'140px'} height={'25px'}*!/*/}
                    {/*            /!*                 placement={'topRight'} overlayClassName={'sliderPopover'} text={text2}/>*!/*/}
                    {/*            /!*    <span>Comments</span>*!/*/}
                    {/*            /!*</div>*!/*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}

                    <div className={'sliderWrapper'} style={isMobile ? {top: '36px'} : {}}>
                        <ArchPopover right={'-10px'} top={'-10px'} width={'253px'} height={'50px'}
                                     placement={'topRight'} overlayClassName={'sliderPopover'} text={text}/>
                        <ArchSlider value={sliderVal} range={[-100, 100]} snapped={true} snapNumber={10} className={"customSlider"} onChange={onSliderChange} />
                        {/*<Slider*/}
                        {/*    min={-100}*/}
                        {/*    max={100}*/}
                        {/*    onChange={onChange}*/}
                        {/*    onAfterChange={onAfterChange}*/}
                        {/*    value={typeof sliderVal === 'number' ? sliderVal : 0}*/}

                        {/*    tooltip={{*/}
                        {/*        open: false,*/}
                        {/*        formatter: val => String(val).replace('-', '')*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <div className={'sliderLabel'}>
                            <p className={'upper'}>Upper</p>
                            <p className={'lower'}>Lower</p>
                        </div>
                    </div>
                    {/*<div style={{position: "absolute", bottom: "16px", left: "70%", color: "white"}}>Code Version: 20230721_1458</div>*/}
                    <Loading/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    currentClickItemId: state.webViewer.currentClickItemId,
    isMobile: state.webViewer.isMobile,
    isLoading: state.webViewer.isLoading,
    currentDataTool: state.dataTool.currentDataTool,
})

const mapDispatchToProps = dispatch => ({
    setSliderValue: val => dispatch(action.setSliderValue(val)),
    setIsMobile: val => dispatch(action.setIsMobile(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Option)
