import React, {useEffect, createContext, useState} from 'react'
import {connect} from "react-redux";
import action from "../../../../store/action";
import {cloneDeep} from "lodash";
import {computeModalData} from "../../../../lib/threeLib";
import {
    createGumMatrix,
    createIPRData,
    createNumbersData,
    createTeethMatrices,
    createVisibilityData
} from "../../../../lib/dataConversion";
import DragModal from '../../../../Components/DragModal/index'


function WebDataTool(props) {

    const {
        currentPatient,
        stageCount,
        setLoadingData,
        fileData,
        getDentalData
    } = props


    const [superimpositionMatrixList, setSuperimpositionMatrixList] = useState([])

    const [dentalData, setDentalData] = useState([])

    const [visible, setVisible] = useState(false)

    const [errText, setErrText] = useState('')


    /**
     * @description   creates an array of objects representing the upper and lower gum of a dental model, each containing
     * information about their respective teeth, IPD, and pontic.
     * */
    const createMainStructure = () => {
        let gumObj = {
            type: "gum",
            matrixList: new Array(stageCount),
            urlList: new Array(stageCount),
            tooth: null,
            ipr: new Array(stageCount),
            pontic: new Array(stageCount)
        }

        let data = [
            {
                isUpper: true,
                gum: cloneDeep(gumObj)
            },
            {
                isUpper: false,
                gum: cloneDeep(gumObj)
            }
        ]

        return cloneDeep(data)
    }

    /**
     * @description    creates an array of objects representing a single tooth, including its movement data, attachment, hooks,
     * cutouts, and bite stop.
     * */
    const createToothStructure = (toothUrl, bool) => {

        let saveArray = new Array(toothUrl.size)

        let obj = {
            type: "tooth",
            matrix: new Array(stageCount),
            url: "",
            number: 18,
            visible: new Array(stageCount),
            fileType: "stl",
            movementData: new Array(stageCount),
            children: [
                {
                    type: "attachment",
                    url: "",
                    fileType: "stl"
                },
                {
                    type: "hooks",
                    url: "",
                    fileType: "stl"
                },
                {
                    type: "cutouts",
                    url: "",
                    fileType: "stl"
                },
                {
                    type: "biteStop",
                    url: "",
                    fileType: "stl"
                }
            ]
        }

        for(let [index, value] of toothUrl.entries()) {
            let copyObj = cloneDeep(obj)

            const regex = /(\.drc$)|(\.stl$)/
            let fileType = index.match(regex)[0].replace('.','')

            copyObj.url = value
            copyObj.fileType = fileType

            let num = Number(index.replace(currentPatient.fileName, '').replace(currentPatient.fileName, '').match(/\d+/)[0])

            saveArray[num - 1] = copyObj
        }

        return saveArray
    }

    const createPonticStructure = (ponticData) => {
        let array = []

        let defaultObj = {
            number: 0,
            urlList: [],
            startStage: 0,
            endStage: 0,
        }


        for(let [index, value] of ponticData.entries()) {
            // console.log('=======================================')
            let stageNum = Number(index.match(/_STAGE_(\d+)/)[1]) - 1
            let ponticNum =  Number(index.match(/__PONTIC_(\d+)/)[1])
            // console.log('stageNum: ', stageNum)
            // console.log('ponticNum: ', ponticNum)
            // console.log('array: ', array)
            let flag = array.some(arg=> arg.number == ponticNum)
            // console.log(flag,ponticNum)
            if(array.length === 0) {
                let copyObj = Object.assign(defaultObj)
                copyObj.number = ponticNum

                copyObj.startStage = stageNum

                copyObj.endStage = stageNum

                array.push(copyObj)
            }else {


                array.map(item=> {
                    // console.log(item)
                    if(!flag) {
                        let copyObj = cloneDeep(defaultObj)

                        copyObj.number = ponticNum

                        copyObj.startStage = stageNum

                        copyObj.endStage = stageNum

                        array.push(copyObj)
                    }else {
                        if(item.startStage > stageNum) {
                            item.startStage = stageNum
                        }

                        if(item.endStage < stageNum) {
                            item.endStage = stageNum
                        }
                    }
                })


            }
        }

        array.map(item=> {

            item.urlList = new Array(item.endStage - item.startStage + 1).fill(undefined)
        })
        // console.log(array, 'ponnnnnnnnmmm', ponticData)
        // console.log('=======================================')
        return array
    }

    /**
     * @description    populates a tooth object in a gum object with the specified URL list.
     * */
    const createToothItemByUrlList = (urlList, data, isUpper) => {

        for(let [index, value] of urlList.entries()) {
            let num = Number(index.replace(currentPatient.fileName, '').replace(currentPatient.fileName, '').match(/\d+/)[0])

            const regex = /(\.drc$)|(\.stl$)/
            let fileType = index.match(regex)[0].replace('.','')

            let itemType = index.match(/__TOOTH_\d+_(.*)\.stl/)[1]

            console.log(itemType, "itemType")

            let subScript = isUpper ? 0 : 1

            let childrenSubScript = 0

            if(itemType === 'HOOK') {
                childrenSubScript = 1
            }else if(itemType === 'ATTACH') {
                childrenSubScript = 0
            }else if(itemType === 'CUTOUT') {
                childrenSubScript = 2
            }else if(itemType === 'BITESTOP') {
                childrenSubScript = 3
            }

            data[subScript].gum.tooth[num-1].children[childrenSubScript].url = value
            data[subScript].gum.tooth[num-1].children[childrenSubScript].fileType = fileType
        }
    }

    /**
     * @description    parses a base64-encoded string and applies the specified function to each entry in the resulting array.
     * */
    const base64ToothNumberDataParsing = (data, fn) => {
        let list = [];

        for(let [index, value] of data.entries()) {
            list.push(fn(value))
        }

        return list
    }

    /**
     * @description    It is similar to base64ToothNumberDataParsing, but the resulting array has a length equal to the number of stages in the model.
     *
     * */
    const base64StageDataParsing = (data, fn) => {
        let list = new Array(stageCount).fill(undefined);

        for(let [index, value] of data.entries()) {
            let num = index.replace(currentPatient.fileName, '').replace(currentPatient.fileName, '').match(/\d+/)[0]

            list[num - 1] = fn(value)


        }
        list = fillArray(list)
        return list
    }

    /**
     * @description    sets the tooth number property in each object in the target array to the corresponding value in the data array.
     * */
    const setToothNumber = (data, target) => {
        target.map((item, i)=> {
            item.number = data[i]
        })
    }

    /**
     * @description    sets the tooth visible property in each object in the target array to the corresponding value in the data array.
     * */
    const setToothVisible = (data, target) => {

        let array = transposeArray(data)

        target.map((item,i)=> {
            item.visible = array[i]
        })

    }

    /**
     * @description    sets the tooth matrix property in each object in the target array to the corresponding value in the data array.
     * */
    const setToothMatrix = (data, target) => {
        let array = transposeArray(data)

        target.map((item,i)=> {
            item.matrix = array[i]
        })

    }

    const setToothMovement = (toothMatrixList, gumMatrixList, target) => {

        target.map((item, i)=> {
            toothMatrixList.map((val, j)=> {
                let obj = computeModalData(toothMatrixList[0][i], toothMatrixList[j][i], item.number,gumMatrixList[0], gumMatrixList[j])
                item.movementData[j] = obj
            })
        })

    }

    /**
     * @description     The matrix of superimposition is calculated and each frame data is stored as an array.
     * */
    const getSuperimpositionMatrix = (toothData, gumData, isUpper) => {
        let array = new Array(toothData.length).fill(undefined)

        toothData.map((item, index)=> {
            let arrayItem = []

            gumData.map((val, j) => {
                // Due to the existence of biteshift, we need to convert the initial tooth data to the
                // gum coordinate system to recalculate the matrix of superimposition
                let mat = cloneDeep(gumData[0]).invert().multiply(item.matrix[0])

                mat = cloneDeep(val).multiply(mat.clone())

                arrayItem.push(mat)
            })

            array[index] = arrayItem
        })

        return array
    }

    const setPonticUrl = (data, target) => {
        for(let [index, value] of data.entries()) {
            let stageNum = Number(index.match(/_STAGE_(\d+)/)[1]) - 1
            let ponticNum =  Number(index.match(/__PONTIC_(\d+)/)[1])

            const regex = /(\.drc$)|(\.stl$)/;
            const fileType = index.match(regex)[0].replace('.','')

            target.map(item=> {
                if(item.number === ponticNum) {
                    item.urlList[stageNum - item.startStage] = {
                        url: value,
                        fileType
                    }
                }
            })


        }

        target.map(item=> {
            item.urlList = fillArray(item.urlList)
        })


    }

    const addGumUrl = (data, target, isUpper) => {
        for(let [index, value] of data.entries()) {
            const regex1 = /_STAGE_(\d+)/;
            const match = index.match(regex1)[1];
            let num = Number(match)

            const regex2 = /(\.drc$)|(\.stl$)/;
            const fileType = index.match(regex2)[0].replace('.','')


            let subScript = isUpper ? 0 : 1

            target[subScript].gum.urlList[num - 1] = {
                url: value,
                fileType
            }
        }

        //
        target.map(item=> {
            item.gum.urlList = fillArray(item.gum.urlList)
        })

    }

    /**
     * @description     transposes a 2D array.
     * */
    const transposeArray = (data) => {

        let array = new Array(data[0].length).fill().map(() => new Array(data.length).fill(0))

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].length; j++) {
                array[j][i] = data[i][j];
            }
        }

        return array
    }

    /**
     * @description    fills any undefined elements in the array with the most recently defined element.
     * */
    const fillArray = (data) => {

        let recordSubscript = 0

        let copyData = cloneDeep(data).map((item, i)=> {

            if(item !== undefined) {
                recordSubscript = i
            }else {
                item = cloneDeep(data)[recordSubscript]
            }

            return item
        })

        return copyData
    }

    // Returns data according to the file specified type
    const getFileDataByReg = (reg, data) => {
        let map = new Map()

        for (let [index, value] of data.entries()) {

            if(index.match(reg)) {
                map.set(index, value)
            }
        }

        return map
    }

    // Data is obtained in batches according to the keyframe number
    const dataFactory = () => {
        // post data and uncompressing .zip
        try {
            let backendData = cloneDeep(fileData)

            //
            let upperToothUrlList = getFileDataByReg(/(__UPPER__TOOTH_(\d+)\.stl)|(__UPPER__TOOTH_(\d+)\.drc)/,backendData)
            let lowerToothUrlList = getFileDataByReg(/(__LOWER__TOOTH_(\d+)\.stl)|(__LOWER__TOOTH_(\d+)\.drc)/,backendData)
            let upperGumUrlList = getFileDataByReg(/((?=.*_STAGE_(\d+))(?=.*__UPPER__GUM\.stl))|((?=.*_STAGE_(\d+))(?=.*__UPPER__GUM\.drc))/,backendData)
            let lowerGumUrlList = getFileDataByReg(/((?=.*_STAGE_(\d+))(?=.*__LOWER__GUM\.stl))|((?=.*_STAGE_(\d+))(?=.*__LOWER__GUM\.drc))/,backendData)
            let upperHookUrlList = getFileDataByReg(/(__UPPER__TOOTH_(\d+)_HOOK\.stl)|(__UPPER__TOOTH_(\d+)_HOOK\.drc)/,backendData)
            let lowerHookUrlList = getFileDataByReg(/(__LOWER__TOOTH_(\d+)_HOOK\.stl)|(__LOWER__TOOTH_(\d+)_HOOK\.drc)/,backendData)
            let upperCutoutUrlList = getFileDataByReg(/(__UPPER__TOOTH_(\d+)_CUTOUT\.stl)|(__UPPER__TOOTH_(\d+)_CUTOUT\.drc)/,backendData)
            let lowerCutoutUrlList = getFileDataByReg(/(__LOWER__TOOTH_(\d+)_CUTOUT\.stl)|(__LOWER__TOOTH_(\d+)_CUTOUT\.drc)/,backendData)
            let upperAttachUrlList = getFileDataByReg(/(__UPPER__TOOTH_(\d+)_ATTACH\.stl)|(__UPPER__TOOTH_(\d+)_ATTACH\.drc)/,backendData)
            let lowerAttachUrlList = getFileDataByReg(/(__LOWER__TOOTH_(\d+)_ATTACH\.stl)|(__LOWER__TOOTH_(\d+)_ATTACH\.drc)/,backendData)
            let upperBiteStopUrlList = getFileDataByReg(/(__UPPER__TOOTH_(\d+)_BITESTOP\.stl)|(__UPPER__TOOTH_(\d+)_BITESTOP\.drc)/,backendData)
            let lowerBiteStopUrlList = getFileDataByReg(/(__LOWER__TOOTH_(\d+)_BITESTOP\.stl)|(__LOWER__TOOTH_(\d+)_BITESTOP\.drc)/,backendData)
            let upperPonticUrlList = getFileDataByReg(/((?=.*_STAGE_(\d+))(?=.*__UPPER__PONTIC_\d+\.stl))|((?=.*_STAGE_(\d+))(?=.*__UPPER__PONTIC_\d+\.drc))/,backendData)
            let lowerPonticUrlList = getFileDataByReg(/((?=.*_STAGE_(\d+))(?=.*__LOWER__PONTIC_\d+\.stl))|((?=.*_STAGE_(\d+))(?=.*__LOWER__PONTIC_\d+\.drc))/,backendData)

            //
            let upperToothNumberFile = getFileDataByReg(/(__UPPER__TOOTH_NUMBERS\.raw)/,backendData)
            let lowerToothNumberFile = getFileDataByReg(/(__LOWER__TOOTH_NUMBERS\.raw)/,backendData)

            let upperToothVisibleFile = getFileDataByReg(/(__UPPER__VISIBILITY\.raw)/,backendData)
            let lowerToothVisibleFile = getFileDataByReg(/(__LOWER__VISIBILITY\.raw)/,backendData)

            let upperToothMatrixFile = getFileDataByReg(/(__UPPER__TOOTH\.raw)/,backendData)
            let lowerToothMatrixFile = getFileDataByReg(/(__LOWER__TOOTH\.raw)/,backendData)

            let upperGumMatrixFile = getFileDataByReg(/(__UPPER__GUM\.raw)/,backendData)
            let lowerGumMatrixFile = getFileDataByReg(/(__LOWER__GUM\.raw)/,backendData)

            let upperIPRFile = getFileDataByReg(/(__UPPER__IPR\.raw)/,backendData)
            let lowerIPRFile = getFileDataByReg(/(__LOWER__IPR\.raw)/,backendData)


            //
            let data = createMainStructure()
            data[0].gum['tooth'] = createToothStructure(upperToothUrlList, true)
            data[1].gum['tooth'] = createToothStructure(lowerToothUrlList, true)

            data[0].gum['pontic'] = createPonticStructure(upperPonticUrlList)
            data[1].gum['pontic'] = createPonticStructure(lowerPonticUrlList)

            console.log(data, '-+++++++++++++++++++++++')

            //
            createToothItemByUrlList(upperHookUrlList, data, true)
            createToothItemByUrlList(lowerHookUrlList, data, false)
            createToothItemByUrlList(upperAttachUrlList, data, true)
            createToothItemByUrlList(lowerAttachUrlList, data, false)
            createToothItemByUrlList(upperCutoutUrlList, data, true)
            createToothItemByUrlList(lowerCutoutUrlList, data, false)
            createToothItemByUrlList(upperBiteStopUrlList, data, true)
            createToothItemByUrlList(lowerBiteStopUrlList, data, false)

            addGumUrl(upperGumUrlList, data, true)
            addGumUrl(lowerGumUrlList, data, false)

            let upperToothNumberList = base64ToothNumberDataParsing(upperToothNumberFile, createNumbersData)
            let lowerToothNumberList = base64ToothNumberDataParsing(lowerToothNumberFile, createNumbersData)

            let upperToothVisibleList = base64StageDataParsing(upperToothVisibleFile, createVisibilityData)
            let lowerToothVisibleList = base64StageDataParsing(lowerToothVisibleFile, createVisibilityData)

            let upperToothMatrixList = base64StageDataParsing(upperToothMatrixFile, createTeethMatrices)
            let lowerToothMatrixList = base64StageDataParsing(lowerToothMatrixFile, createTeethMatrices)


            let upperGumMatrixList = base64StageDataParsing(upperGumMatrixFile, createGumMatrix)
            let lowerGumMatrixList = base64StageDataParsing(lowerGumMatrixFile, createGumMatrix)

            let upperIPRList = base64StageDataParsing(upperIPRFile, createIPRData)
            let lowerIPRList = base64StageDataParsing(lowerIPRFile, createIPRData)

            setToothNumber(upperToothNumberList[0], data[0].gum.tooth)
            setToothNumber(lowerToothNumberList[0], data[1].gum.tooth)

            setToothVisible(upperToothVisibleList,data[0].gum.tooth)
            setToothVisible(lowerToothVisibleList, data[1].gum.tooth)

            setToothMatrix(upperToothMatrixList, data[0].gum.tooth)
            setToothMatrix(lowerToothMatrixList, data[1].gum.tooth)

            setToothMovement(upperToothMatrixList, upperGumMatrixList, data[0].gum.tooth)
            setToothMovement(lowerToothMatrixList, lowerGumMatrixList, data[1].gum.tooth)

            data[0].gum.matrixList = upperGumMatrixList
            data[1].gum.matrixList = lowerGumMatrixList

            data[0].gum.ipr = upperIPRList
            data[1].gum.ipr = lowerIPRList

            setPonticUrl(upperPonticUrlList, data[0].gum.pontic)
            setPonticUrl(lowerPonticUrlList, data[1].gum.pontic)

            let upperSuperimpositionMatrixList = getSuperimpositionMatrix(data[0].gum.tooth,upperGumMatrixList, true)
            let lowerSuperimpositionMatrixList = getSuperimpositionMatrix(data[1].gum.tooth, lowerGumMatrixList, true)

            setSuperimpositionMatrixList([upperSuperimpositionMatrixList, lowerSuperimpositionMatrixList])



            setDentalData(data)
        }
        catch(err) {
            setErrText(err)

            setVisible(true)
        }

        setLoadingData(false)


    }

    window.onerror = function(message, url, line, column, error) {
        setLoadingData(false)

        setErrText(`message: ${error.message}; stack: ${error.stack}`)

        setVisible(false)
    }

    useEffect(() => {

        if(fileData && fileData.size !== 0) {
            dataFactory()
        }
        //
    }, [fileData])


    useEffect(() => {
        if(dentalData.length !== 0) {
            getDentalData(dentalData)
        }
    }, [dentalData]);

    return (
        <>
            <DragModal wrapClassName={'uploadModal'} visible={visible} titleText={'Select Mode'} closeModal={()=> setVisible(false)} >
                {errText}
            </DragModal>
        </>
    );
}

const mapStateToProps = state => ({
    stageCount: state.webViewer.stageCount,
    currentClickItemId: state.webViewer.currentClickItemId,
    isMobile: state.webViewer.isMobile,
    currentPatient: state.webViewer.currentPatient,
})

const mapDispatchToProps = dispatch => ({
    setLoadingData: val => dispatch(action.setLoadingData(val)),
    setIsLoading: val => dispatch(action.setIsLoading(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WebDataTool)
