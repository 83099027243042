import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import action from "../../store/action";
import {Popover} from "antd";
import './index.less'
import SvgIcon from "../SvgIcon";

/**
 * @description    Secondary encapsulation based on antd popover components
 * @param props The parameter passed by the attribute within the tag
 * */
function ArchPopover(props) {

    const { placement, right, top, text=[], customDom, width, height, overlayClassName, popoverVisible, needBreak=false } = props

    const clickHander = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }


    const content = (
        <div >
            {
                text.map(item=> {

                    var t = needBreak ? item.split("[b]") : item

                    return(
                        <div className={'popContentContainer'}>
                            <span className={'dot'}>
                                <SvgIcon iconClass={'hint_message_dot'} style={{fontSize: '4px', position: 'absolute',top: 'calc(50% + 2px)'}}></SvgIcon>
                            </span>
                            <span className={'content'} dangerouslySetInnerHTML={{__html: item}}></span>
                        </div>
                    )
                })
            }
        </div>
    )

    return (
        <Popover content={ text.length && popoverVisible ? content : null} placement={placement} overlayClassName={overlayClassName} >
            <div className={'popStyle'} style={{right, top, display: popoverVisible ? 'block' : 'none'}} onClick={clickHander}>
                <img src={require('../../Asset/image/questionMark.png')} width={14} height={14} style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}></img>
            </div>
        </Popover>
    )
}

const mapStateToProps = state => ({
    popoverVisible: state.webViewer.popoverVisible,
})

const mapDispatchToProps = dispatch => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(ArchPopover)
