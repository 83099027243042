import AWS from "aws-sdk";
import {config} from './config/amplify'

const region = config.Auth.region
const userPoolId = config.Auth.userPoolId
const identityPoolId = config.Auth.identityPoolId
const clientId = config.Auth.userPoolWebClientId

AWS.config.region = region

const ModeEnum = Object.freeze({
  Advanced: 'Advanced',
  ReadOnly: 'Read-Only'
})


/**
 * Get Encrypt Params from Path
 * @returns {Params}
 */
export const getEncryptDataFromPath = () => {
  try {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const data = params.get('data')
    let queryData = window.atob(data)
    let parsedObject = JSON.parse(queryData);
    // console.log('>>>>>queryData', parsedObject)
    const { path,userId,token, mode, refreshToken, username, password } = parsedObject
    return {
      ...parsedObject,
      path,
      userId,
      token, 
      mode, 
      refreshToken, 
      username, 
      password
    }
  }
  catch (e) {
    return {mode: 'Read-Only'}
  }
}

/**
 * Get Open Mode by Path Params
 */
export const getMode = () => {
  const { mode } = getEncryptDataFromPath()
  return {
      readOnlyMode: mode === ModeEnum.ReadOnly,
      advancedMode: mode === ModeEnum.Advance
  }
}

export const getAWSS3ServiceClient = async (token) => {
  try {

    // Cognito User Pools
    const providerName = `cognito-idp.${config.Auth.region}.amazonaws.com/${config.Auth.userPoolId}`

    const cognitoidentity = new AWS.CognitoIdentity();

    // Cognito Identity Pool Credentials Params
    const params = {
      IdentityPoolId: config.Auth.identityPoolId,
      Logins: {
        [providerName]: token,
      }
    };

    // get Identity Id
    const identityResult = await cognitoidentity.getId(params).promise();

    const cognitoIdentityCredentials = new AWS.CognitoIdentityCredentials({
      IdentityId: identityResult.IdentityId,
      Logins: params.Logins,
    });

    // Update Credentials
    await cognitoIdentityCredentials.getPromise();
    
    AWS.config.credentials = cognitoIdentityCredentials;
    
  } catch (error) {
    console.error("Failed to retrieve AWS credentials:", error);
    throw error;
  }
}

export const handleRefreshToken = async (refreshToken, clientId) => {
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: config.Auth.region
  });

  try {
    const params = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken
      },
    };

    // request to refresh
    const response = await cognitoidentityserviceprovider.initiateAuth(params).promise();
    const newAccessToken = response.AuthenticationResult.AccessToken;

    return { newAccessToken };

  } catch (error) {
    console.error('Error refreshing tokens:', error);
    throw error;
  }
}