// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.threeAnnotations {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CreateModal/Tooth/index.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".threeAnnotations {\n  display: none !important;\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
