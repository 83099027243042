import React, {useRef, useState, useEffect, useLayoutEffect, useCallback} from "react";
import {Line, Plane, Billboard, Text, Edges, useProgress} from "@react-three/drei";
import { useUpdate } from "@react-three/fiber";
import {connect} from "react-redux";
import * as THREE from 'three'

import {getIPREndPoint} from '../../../lib/threeLib'

import { useUpdateEffect } from '../../../lib/customHooks'

import { getToothMatrix } from '../../../dataInterface/index'
import {useFrame} from "@react-three/fiber";
import {setCurrentPatient} from "../../../store/action/webViewer";

function IPR(props) {

    const {
        isUpper,
        keyframeNumber,
        camera,
        gridCheckboxValue,
        view,
        stage,
        iprValue,
        tooth1,
        tooth2,
        centerPosition,
        toothData,
        opacity,
        currentPatient,
        isMobile
    } = props
    const ref = useRef()
    const planeGroupRef = useRef()

    const [endPos, setEndPos] = useState(new THREE.Vector3())

    // set end vertex
    useEffect(() => {
        const endPoint = getIPREndPoint(getToothMatrix(toothData, stage, tooth1 - 1), getToothMatrix(toothData, stage, tooth2 - 1), centerPosition, isUpper)

        setEndPos(endPoint)

    }, [])


    /**
     * @description     Keep the model size realistic on the viewport when controlling the camera
     * */

    const [boundingBox, setBoundingBox] = useState(new THREE.Box3())
    const children = useRef()
    const [iprScale, setIprScale] = useState(new THREE.Vector3())
    const center = useRef(new THREE.Vector3())



    // Specific implementation of the method.
    // 1. The vertices of the boundingBox are transformed to camera space and then to screen space.
    const resizeIPR = () => {

        // const worldMin = new THREE.Vector4(boundingBox.min.clone().x, boundingBox.min.clone().y, boundingBox.min.clone().z, 1).applyMatrix4(children.current.matrix)
        //
        // const worldMax = new THREE.Vector4(boundingBox.max.clone().x, boundingBox.max.clone().y, boundingBox.max.clone().z, 1).applyMatrix4(children.current.matrix)

        // const topLeft = worldMin.applyMatrix4(camera._camera.matrixWorldInverse).applyMatrix4(camera._camera.projectionMatrix);
        // const topRight = worldMax.applyMatrix4(camera._camera.matrixWorldInverse).applyMatrix4(camera._camera.projectionMatrix);


        // // perspective division
        // topLeft.x *= 1 /topLeft.w
        // topLeft.y *= 1 /topLeft.w
        // topLeft.z *= 1 /topLeft.w
        //
        // topRight.x *= 1 / topRight.w
        // topRight.y *= 1 / topRight.w
        // topRight.z *= 1 / topRight.w

        // const width = Math.abs(topLeft.x - topRight.x);
        // const height = Math.abs(topLeft.y - topRight.y);
        // const depth = Math.abs(topLeft.z - topRight.z)
        //
        //
        // const size = Math.max(width,height,depth) * window.innerHeight;
        //
        //
        // const screenHeight = 60;
        // const scaleFactor =  screenHeight / size;

        // setIprScale(scaleFactor)
        if(gridCheckboxValue) {

            if (planeGroupRef.current.children[0].children.length < 2)
                return
            const size = planeGroupRef.current.children[0].children[1].geometry.boundingBox.getSize(new THREE.Vector3()).length()

            const zoom = camera._camera.zoom;

            const cameraWidth = camera._camera.right - camera._camera.left;


            const scale = cameraWidth / size / zoom / 30;


            setIprScale(new THREE.Vector3(scale, scale, scale))
        }else {

            const distance = camera._camera.position.distanceTo(planeGroupRef.current.position);

            let scale = 1;

            if(isMobile) {
                scale = window.screen.width > window.screen.height ? 8 * distance / window.screen.width / 3 : 8 * distance / window.screen.width / 6

                // if(scale < 2) {
                //     scale = 8 * distance / window.innerWidth / 3
                // }
            }
            else {
                scale = 8 * distance / 1920
            }

            if(scale > 6) {
                return
            }
            setIprScale(new THREE.Vector3(scale,scale,scale))
        }

        // planeGroupRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor)
    }


    /**
     * @description   Note that we must ensure that endPos is set. If endPos is not set, then boundingBox we get is NaN
     *
     * */
    useEffect(()=> {
        if(planeGroupRef.current && (endPos[0] !== 0 && endPos.x !== 0)) {
            window.requestAnimationFrame(()=> {
                children.current = planeGroupRef.current.children[0]

                let box = new THREE.Box3().setFromObject(planeGroupRef.current.children[0])

                // bounding box size


                center.current = box.getCenter(center.current);

                // planeGroupRef.current.position.set(center.current.x, center.current.y, center.current.z)

                setBoundingBox(box)
            })
            resizeIPR()

        }

    }, [endPos, planeGroupRef.current, currentPatient])

    useUpdateEffect(()=> {

        if(camera && endPos.x !== 0) {
            setTimeout(()=> {
                resizeIPR()
            }, 0)
        }

    }, [gridCheckboxValue, camera])

    /**
     * @description    The ipr size needs to be reset after the camera position is updated
     *
     * */

    const cameraSleepHandler = useCallback(() => {
        resizeIPR()
    }, [gridCheckboxValue])

    useUpdateEffect(()=> {

        camera.addEventListener('update', cameraSleepHandler)
    }, [view])


    useLayoutEffect(() => {
        if(children.current) {
            if(!isNaN(boundingBox.max.y) && boundingBox.max.y !== -Infinity) {

                // if(type == 'lower' && index == 0) {
                //     console.log('1', firstTimeSetup.current)
                //     console.log(boundingBox)
                // }

                resizeIPR()

            }


        }
    }, [boundingBox]);


    useFrame(()=> {



        // if(keyframeNumber === 8 && tooth1 === 8) {
        //     console.log(boundingBox)
        // }
    })

    /**
     * @description   This code is an improved version of the code below.
     * */
    const wheelHandler = (e)=> {
        if(e.target.nodeName === "CANVAS" ) {

            requestAnimationFrame(()=> {
                resizeIPR()
            })
        }
    }

    useEffect(()=> {
        // window.removeEventListener("wheel", wheelHandler)


        window.removeEventListener("wheel", wheelHandler)

        window.addEventListener("wheel", wheelHandler, false)

        return () => {
            window.removeEventListener("wheel", wheelHandler)
        }

    }, [boundingBox, gridCheckboxValue, camera])

    /**
     * @description   The following comment code will cause frames to be dropped when moving the camera.
     * Try not to use the control event in cameraControl
     * */
    // useEffect(() => {
    //     // Due to the billboard while moving the camera internal parameters were not immediately available updates, so we need to use Windows.RequestAnimationFrame execution order to delay our method
    //
    //     // console.log(boundingBox.current, '{}{}{}{}')
    //     let onCameraControl;
    //     // console.log(boundingBox.current)
    //     if(boundingBox.max.y !== -Infinity && !isNaN(boundingBox.max.y)) {
    //         // console.log('hh')
    //         onCameraControl = (event) => {
    //             window.requestAnimationFrame(()=> {
    //                 resizeIPR()
    //             })
    //         }
    //         // camera.removeEventListener('control', onCameraControl)
    //         camera.addEventListener('control', onCameraControl);
    //
    //     }
    //
    //
    // }, [boundingBox, gridCheckboxValue, camera]);


    return (
       <group  centerPosition={centerPosition} endPos={endPos} toothType={isUpper} iprValue={iprValue}>
           {/* panel and text */}
           <group scale={iprScale} renderOrder={1} position={[endPos[0], endPos[1], endPos[2]]} visible={(opacity == 1)} ref={planeGroupRef}>
               <Billboard
                   follow={true}
                   lockX={false}
                   lockY={false}
                   lockZ={false} // Lock the rotation on the z axis (default=false)
                   // args = {[44,30]}
                   // renderOrder={1}

               >
                   <Text fontSize={1} color={'black'} renderOrder={3}>
                       {iprValue}
                       <meshStandardMaterial transparent={true} depthTest={true} color="white" />
                   </Text>
                   <Plane args={[2,2]} renderOrder={2}>
                       <meshBasicMaterial transparent={true} depthTest={true} depthWrite={true} color={'white'}  ></meshBasicMaterial>
                       <Edges
                           scale={1.0}
                           threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                           color="black"
                       />
                   </Plane>
               </Billboard>

           </group>

           <Line
               ref={ref}
               points={[centerPosition, endPos]}
               lineWidth={1}
               fog={false}
               // side={THREE.DoubleSide}
               visible={opacity == 1}
               depthTest={false}
               transparent={true}
               renderOrder={1}

           >
           </Line>
       </group>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    view: state.webViewer.view,
    keyframeNumber: state.webViewer.keyframeNumber,
    isLoading: state.webViewer.isLoading,
    currentPatient: state.webViewer.isLoading,
    isMobile: state.webViewer.isMobile,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(IPR)
