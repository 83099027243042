import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import action from "../../../../store/action";

import store from "../../../../store";

import {cloneDeep} from "lodash";


import {getGumData, getToothData, getToothUrl, getToothVisible, getToothMatrix, getToothFileType} from '../../../../dataInterface/index'
import Superimposition from "../../../../Components/CreateModal/Superimposition";


function ToothDataPro(props) {

    const {
        data,
        stage,
        isUpper,
        sliderVal,
        keyframeNumber,
        overlayCheckboxValue,
    } = props


    const [toothData, setToothData] = useState([])

    /**
     * @description   set data
     * */

    useEffect(()=> {
        let gumData = getGumData(data, isUpper)

        let initToothData = cloneDeep(getToothData(gumData))

        setToothData(initToothData)
    }, [data])


    /**
     * @description adjust model opacity
     * */
    const [opacity, setOpacity] = useState(1)

    useEffect(() => {
        if (!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal)) * 0.01)
        } else if (isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal) * 0.01)
        } else {
            setOpacity(1)
        }
    }, [sliderVal]);



    return (
        <group name={'overlayToothGroup'} visible={overlayCheckboxValue}>
            {
                toothData.map((toothItem, initIndex)=> {
                    return(
                        <Superimposition
                            itemId={toothItem.number}
                            key={'init' + toothItem.number}
                            initTooth={true}
                            url={getToothUrl(toothData, initIndex)}
                            initMatrix={getToothMatrix(toothData, stage, initIndex)}
                            fileType={getToothFileType(toothData, initIndex)}
                            toothIndex={initIndex}
                            opacity={opacity}
                        />
                    )
                })
            }
        </group>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    keyframeNumber: state.webViewer.keyframeNumber,
    overlayCheckboxValue: state.webViewer.overlayCheckboxValue,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ToothDataPro)
