import React, {useEffect, useRef, useState, useMemo} from "react";
import {useLoader, useThree} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {connect} from "react-redux";
import action from "../../../store/action";
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import PropTypes from "prop-types";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';


/**
 * @description create gum function. In order to batch creation gum
 * @param props The parameter passed by the attribute within the tag
 * */

const ByteGum = function ByteGum(props) {

    const {url, sliderVal, fileType, isUpper} = props

    const ref = useRef()

    console.log(url)

    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })


    /**
     * @description    index is missing due to normal information, we need to recalculate index
     * */
    const count = useRef(0)

    useEffect(()=> {
        if(ref.current && count.current < 1) {
            ref.current.geometry.deleteAttribute("normal")
            ref.current.geometry = mergeVertices(ref.current.geometry, 0.1);
            ref.current.geometry.computeVertexNormals(true);

            count.current++
        }
    }, [])

    /**
     * @description adjust model opacity
     * */
        // slider control model opacity
    const [opacity, setOpacity] = useState(1)  // model opacity

    const [depthWrite, setDepthWrite] = useState(true);
    useEffect(() => {
        if(!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal) * 5) * 0.01)
            setDepthWrite(false)
        }else if(isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal * 5) * 0.01)
            setDepthWrite(false)
        }else {
            setOpacity(1)
            setDepthWrite(true)
        }

    }, [sliderVal]);

    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            matrixAutoUpdate={false}
            renderOrder={0}
            // visible={keyframeNumber == itemId}
        >
            <meshPhysicalMaterial
                attach="material"
                transparent={true}
                color={'#ff9999'}
                opacity={opacity}
                depthWrite={depthWrite}
                depthTest={true}
                roughness={0.2}
                metalness={0}
                clearcoat={0.1}
                clearcoatRoughness={0.3}
                reflectivity={0.1}
                emissive={'#6a1653'}
                emissiveIntensity={0.2}
                flatShading={false}
            />

        </mesh>

    )
}


const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    keyframeNumber: state.webViewer.keyframeNumber,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val))
})


export default connect(mapStateToProps, mapDispatchToProps)(ByteGum)
