import React, {useRef, useState, useEffect, Suspense, useCallback} from 'react'

import {useThree, extend, useFrame} from '@react-three/fiber'
import {PerspectiveCamera, OrthographicCamera, Grid} from '@react-three/drei'
import ToothDataPro from './components/ToothDataPro/index'
import GumDataPro from './components/GumDataPro/index'
import Superimposition from '../../Components/CreateModal/Superimposition/index'
import Gizmo from '../../Components/CreateModal/Gizmo/index'
import {connect} from "react-redux";
import action from "../../store/action";

// import Grid from '../../Components/Grid/index'
import Grid2 from '../../Components/Grid2/index'
import * as THREE from "three";

import CameraControls from 'camera-controls';
import {cloneDeep} from "lodash";

import IPRDataPro from "./components/IPRDataPro";
import PonticDataPro from "./components/PonticDataPro";
import SuperimpositionPro from "./components/SuperimpositionPro";
import ByteGum from '../../Components/Byte/Mexicali/Gum'
import ByteTooth from '../../Components/Byte/Mexicali/Tooth'
import SmarteeOrByteModel from '../../Components/Byte/SmarteeOrByte/SmarteeOrByteModel'
import {
    byte_getGumFileType,
    byte_getGumUrl,
    byte_getGumUrlList,
    byte_getSmarteeOrByteData,
    byte_getSmarteeOrByteFileType,
    byte_getToothFileType,
    byte_getToothListByStage,
    byte_getToothNumber,
    byte_getToothUrl,
    byte_getSmarteeOrByteUrl
} from '../../ByteDataInterface/index'


CameraControls.install({THREE})
extend({CameraControls})



/**
 * @description main View
 * The 3D model already contains location information, so we don't need change our model position
 * */

function MyScene(props) {

    const {
        gridCheckboxValue,
        view,
        setSliderValue,
        setView,
        setCurrentClickItemId,
        keyframeNumber,
        overlayCheckboxValue,
        setIsLoading,
        setCameraControlRef,
        dentalData,
        isUpdated,
        collisionCheckboxValue,
        currentPatient,
        IPRCheckboxValue,
        setDentalData,
        currentDataTool,
        mexicaliData,
        setMexicaliData,
        upperOrLower,
        smarteeOrByteData,
        isMobile
    } = props


    const mouseRef = useRef()
    // const orbitRef = useRef()
    const perspRef = useRef() // perspective camera ref
    const perspRef2 = useRef()
    const orthRef = useRef() // orthographic camera
    const ref = useRef()  // camera controls ref
    const ref2 = useRef()

    const iprGroup = useRef()

    const dentalRef = useRef()


    //get camera zoom
    const [scale, setScale] = useState(1)

    const [posZ, setPosZ] = useState(300)


    // rotate camera
    const mainCamera = useThree(({camera}) => camera)  // current camera (for example, we can get camera type)

    const camera = mainCamera;

    const {gl, raycaster} = useThree()


    useEffect(() => {
        if(isMobile) {
            setPosZ(800)
            perspRef.current.position.setZ(800)
        }
    }, [isMobile]);



    /**
     * @description  Adjust the camera position with animation
     * */

    const setZoomHandler = useCallback(() => {
        console.log("zoom")
        orthRef.current.zoom = 8

    },[]);


    useEffect(() => {
        // We need to use a timer to delay the execution of this code
        setTimeout(() => {
            if (view == 'left') {
                setTimeout(() => {
                    ref.current?.setLookAt(posZ, 0, 0, 0, 0, 0, true);
                }, 50)

            } else if (view == 'upper') {
                setSliderValue(-100)
                setTimeout(() => {
                    ref.current?.setLookAt(0, -posZ, 0, 0, 0, 0, true);
                }, 50)

            } else if (view == 'front') {
                setTimeout(() => {
                    ref.current?.setLookAt(0, 0, posZ, 0, 0, 0, true);
                }, 50)

            } else if (view == 'right') {
                setTimeout(() => {
                    ref.current?.setLookAt(-posZ, 0, 0, 0, 0, 0, true);
                }, 50)


            } else if (view == 'lower') {
                setSliderValue(100)
                setTimeout(() => {
                    ref.current?.setLookAt(0, posZ, 0, 0, 0, 0, true);
                }, 50)


            } else if (view == 'back') {
                setTimeout(() => {
                    ref.current?.setLookAt(0, 0, -posZ, 0, 0, 0, true);
                }, 50)

            } else if (view == 'home') {
                setSliderValue(0)
                setTimeout(() => {
                    ref.current?.setLookAt(-37, 23, posZ, 0, 0, 0, true)
                })
            }
            console.log(gridCheckboxValue, "gridCheckboxValue", camera)





        }, 0)

        if(gridCheckboxValue) {

            ref.current.zoomTo(8);

            console.log(ref.current)
        }

        setView('')




    }, [view])


    /**
     * @description   Determines if the model is currently clicked
     * */
    const getIntersects = (event, ref) => {
        //Declare the rayCaster and mouse variables
        let mouse = new THREE.Vector2();

        // The normalized device coordinate system in three.js is a coordinate system with the center of the screen as the origin,
        // and the range of x and y axes is -1 to 1. Where the positive direction of the X-axis is the right side of the screen,
        // and the positive direction of the Y-axis is the top of the screen. This coordinate system is the basic coordinate system
        // used by three.js for 3D scene rendering.
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        //By clicking the position of the mouse, the position of the required point of the raycaster is
        // calculated, with the screen as the center point, ranging from -1 to 1
        let currentCamera = gridCheckboxValue ? orthRef.current : perspRef.current

        raycaster.setFromCamera(mouse, currentCamera);

        //Gets an array of objects that intersect the ray.The elements are sorted by distance, with the closest elements coming first.
        //+true, which searches for its descendants, must be added here because the model is made up of many parts and has many descendants.
        let intersects = raycaster.intersectObjects(ref.current);

        console.log(intersects, ref.current, mouse.x, mouse.y, currentCamera, gridCheckboxValue)
        //Returns the selected object

        return intersects;
    }

    const onMouseClickHandler = async (event) => {
        if (event.target.nodeName !== 'CANVAS') {
            return
        }

        let disX = 0
        let disY = 0
        let cx = event.clientX
        let cy = event.clientY

        // Get the array where the raycaster intersects all the models, sorted by distance, with the closest elements coming first
        let intersects = getIntersects(event, dentalRef);

        // Calculates whether the mouse has moved. The panel is not hidden if it is moved
        const onmousemove = (e) => {
            disX = cx - e.clientX
            disY = cy - e.clientY

        }
        document.addEventListener('mousemove', onmousemove)

        const onmouseup = (e) => {
            // It needs to be reset when intersects is empty
            if (intersects.length == 0 && event.target.nodeName == 'CANVAS' && disY == 0 && disX == 0) {
                setCurrentClickItemId(null)
            }

            document.removeEventListener('mouseup', onmouseup)
            document.removeEventListener('mousemove', onmousemove)
        }


        //
        document.addEventListener('mouseup', onmouseup)
    }

    useEffect(() => {
        document.addEventListener('mousedown', onMouseClickHandler);

        return ()=> {
            document.removeEventListener('mousedown', onMouseClickHandler);
        }
    }, []);




    // useEffect(() => {
    //     eventBus.on('setToothMatrix', (val)=> {
    //         setDentalData(cloneDeep(val))
    //     })
    // }, []);





    /**
     * @description   When the browser resize, reset the camera parameters
     * */
    const onWindowResize = () => {
        if (camera.type == "PerspectiveCamera") {
            camera.aspect = window.innerWidth / window.innerHeight;
        } else {
            camera.bottom = window.innerHeight / -2
            camera.top = window.innerHeight / 2
            camera.left = window.innerWidth / -2
            camera.right = window.innerWidth / 2
        }


        gl.setSize(window.innerWidth, window.innerHeight);
        camera.updateProjectionMatrix();
        // gl.setClearColor('#f0f0f0', 1);
    }

    /**
     * @description             The distance from the camera to the midpoint of the model and the distance from the camera to
     *                      the midpoint of the IPR are compared to determine whether to display or hide.
     * */
    const updateAnnotationVisibility = () => {
        // console.log(IPRCheckboxValue, keyframeNumber, IPRCheckboxValue && keyframeNumber > 0)
        // ipr visiblity
        const visibleHandler = (item) => {
            const startVector = new THREE.Vector3(item.centerPosition.x, item.centerPosition.y, item.centerPosition.z);
            const endVector = new THREE.Vector3(item.endPos.x, item.endPos.y, item.endPos.z);

            const vec = new THREE.Vector3().subVectors(endVector, startVector)


            /**
             * @description    The coordinate systems here are all scene coordinate systems
             *
             * */

                // The vec is projected onto the horizontal plane
            const planeNormal = new THREE.Vector3(0, 1, 0);
            const vecProjection = new THREE.Vector3();
            vecProjection.copy(planeNormal).projectOnVector(vec);
            const vecResult = new THREE.Vector3();
            vecResult.subVectors(vec, vecProjection);

            // Project the vector of the camera position to the origin (0,0,0) onto the horizontal plane
            let cameraVec;

            cameraVec = new THREE.Vector3(ref.current._camera.position.x, ref.current._camera.position.y, ref.current._camera.position.z)

            cameraVec.setY(item.toothType ? 5 : -5)



            // Calculate the Angle between two vectors.
            // Since the Angle between the IPR in the middle of the model and the camera is 59.5 degrees, 59.5 needs to be subtracted for zeroing
            const dotProduct = vecResult.dot(cameraVec);
            const magnitudeProduct = vecResult.length() * cameraVec.length();
            const angle = Math.acos(dotProduct / magnitudeProduct);
            const angleInDegrees = THREE.MathUtils.radToDeg(angle);


            if (angleInDegrees - 60 <= 20) {
                item.visible = true;
            } else {
                item.visible = false;
            }
        }

        if (IPRCheckboxValue && dentalRef.current) {

            dentalRef.current.children.forEach((item, i) => {

                item?.children[1]?.children[keyframeNumber]?.children[2]?.children.map(val=> {
                    visibleHandler(val)
                })
            })
        }

        // collisions visiblity
        if (collisionCheckboxValue && dentalRef.current) {

            dentalRef.current.children.forEach((item, i) => {

                item?.children[1]?.children[keyframeNumber]?.children[3]?.children.map(val=> {
                    visibleHandler(val)
                })
            })

        }
    };


    useFrame(()=> {
        updateAnnotationVisibility()
    })

    /**
     * @description    The mouse wheel scrolls to resize the grid
     *
     * */
    let scaleState = 0
    const lastDistance = useRef() // Used to record the moving distance of the camera controller
    const cameraFlag = useRef(true) // A value of true means that the scroll event is triggered when the camera is a perspective camera, and vice versa (false: orthogonal camera)
    useEffect(() => {
        window.addEventListener('resize', onWindowResize)

        // window.addEventListener('wheel', () => {
        //     lastDistance.current = ref.current.distance  // Log the data and compare it in the useEffect below
        //
        //     if(gridCheckboxValue) {
        //         cameraFlag.current = true
        //     }else {
        //         cameraFlag.current = false
        //     }
        //
        //
        //     if (orthRef.current.zoom > 40 && scaleState === 0) {
        //         // setScaleState(1)
        //         setScale(0.2)
        //     } else if (orthRef.current.zoom < 4 && scaleState === 0) {
        //         // setScaleState(-1)
        //         setScale(2)
        //     } else if (orthRef.current.zoom > 0 && orthRef.current.zoom < 40) {
        //         setScale(0.8)
        //         // setScaleState(0)
        //     }
        //
        //
        // })


    }, [])

    useEffect(() => {
        // onWindowResize()
    }, [isUpdated]);



    /**
     * @description   Update the controller parameters and save the controller object to redux
     * */
    useFrame((state, delta) => {
        ref.current.update(delta)

        // console.log(ref.current)
    }, -1)


    /**
     * @description    Set the camera control object, which will be used by the gizmo component to prevent the camera control
     * event from being triggered by dragging
     * */
    useEffect(() => {
        if (ref.current) {
            setCameraControlRef(ref.current)
        }
    }, [ref.current])


    /**
     * @description   When switching cameras, camera parameters are synchronized.
     *                I want to explain why I used the inverse scale function and why I used the number 800.
     *                1. The range of zoom values is (0, +∞]. The range of distance values is (0, +∞].
     *                In camera-control, the larger the zoom, the closer to the model. On the contrary, the smaller
     *                the distance, the closer to the model. So I chose the inverse proportionality function.
     *
     *                2. Initially, I adjusted the parameters of both cameras so that the model looked about the same size
     *                when switching cameras (I set zoom to 8 for OrthographicCamera and position to [0,0,100] for
     *                PerspectiveCamera).
     *
     *                3. ToothDataPro be clear, we only need to look roughly the same when switching cameras. The correlation
     *                between zoom and distance is not very strong (Because the larger the zoom value of the OrthographicCamera
     *                can only approach the object surface, while the larger the distance of the PerspectiveCamera is close to the
     *                central coordinate point of the object).
     * */
    const init = useRef(true)
    const flag = useRef(false)
    useEffect(() => {
        // Parameters are not synchronized on initial loading, but only when switched

        if (!init.current) {
            if (gridCheckboxValue) {
                let pos = cloneDeep(perspRef.current.position)

                // 1. When we drag the camera and switch cameras, we need to duplicate the position of the PerspectiveCamera
                // in order to keep the view consistent

                // 2. The 8x magnification of position is to ensure that the grid will display properly (in fact,
                // no matter how much magnification is done, it doesn't matter to the orthogonal camera).

                if(ref.current.distance <= 100 && cameraFlag) {
                    flag.current = true
                    pos.x = pos.x * 8
                    pos.y = pos.y * 8
                    pos.z = pos.z * 8
                }
                orthRef.current.position.copy(pos)

                //The zoom value of the orthogonal camera is adjusted according to the distance parameter of the perspective camera
                // function expression：f(x) = 800 / x                           (f(x) is zoom and x is distance)
                // Graph of function: src/Asset/GraphOfFunction/zoom2distance.png
                orthRef.current.zoom = 6000 / ref.current.distance

                orthRef.current.updateProjectionMatrix()
            } else {

                // position is a good record of the camera position, so we need to convert zoom to distance (since PerspectiveCamera move the camera in dolly fashion
                // and OrthographicCamera move the camera in zoom fashion)
                // function expression：f(x) = 800 / x                    (f(x) is distance and x is zoom)
                // Graph of function: src/Asset/GraphOfFunction/zoom2distance.png

                let pos = cloneDeep(orthRef.current.position)

                setTimeout(()=> {
                    ref.current.dollyTo(6000 / orthRef.current.zoom)
                },0)

                perspRef.current.position.copy(pos)

                perspRef.current.updateProjectionMatrix()
            }

        } else {
            init.current = false
        }


        if (gridCheckboxValue) {
            ref.current.camera = orthRef.current
        } else {
            ref.current.camera = perspRef.current
        }

        ref.current.dispose()


    }, [gridCheckboxValue]);


    /**
     * @description   adding light. The light source is created here in order to add the light source in the camera rather than in the scene.
     *                  Let the light source move with the camera
     * */

    const createDirLight = () => {
        const dirLight = new THREE.DirectionalLight(0xffffff, 0.2);
        dirLight.castShadow = false;
        dirLight.position.set(0, -10, 0);
        return dirLight
    }

    const createPointLight = () => {
        const pointLight = new THREE.PointLight(0xffffff, 0.1);
        pointLight.castShadow = false;
        return pointLight;
    };

    const createSpotLight = () => {
        const spotlight = new THREE.SpotLight(0xffffff, 0.05);
        spotlight.castShadow = false;
        spotlight.shadow.bias = -0.0001;
        spotlight.shadow.mapSize.width = 1024 * 4;
        spotlight.shadow.mapSize.height = 1024 * 4;
        return spotlight;
    };

    useEffect(() => {
        // adding light in orth camera
        const dirLight = createDirLight()
        dirLight.visible = true

        const spotLight = createSpotLight()
        spotLight.visible = true

        orthRef.current.add(dirLight)
        orthRef.current.add(spotLight)

        // adding light in persp camera
        perspRef.current.add(createDirLight())

        const pointLight = createPointLight()
        pointLight.position.copy(perspRef.current.position);
        perspRef.current.add(pointLight)

    }, []);

    // Turn on the corresponding light source according to the current camera
    useEffect(() => {

        orthRef.current.children.map(item=> {
            item.visible = gridCheckboxValue
        })

        perspRef.current.children.map(item=> {
            item.visible = !gridCheckboxValue
        })
    }, [gridCheckboxValue]);



    const [iprNeedUpdate, setIprNeedUpdate] = useState(true)

    useEffect(() => {
        setIprNeedUpdate(false)
        setTimeout(()=> {
            setIprNeedUpdate(true)
        }, 300)
    }, [gridCheckboxValue]);


    useEffect(() => {
        if(ref.current) {
            ref.current.mouseButtons.right = 1
            ref.current.mouseButtons.middle = 2
        }

    }, [ref.current]);


    const handleKeyDown = (event) => {


        if (event.keyCode === 17) { // ctrl
            ref.current.mouseButtons.left = 2
        }
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 17) {
            ref.current.mouseButtons.left = 1
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);





    return (<>
        {/* minZoom: OrthographicCamera config, Used to limit the camera movement range. maxDistance: PerspectiveCamera config, Used to limit the camera movement range. */}

        <cameraControls args={[camera, gl.domElement]} ref={ref} minZoom={2} maxZoom={2000} maxDistance={1200}  dampingFactor={0.1}  />
        <PerspectiveCamera
            ref={perspRef}
            makeDefault={!gridCheckboxValue}
            position={[0, 0, posZ]}
            aspect={window.innerWidth / window.innerHeight}
            fov={10}
            far={10000}
            near={1}
            onUpdate={(c) => c.updateProjectionMatrix()}
        />
        <OrthographicCamera
            ref={orthRef}
            makeDefault={gridCheckboxValue}
            zoom={7}
            // position={[0,0,100]}
            far={10000}
            near={1}
            // left={window.innerWidth / -2}
            // right={window.innerWidth / 2}
            // top={window.innerHeight / 2}
            // bottom={window.innerHeight / -2}
        />

        <Grid2 cameraControl={ref.current} />
        {/* light */}
        {/*<ambientLight color={'#000'}/>*/}
        {/*<hemisphereLight skyColor={'#ffffff'} groundColor={'#ffffff'} intensity={0.2}  />*/}
        <directionalLight color={'#fff'} position={[0,60,0]}   intensity={0.1}  ></directionalLight>
        <directionalLight color={'#fff'} position={[0,-60,0]} intensity={0.1}></directionalLight>
        <directionalLight color={'#fff'} position={[0, 0, 90]}  intensity={0.2}></directionalLight>
        <directionalLight color={'#fff'} position={[0, 0, -90]}  intensity={0.2}></directionalLight>
        <directionalLight color={'#fff'} position={[90, 0, 0]}  intensity={0.2}></directionalLight>
        <directionalLight color={'#fff'} position={[-90, 0, 0]}  intensity={0.2}></directionalLight>
        {
            currentDataTool.key !== 1 && isUpdated ? (
                <>
                    <group name={'dental'} ref={dentalRef}>
                        {
                            dentalData.length !== 0 && dentalData.map(item=> {
                                return(
                                    <group name={item.isUpper ? 'upperDental' : 'lowerDental'}>
                                        <SuperimpositionPro data={dentalData} stage={0} isUpper={item.isUpper} />
                                        <group name={'stage'}>
                                            {
                                                item.gum.urlList.map((gumUrl, i)=> {
                                                    return(
                                                        <group stage={i} visible={keyframeNumber === i}>
                                                            <GumDataPro data={dentalData} stage={i} isUpper={item.isUpper} />
                                                            <ToothDataPro data={dentalData} stage={i} isUpper={item.isUpper} />
                                                            <IPRDataPro  data={dentalData} stage={i} isUpper={item.isUpper} cameraControl={ref.current}></IPRDataPro>
                                                            <PonticDataPro data={dentalData} stage={i} isUpper={item.isUpper} />
                                                        </group>
                                                    )
                                                })
                                            }
                                        </group>

                                    </group>
                                )
                            })
                        }

                    </group>
                    {/*<Gizmo modelRef={dentalRef} data={dentalData} setDentalData={setDentalData} />*/}
                </>
            ) : (
                currentPatient.mode === 0 ? (
                    <Suspense>
                        <group>
                            {/*<ByteGum url={mexicaliData[0].gum.urlList[0].url} fileType={mexicaliData[0].gum.urlList[0].fileType} />*/}
                            {
                                mexicaliData.map((item, index)=> {
                                    return(
                                        <group visble={index === 0 ? upperOrLower : !upperOrLower}>
                                            {
                                                byte_getGumUrlList(item).map((val, gumIndex)=> {
                                                    return(
                                                        <group visible={keyframeNumber === gumIndex && (index === 0 ? upperOrLower : !upperOrLower)} rotation={index === 1 ? [Math.PI / 2, 0, Math.PI] : [-Math.PI / 2,0,0]}>
                                                            {
                                                                val.url !== "" ? <ByteGum isUpper={item.isUpper} key={byte_getGumUrl(val)} url={byte_getGumUrl(val)} fileType={byte_getGumFileType(val)} /> : <></>
                                                            }
                                                            <group>
                                                                {
                                                                    byte_getToothListByStage(item, gumIndex).map((toothObj, toothIndex)=> {

                                                                        return(
                                                                            <ByteTooth
                                                                                url={byte_getToothUrl(toothObj)}
                                                                                key={byte_getToothUrl(toothObj)}
                                                                                fileType={byte_getToothFileType(toothObj)}
                                                                                toothIndex={toothIndex}
                                                                                stage={gumIndex}
                                                                                toothModelData={mexicaliData}
                                                                                setMexicaliData={setMexicaliData}
                                                                                number={byte_getToothNumber(toothObj)}
                                                                                isUpper={item.isUpper}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </group>
                                                        </group>
                                                    )
                                                })
                                            }
                                        </group>
                                    )
                                })

                            }
                        </group>
                    </Suspense>
                ) : (
                    <group>
                        {
                            smarteeOrByteData.map((item, index)=> {

                                return(
                                    <group name={item.isUpper ? 'upper' : 'lower'} visible={index === 0 ? upperOrLower : !upperOrLower}>
                                        {
                                            byte_getSmarteeOrByteData(item).map((model, mIndex)=> {

                                                return(
                                                    <SmarteeOrByteModel
                                                        isUpper={item.isUpper}
                                                        url={byte_getSmarteeOrByteUrl(model)}
                                                        fileType={byte_getSmarteeOrByteFileType(model)}
                                                        visible={mIndex === keyframeNumber}
                                                    />
                                                )
                                            })
                                        }
                                    </group>
                                )
                            })
                        }
                    </group>
                )
            )
        }

        {/*<Grid scale={scale}/>*/}


        {/*<gridHelper args={[1, 100, `white`, `gray`]} />*/}

        {/*<PerspectiveCamera*/}
        {/*    ref={perspRef2}*/}
        {/*    makeDefault={!gridCheckboxValue}*/}
        {/*    position={[0, 0, 100]}*/}
        {/*    aspect={window.innerWidth / window.innerHeight}*/}
        {/*    fov={45}*/}
        {/*    far={1000}*/}
        {/*    near={1}*/}
        {/*    onUpdate={(c) => c.updateProjectionMatrix()}*/}
        {/*/>*/}
    </>);
}

const mapStateToProps = state => ({
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    view: state.webViewer.view,
    keyframeNumber: state.webViewer.keyframeNumber,
    overlayCheckboxValue: state.webViewer.overlayCheckboxValue,
    collisionCheckboxValue: state.webViewer.collisionCheckboxValue,
    IPRCheckboxValue: state.webViewer.IPRCheckboxValue,
    toothData: state.webViewer.toothData,
    gumData: state.webViewer.gumData,
    iprData: state.webViewer.iprData,
    currentPatient: state.webViewer.currentPatient,
    ponticData: state.webViewer.ponticData,
    ponticCheckboxValue: state.webViewer.ponticCheckboxValue,
    stageCount: state.webViewer.stageCount,
    dentalModels: state.webViewer.dentalModels,
    isLoading: state.webViewer.isLoading,
    currentDataTool: state.dataTool.currentDataTool,
    upperOrLower: state.dataTool.upperOrLower,
    isMobile: state.webViewer.isMobile,
})


const mapDispatchToProps = dispatch => ({
    setSliderValue: val => dispatch(action.setSliderValue(val)),
    setView: val => dispatch(action.setView(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
    setToothData: val => dispatch(action.setToothData(val)),
    setIsLoading: val => dispatch(action.setIsLoading(val)),
    setPanelData: val => dispatch(action.setPanelData(val)),
    setIprData: val => dispatch(action.setIprData(val)),
    setAttachmentData: val => dispatch(action.setAttachmentData(val)),
    setGumData: val => dispatch(action.setGumData(val)),
    setCameraControlRef: val => dispatch(action.setCameraControlRef(val)),
    setCutoutsData: val => dispatch(action.setCutoutsData(val)),
    setHooksData: val => dispatch(action.setHooksData(val)),
    setPonticData: val => dispatch(action.setPonticData(val)),
    setLoadingData: val => dispatch(action.setLoadingData(val)),
    setBiteStopData: val => dispatch(action.setBiteStopData(val)),
    setReduxMovementData: val => dispatch(action.setReduxMovementData(val)),
    setDentalModels: val => dispatch(action.setDentalModels(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyScene)


