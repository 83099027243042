import react, {useRef, useState, useEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action/index'
import ArchPopover from "../../../../Components/ArchPopover";
import Dot from "../../../../Asset/image/mobile_navigate_dot.png"

function View(props) {
    const {setView, isMobile} = props

    const [visible, setVisible] = useState(true)

    const text = ['Click on different buttons to change the view.']

    const clickBtn = () => {
        setVisible(!visible)
    }

    return (
        <>
            {
                isMobile ? (
                    <div className={'mobileWrap'}>
                        <div className={'navigator'} onClick={clickBtn}>
                            <img src={Dot} width={8} height={8}></img>
                            <span>Navigator</span>
                        </div>
                        <div className={'direct'} style={{display: visible ? 'flex' : 'none'}} onClick={()=> setView('upper')}>
                            <span>Upper</span>
                        </div>
                        <div className={'direct'} style={{display: visible ? 'flex' : 'none'}} onClick={()=> setView('lower')}>
                            <span>Lower</span>
                        </div>
                        <div className={'direct'} style={{display: visible ? 'flex' : 'none'}} onClick={()=> setView('right')}>
                            <span>Right</span>
                        </div>
                        <div className={'direct'} style={{display: visible ? 'flex' : 'none'}} onClick={()=> setView('left')}>
                            <span>Left</span>
                        </div>
                        <div className={'direct'} style={{display: visible ? 'flex' : 'none'}}  onClick={()=> setView('front')}>
                            <span>Front</span>
                        </div>
                        <div className={'direct'} style={{display: visible ? 'flex' : 'none'}} onClick={()=> setView('back')}>
                            <span>Back</span>
                        </div>
                    </div>
                ) : (
                    <div className={'viewWrapper'}>
                        <svg className="upperBtn" onClick={()=> setView('upper')}>
                            <polygon points="11 1 71 1 81 25 1 25" className="polygon"></polygon>
                            <text position="relative" x="42" y="18" className="text">Upper</text>
                        </svg>
                        <svg className="frontBtn" onClick={()=> setView('front')}>
                            <polygon points="1 1 81 1 81 26 1 26" className="polygon"></polygon>
                            <text position="relative" x="42" y="19" className="text">Front</text>
                        </svg>
                        <svg className="homeBtn" onClick={()=> setView('home')}>
                            <polygon points="1 1 81 1 81 26 1 26" className="polygon"></polygon>
                            <text position="relative" x="42" y="19" className="text">Home</text>
                        </svg>
                        <svg className="backBtn" onClick={()=> setView('back')}>
                            <polygon points="1 1 81 1 81 26 1 26" className="polygon"></polygon>
                            <text position="relative" x="42" y="19" className="text">Back</text>
                        </svg>
                        <svg className="lowerBtn" onClick={()=> setView('lower')}>
                            <polygon points="1 1 81 1 71 25 11 25" className="polygon"></polygon>
                            <text position="relative" x="42" y="18" className="text">Lower</text>
                        </svg>
                        <svg className="rightBtn" onClick={()=> setView('right')}>
                            <polygon points="1 11 25 1 25 81 1 71" className="polygon"></polygon>
                            <text className="rotateRight text" position="relative" x="-40" y="18" textAnchor="middle" fill="black"
                                  fontSize="16">Right
                            </text>
                        </svg>
                        <svg className="leftBtn" onClick={()=> setView('left')}>
                            <polygon points="1 1 25 11 25 71 1 81" className="polygon"></polygon>
                            <text className="rotateLeft text" position="relative" x="40" y="-8" textAnchor="middle" fill="black"
                                  fontSize="16">Left
                            </text>
                        </svg>
                        <ArchPopover right={'2px'} top={'0px'} width={'300px'} height={'23px'} placement={'topRight'} overlayClassName={'viewPopover'} text={text} />
                    </div>
                )
            }
        </>

    );
}

const mapStateToProps = state => ({
    isMobile: state.webViewer.isMobile,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
