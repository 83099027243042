import request from '../request'

/**
 * @description  Get patient data
 * @param {*} data
 * @returns
 */
export function uploadPatientFileApi(data){
    return request({
        url: 'upload/webviewer',
        method: 'post',
        data,
        timeout: 60 * 60 * 1000,
    })
}

/**
 * @description  Get modal
 * @param {*} data
 * @returns
 */
export function getModalApi(data){

    return request({
        url: 'getModel',
        method: 'post',
        responseType: 'blob',
        data,
    })
}

/**
 * @description get patients list
 * @param {*} data
 * */
export function getPatientsListApi(data){
    return request({
        url: 'getPatientsList',
        method: 'get',
        data
    })
}

/**
 * @description  get stage info
 * @param {*} data
 * */
export function getStageInfoApi(data){
    return request({
        url: 'getStageInfo',
        method: 'post',
        data
    })
}

/**
 * @description  get photos
 * @param {*} data
 * */
export function getPhotosApi(data){
    return request({
        url: 'getPhotos',
        method: 'get',
        data
    })
}

/**
 * @description  get setup version list
 * @param {*} data
 * */
export function getSetupVersionListApi(data){
    return request({
        url: 'getSetupVersionList',
        method: 'get',
        data
    })
}

/**
 * @description  submit select version
 * @param {*} data
 * */
export function submitSelectVersionApi(data){
    return request({
        url: 'submitSelectVersion',
        method: 'post',
        data
    })
}

/**
 * @description  submit user text
 * @param {*} data
 * */
export function submitUserTextApi(data){
    return request({
        url: 'submitUserText',
        method: 'post',
        data
    })
}

/**
 * @description  accept setup
 * @param {*} data
 * */
export function acceptSetupApi(data){
    return request({
        url: 'acceptSetup',
        method: 'post',
        data
    })
}


