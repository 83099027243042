export const DentalNotationType = Object.freeze({
  ISO: 0,
  Universal: 1,
  Palmer: 2,
  Legacy: 3
})


export const ISOMap = Object.freeze([
  18,
  17,
  16,
  15,
  14,
  13,
  12,
  11,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  48,
  47,
  46,
  45,
  44,
  43,
  42,
  41,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38
])

export const UniversalMap = Object.freeze([
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "32",
  "31",
  "30",
  "29",
  "28",
  "27",
  "26",
  "25",
  "24",
  "23",
  "22",
  "21",
  "20",
  "19",
  "18",
  "17"
])

export const PalmerMap = Object.freeze([
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "1",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "1",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8"
])

export const LegacyMap = Object.freeze([
    "UR 8",
    "UR 7",
    "UR 6",
    "UR 5",
    "UR 4",
    "UR 3",
    "UR 2",
    "UR 1",
    "UL 1",
    "UL 2",
    "UL 3",
    "UL 4",
    "UL 5",
    "UL 6",
    "UL 7",
    "UL 8",
    "LR 8",
    "LR 7",
    "LR 6",
    "LR 5",
    "LR 4",
    "LR 3",
    "LR 2",
    "LR 1",
    "LL 1",
    "LL 2",
    "LL 3",
    "LL 4",
    "LL 5",
    "LL 6",
    "LL 7",
    "LL 8"
])

export const DentalNotationHeadRowData = {
  [DentalNotationType.ISO]: ISOMap,
  [DentalNotationType.Universal]: UniversalMap,
  [DentalNotationType.Palmer]: PalmerMap,
  [DentalNotationType.Legacy]: LegacyMap
}