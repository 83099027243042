import React, {useRef, useState, useEffect, useCallback} from "react";
import {useLoader, useThree} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {Line, PivotControls, Center} from "@react-three/drei";
import {connect} from "react-redux";
import action from "../../../store/action";

import * as THREE from 'three'
import {mergeVertices} from "three/addons/utils/BufferGeometryUtils";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";


function Pontic(props) {
    const {
        itemId,
        url,
        setCurrentClickItemId,
        setCurrentClickPontic,
        currentClickPontic,
        initTooth,
        dragging,
        keyframeNumber,
        stageNum,
        opacity,
        fileType
    } = props

    const ref = useRef()

    //load model
    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })


    // Mouse click the model
    const clickHandler = useCallback((e) => {
        if (dragging || initTooth) {
            return
        }

        if(e.eventObject.material.opacity === 1 && e.eventObject.visible) {
            e.stopPropagation()
            // console.log(itemId)
            setCurrentClickItemId(null)
            setCurrentClickPontic(itemId)

        }
    }, [])


    useEffect(()=> {
        setTimeout(()=> {
            if(ref.current) {

                ref.current.geometry.deleteAttribute("normal")
                ref.current.geometry = mergeVertices(ref.current.geometry, 0.1);
                ref.current.geometry.computeVertexNormals(true);

            }
        }, 0)
    }, [])


    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            visible={stageNum === keyframeNumber}
            matrixAutoUpdate={false}
            onClick={(event) => clickHandler(event)}
            name={'pontic' + itemId}
            renderOrder={2}
            >
            <meshStandardMaterial
                color={currentClickPontic == itemId ? 'orange' : '#C0C0C0'}
                opacity={opacity}
                transparent={true}
                depthWrite={opacity > 0.5 ? true : false}
                roughness={0.4}
                metalness={0}
                reflectivity={0.3}
                emissive={'#fff'}
                emissiveIntensity={0.05}
            />
        </mesh>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    currentClickPontic: state.webViewer.currentClickPontic,
    dragging: state.webViewer.dragging,
    keyframeNumber: state.webViewer.keyframeNumber,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val)),
    setCurrentClickPontic: val => dispatch(action.setCurrentClickPontic(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
    setDragging: val => dispatch(action.setDragging(val)),
    setToothData: val => dispatch(action.setToothData(val)),
    setPivotData: val => dispatch(action.setPivotData(val)),
    setPanelData: val => dispatch(action.setPanelData(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pontic)
