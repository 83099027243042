// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archSliderWrapper {
  position: relative;
  z-index: 13;
}
.archSliderWrapper .rail {
  position: relative;
  width: 148px;
  height: 9px;
  border-radius: 9px;
  background-color: white;
  cursor: pointer;
}
.archSliderWrapper .rail::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 2px;
  height: 9px;
  background-color: #6C6D72;
}
.archSliderWrapper .controller {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 14;
  cursor: pointer;
}
.archSliderWrapper .controller span {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
}
.archSliderWrapper .controller .bigCircle {
  width: 16px;
  height: 16px;
  background-color: white;
}
.archSliderWrapper .controller .smallCircle {
  width: 14px;
  height: 14px;
  background-color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Slider/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;AAHA;EAKI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;AACN;AAtBA;EA0BI,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AADJ;AAjCA;EAqCM,kBAAA;EACA,qBAAA;EAEA,kBAAA;AAFN;AAtCA;EA4CM,WAAA;EACA,YAAA;EACA,uBAAA;AAHN;AA3CA;EAkDM,WAAA;EACA,YAAA;EACA,sBAAA;AAJN","sourcesContent":[".archSliderWrapper {\n  position: relative;\n  z-index: 13;\n\n  .rail {\n    position: relative;\n    width: 148px;\n    height: 9px;\n    border-radius: 9px;\n    background-color: white;\n    cursor: pointer;\n\n    &::after {\n      content: '';\n      position: absolute;\n      top: 0px;\n      left: 50%;\n      transform: translateX(-50%);\n      display: block;\n      width: 2px;\n      height: 9px;\n      background-color: #6C6D72;\n    }\n  }\n\n  .controller {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    left: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 14;\n    cursor: pointer;\n\n    span {\n      position: absolute;\n      display: inline-block;\n\n      border-radius: 50%;\n    }\n\n    .bigCircle {\n      width: 16px;\n      height: 16px;\n      background-color: white;\n    }\n\n    .smallCircle {\n      width: 14px;\n      height: 14px;\n      background-color: gray;\n    }\n  }\n\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
