import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import action from "../../../../store/action";

import store from "../../../../store";

import {cloneDeep} from "lodash";
import Tooth from "../../../../Components/CreateModal/Tooth";
import Attachment from "../../../../Components/CreateModal/Attachment";
import Hooks from "../../../../Components/CreateModal/Hooks";
import Cutouts from "../../../../Components/CreateModal/Cutouts";
import BiteStop from "../../../../Components/CreateModal/BiteStop";

import {
    getGumData,
    getToothData,
    getToothUrl,
    getToothVisible,
    getToothMatrix,
    getItemsModelUrl,
    getToothFileType
} from '../../../../dataInterface/index'


function ToothDataPro(props) {

    const {
        data,
        stage,
        isUpper,
        sliderVal,
        keyframeNumber
    } = props


    const [toothData, setToothData] = useState([])

    /**
     * @description   set data
     * */

    useEffect(()=> {
        let gumData = getGumData(data, isUpper)

        let initToothData = cloneDeep(getToothData(gumData))

        setToothData(initToothData)
    }, [data])


    /**
     * @description adjust model opacity
     * */
    const [opacity, setOpacity] = useState(1)

    useEffect(() => {
        if (!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal)) * 0.01)
        } else if (isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal) * 0.01)
        } else {
            setOpacity(1)
        }
    }, [sliderVal]);



    return (
        <group name={'toothAndItem'}>
            {
                toothData.map((toothItem, toothIndex)=> {
                    return(
                        <group matrix={getToothMatrix(toothData, stage, toothIndex)}  matrixAutoUpdate={false} name={toothItem.number} visible={(getToothVisible(toothData, stage, toothIndex) === 1 && stage === keyframeNumber) ? true : false}>
                            <Tooth
                                itemId={toothItem.number}
                                toothIndex={toothIndex}
                                stage={stage}
                                key={toothItem.number}
                                isUpper={isUpper}
                                url={getToothUrl(toothData, toothIndex)}
                                opacity={opacity}
                                scale={(getToothVisible(toothData, stage, toothIndex) === 1 && stage === keyframeNumber) ? 1 : 0}
                                fileType={getToothFileType(toothData, toothIndex)}
                            />
                            <group name={'item'}>
                                {/*initTooth is used because the tooth in overlay mode does not need to have these components */}
                                {
                                    toothItem.children.map(val => {
                                        return(
                                            <>
                                                {
                                                    val.url!=='' && val.type==='attachment' ? <Attachment url={val.url} fileType={val.fileType} toothNumber={toothItem.number} isUpper={isUpper} type={val.type} opacity={opacity} /> : <></>
                                                }
                                                {
                                                    val.url!=='' && val.type==='hooks' ? <Hooks url={val.url} fileType={val.fileType} toothNumber={toothItem.number} isUpper={isUpper} type={val.type} opacity={opacity} /> : <></>
                                                }
                                                {
                                                    val.url!=='' && val.type==='cutouts' ? <Cutouts url={val.url} fileType={val.fileType} toothNumber={toothItem.number} isUpper={isUpper} type={val.type} opacity={opacity} /> : <></>
                                                }
                                                {
                                                    val.url!=='' && val.type==='biteStop' ? <BiteStop url={val.url} fileType={val.fileType} toothNumber={toothItem.number} isUpper={isUpper} type={val.type} opacity={opacity} /> : <></>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </group>
                        </group>
                    )
                })
            }
        </group>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    keyframeNumber: state.webViewer.keyframeNumber,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ToothDataPro)
