import { Auth } from "aws-amplify"
import { getEncryptDataFromPath } from '../utils'

export const config = {
  API: {
    endpoints: [
      {
        name: "WebAppAPI",
        endpoint:
          "https://d4gi5sijca.execute-api.us-east-2.amazonaws.com/prod/",
      },
      {
        name: "archform-dashboard-back",
        endpoint: "https://eybxaoatm0.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          // use username & password
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
          // use token
          // const { token } = getEncryptDataFromPath()
          // return { Authorization: `Bearer ${token}}`}
        },
      },
      // {
      //   name: "Test",
      //   endpoint:
      //     "https://fgsm94y2k0.execute-api.us-west-1.amazonaws.com/prod",
      // },
    ],
  },
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_bNM3Ho5WZ",
    identityPoolId: "us-east-2:4174969e-0d9e-4380-8d38-99e4f1f97cf7",
    userPoolWebClientId: "663500b1d36judotkr083nilhn",
  },
  Storage: {
    bucket: "archform-patient-data-v2",
    region: "us-east-2",
    identityPoolId: "us-east-2:4174969e-0d9e-4380-8d38-99e4f1f97cf7",
  },
  // Cache: {
  //   itemMaxSize: 4999999,
  //   capacityInBytes: 5000000,
  // },
};

export const readonly_config = {
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_Nq1pmoWe5",
    identityPoolId: "us-east-2:8ff1f9c9-22dc-4694-943c-85098182e4e7",
    userPoolWebClientId: "3pq11lgev552o9gr0359njdq4f",
  },
  Storage: {
    bucket: "archform-patient-data-v2",
    region: "us-east-2",
    identityPoolId: "us-east-2:4174969e-0d9e-4380-8d38-99e4f1f97cf7",
  },
};
