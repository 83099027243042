import React, {useRef, useState, useEffect, useMemo} from "react";

import {connect} from "react-redux";

import {useFrame} from "@react-three/fiber"
import { Segments, Segment } from "@react-three/drei"

import * as THREE from 'three'

import {getIPREndPoint} from '../../../lib/threeLib'

import {getToothMatrix} from '../../../dataInterface/index'

import vertexShader from "../../../shader/Line/vertexShader"
import fragmentShader from "../../../shader/Line/fragmentShader"


/**
 * @description create tooth function. In order to batch creation tooth
 * @param props The parameter passed by the attribute within the tag
 * */
function CollisionArea(props) {

    const {
        stage,
        isUpper,
        tooth1,
        tooth2,
        centerPosition,
        vertices,
        toothData,
        opacity
    } = props

    const ref = useRef()
    const [endPos, setEndPos] = useState(new THREE.Vector3())


    const material = new THREE.ShaderMaterial({
        uniforms: {
            lineWidth: { value: 1.0 }, // line width
        },
        vertexShader,
        fragmentShader,
    });


    const [indexArr, setIndexArr] = useState([])
    useEffect(() => {
        if (vertices) {
            let arr = []
            for (let i = 0; i < vertices.length; i++) {
                arr.push(i)
            }
            arr = new Float32Array(arr)
            setIndexArr(arr)
        }

    }, [vertices]);

    useEffect(()=> {
        const endPoint = getIPREndPoint(getToothMatrix(toothData, stage, tooth1 - 1), getToothMatrix(toothData, stage, tooth2 - 1), centerPosition, isUpper)

        setEndPos(endPoint)
    }, [])

    const materialRef = useRef();

    // useFrame(() => {
    //     materialRef.current.uniforms.lineWidth.value = 2;
    // });

    const [points, setPoints] = useState([]);
    useEffect(()=> {
        if(vertices) {
            let arr = []

            for (let i=0; i<vertices.length; i = i+6) {
                arr.push([[vertices[i], vertices[i+1], vertices[i+2]], [vertices[i+3], vertices[i+4], vertices[i+5]]])
            }

            // console.log(arr, 'arrrrrrrr123')
            setPoints(arr)
        }
    }, [vertices])


    return (
        <>
            <lineSegments
                {...props}
                ref={ref}
                renderOrder={1}
                endPos={endPos}
                centerPosition={centerPosition}
                toothType={isUpper}
            >
                <bufferGeometry ref={ref}>
                    <bufferAttribute
                        attach="attributes-position" // <- new attributes attach
                        array={vertices}
                        count={vertices.length / 3} //
                        itemSize={3}
                        needsUpdate={true}
                    />
                    <bufferAttribute
                        attach="attributes-index"
                        array={indexArr}
                        count={indexArr.length} //
                    />
                </bufferGeometry>
                <shaderMaterial
                    ref={materialRef}
                    uniforms={{ lineWidth: { value: 2 } }}
                    vertexShader={vertexShader}
                    fragmentShader={fragmentShader}
                />
                <lineBasicMaterial
                    color={'#ff6666'}
                    depthWrite={false}
                    linewidth={2.0}
                    depthTest={false}
                    transparent={true}
                    visible={opacity == 1}
                    // onBeforeCompile={customShader}
                />
            </lineSegments>
            {/*<Segments renderOrder={0} lineWidth={1} depthWrite={false} depthTest={false}  transparent={true} >*/}

            {/*    {*/}
            {/*        points.map(item=> {*/}
            {/*            return(*/}
            {/*                <Segment start={item[0]} end={item[1]} color="red" />*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</Segments>*/}
        </>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CollisionArea)
