import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import action from "../../store/action";
import {Popover} from "antd";
import './index.less'
import SvgIcon from "../SvgIcon";
import DragModal from "../DragModal";

/**
 * @description    Secondary encapsulation based on antd popover components
 * @param props The parameter passed by the attribute within the tag
 * */
function HintModal(props) {

    const {
        visible=false,
        wrapClassName='confirmModal',
        width='301px',
        titleText='Hint',
        okButtonHandler=()=>{},
        cancelHandler=()=>{},
        okButtonText='Confirm',
        cancelButtonText='Cancel',
        content='Are you sure you want to leave?'
    } = props

    const okHandler = () => {
        okButtonHandler()
    }

    const closeModalHandler = () => {
        cancelHandler()
    }


    return (
        <DragModal
            visible={visible}
            centered={true}
            wrapClassName={wrapClassName}
            width={width}
            titleText={titleText}
            target={'.confirmModal .ant-modal-content'}
        >
            <div className={'contentWrapper'}>
                <div className={'hintMessage'}>{content}</div>
            </div>
            <div className={'buttonGroup'}>
                <div onClick={closeModalHandler}>
                    {cancelButtonText}
                </div>
                <div onClick={okButtonHandler}>
                    {okButtonText}
                </div>
            </div>
        </DragModal>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(HintModal)
