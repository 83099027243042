/**
 * @description  get gum url list
 *
 * @param {Object} data  dental data
 * */
export const byte_getGumUrlList = (data) => {
    console.log(data)
    return  data.gum.urlList
}

/**
 * @description  get gum url
 *
 * @param {Object} data  gum data
 * */
export const byte_getGumUrl = (data) => {

    return  data.url
}

/**
 * @description  get gum file type
 *
 * @param {Object} data  gum data
 * */
export const byte_getGumFileType = (data) => {

    return  data.fileType
}



/**
 * @description  get tooth list data
 *
 * @param {Object} data  dental data
 * @param {Number} stage
 * */
export const byte_getToothListByStage = (data, stage) => {

    return  data.gum.tooth[stage]
}

/**
 * @description  get tooth url
 *
 * @param {Object} data  tooth data
 * */
export const byte_getToothUrl = (data) => {

    return  data.url
}

/**
 * @description  get tooth file type
 *
 * @param {Object} data  tooth data
 * */
export const byte_getToothFileType = (data) => {

    return  data.fileType
}

/**
 * @description  get tooth number
 *
 * @param {Object} data  tooth data
 * */
export const byte_getToothNumber = (data) => {

    return  data.number
}

/**
 * @description  get smartee or byte data
 *
 * @param {Object} data  dental data
 * */
export const byte_getSmarteeOrByteData = (data) => {

    return  data.data
}

/**
 * @description  get smartee or byte model url
 *
 * @param {Object} data  model data
 * */
export const byte_getSmarteeOrByteUrl = (data) => {

    return  data.url
}

/**
 * @description  get smartee or byte model file type
 *
 * @param {Object} data  model data
 * */
export const byte_getSmarteeOrByteFileType = (data) => {

    return  data.fileType
}
