import { cloneDeep } from "lodash"

const getObjectByPatientsList = (params, data) => {
    let obj = {}

    data.map(item=> {
        for (let key in params) {
            if(item[key] == params[key]) {
                obj = cloneDeep(item);

            }
        }
    })

    return obj;
};

const getPropertyByPatientsListObject = (params, data) => {

    if(data.hasOwnProperty(params)) {
        return data[params];
    }
    else {
        return false;
    }

};



export {
    getObjectByPatientsList,
    getPropertyByPatientsListObject
};
