import { Button, Dropdown } from 'antd';
import './index.less'
import action from "../../../../store/action";
import {connect} from "react-redux";
import {useProgress} from '@react-three/drei'
import React,{useEffect, useState} from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import store from "../../../../store";


const items = [
    {
        label: 'WebViewer data tool',
        key: 0,
    },
    {
        label: 'Byte data tool',
        key: 1,
    },
    {
        label: 'OrthoFx data tool',
        key: 2,
    },
];

function SelectDataTool(props) {

    const {
        setDataTool,
        currentDataTool
    } = props
    console.log(currentDataTool)

    const [visible, setVisible] = useState(false)




    const clickItem = (num) => {

        setDataTool(items[num])

        sessionStorage.setItem('dataTool', num)
        sessionStorage.removeItem('listItemId')
    }

    const clickButton = () => {
        setVisible(!visible)
    }

    useEffect(() => {
        let num = sessionStorage.getItem('dataTool')
        console.log(num)
        if(num !== null && num !== undefined){
            setDataTool(items[num])
        }
    }, []);



    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                setVisible(false)
            }}
        >
            <div className={'SelectDataToolWrap'} onClick={clickButton}>
                <span>{currentDataTool.label}</span>
            </div>
            <div className={'dataToolDropDownWrapper'} style={{display: visible ? 'block' : 'none'}}>
                <div className={'dropDownContainer'}>
                    <ul className={'dropDown'}>
                        {
                            items.map(item=> {
                                return(
                                    <li className={'dropDownItem ' + (currentDataTool.key === item.key ? ' active_tool' : '')} onClick={()=> clickItem(item.key)} key={item.key}>
                                        {item.label}
                                        <span></span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>

        </OutsideClickHandler>
    );
}

const mapStateToProps = state => ({
    currentDataTool: state.dataTool.currentDataTool,
})

const mapDispatchToProps = dispatch => ({
    setDataTool: val => dispatch(action.setDataTool(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectDataTool);
