import React, {useRef, useEffect, useCallback, useState} from "react";
import {useLoader} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import {connect} from "react-redux";
import action from "../../../store/action";
import * as THREE from 'three'
import {mergeVertices} from "three/addons/utils/BufferGeometryUtils";
import store from "../../../store";
import {setCurrentToothInformation} from "../../../store/action/dataTool";


/**
 * @description create tooth function. In order to batch creation tooth
 * @param props The parameter passed by the attribute within the tag
 * */
function ByteTooth(props) {
    /**
     * @param itemId String    component itemId
     * @param url String     model path
     * @param color String     model material color
     * */
    const {
        itemId,
        scale,
        setCurrentClickItemId,
        setCurrentClickInitItemRef,
        currentClickItemId,
        initTooth, // Determine if the tooth is in overlay mode
        dragging,
        keyframeNumber,
        setCurrentClickPontic,
        url,
        visible=false,
        sliderVal,
        fileType,
        toothModelData,
        toothIndex,
        stage,
        setMexicaliData,
        isUpper,
        number,
        setCurrentToothInformation,
    } = props

    const ref = useRef()



    useEffect(()=> {

        if(itemId === currentClickItemId && initTooth) {

            setCurrentClickInitItemRef(ref.current)
        }

    }, [currentClickItemId])


    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })



    const clickHandler = useCallback((e) => {

        if (dragging || initTooth) {
            return
        }

        if(e.eventObject.material.opacity === 1 && e.eventObject.visible) {

            e.stopPropagation()

            setCurrentClickItemId(itemId)

            setCurrentClickPontic(null)

        }

        let subscript = number < 30 ? 0 : 1

        setCurrentToothInformation({
            stage,
            number,
            matrix: ref.current.matrixWorld,
            initMatrix: toothModelData[subscript].gum.tooth[0][toothIndex].matrix
        })
        console.log(number)

        // ToothDataPro prevent a bubble



    }, [])


    const count1 = useRef(0)


    useEffect(()=> {

        if(ref.current && count1.current < 1) {

            ref.current.geometry.deleteAttribute("normal")
            ref.current.geometry = mergeVertices(ref.current.geometry, 0.1);
            ref.current.geometry.computeVertexNormals(true);

            count1.current++

        }
    }, [])

    const count2 = useRef(0)

    useEffect(() => {
        if(ref.current && count2.current < 1) {
            let data = toothModelData

            let subscript = isUpper ? 0 : 1

            toothModelData[subscript].gum.tooth[stage][toothIndex].matrix = ref.current.matrixWorld

            setMexicaliData(data)

            count2.current++
        }
    }, [ref.current]);

    /**
     * @description adjust model opacity
     * */
    const [opacity, setOpacity] = useState(1)

    useEffect(() => {
        if (!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal)) * 0.01)
        } else if (isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal) * 0.01)
        } else {
            setOpacity(1)
        }
    }, [sliderVal]);



    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            renderOrder={initTooth ? 1 : 0}
            scale={scale}
            // matrix={toothMatrix}
            matrixAutoUpdate={false}
            onClick={(event) => clickHandler(event)}
            name={itemId}
        >
            <meshPhysicalMaterial
                attach="material"
                color={'#FFEEE2'}
                opacity={opacity}
                transparent
                depthWrite={opacity > 0.5 ? true : false}
                normalScale={new THREE.Vector2( 0, 0 )}
                depthTest={true}
                clearcoat={1}
                clearcoatRoughness={0.3}
                roughness={0.2}
                metalness={0}
                reflectivity={0.3}
                emissive={'#FFFAF0'}
                emissiveIntensity={0.05}
            />
        </mesh>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    currentClickItemId: state.webViewer.currentClickItemId,
    dragging: state.webViewer.dragging,
    toothData: state.webViewer.toothData,
    keyframeNumber: state.webViewer.keyframeNumber,
    attachmentCheckboxValue: state.webViewer.attachmentCheckboxValue,
    panelData: state.webViewer.panelData,
    cameraControlRef: state.webViewer.cameraControlRef,
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    isMobile: state.webViewer.isMobile,
    editMode: state.webViewer.editMode,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val)),
    setCurrentClickItemId: val => dispatch(action.setCurrentClickItemId(val)),
    setCurrentClickPontic: val => dispatch(action.setCurrentClickPontic(val)),
    setCurrentClickItemRef: val => dispatch(action.setCurrentClickItemRef(val)),
    setCurrentClickInitItemRef: val => dispatch(action.setCurrentClickInitItemRef(val)),
    setDragging: val => dispatch(action.setDragging(val)),
    setToothData: val => dispatch(action.setToothData(val)),
    setPivotData: val => dispatch(action.setPivotData(val)),
    setPanelData: val => dispatch(action.setPanelData(val)),
    setCurrentToothInformation: val => dispatch(action.setCurrentToothInformation(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ByteTooth)
