import React, {useRef, useState, useEffect, useMemo} from "react";



/**
 * @description
 *
 * @param {Function} fn
 * @param {Array} inputs
 * */
export const useUpdateEffect = (fn, inputs) => {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
}


export const useFirstLoadingEffect = (fn, inputs) => {
    let count = useRef(0)

    useEffect(() => {
        if(count.current < 3) {
            fn()
        }

        count.current++
    }, inputs);

}
