import React, {useRef, useState, useEffect, useCallback, useMemo} from "react";
import {Line, PivotControls, Center} from "@react-three/drei";
import {connect} from "react-redux";
import action from "../../../../store/action";

import * as THREE from 'three'

import store from "../../../../store";


import {getGumData, getGumUrl, getGumMatrix, getGumFileType} from '../../../../dataInterface/index'
import Gum from "../../../../Components/CreateModal/Gum";
import {cloneDeep} from "lodash";


function GumDataPro(props) {

    const {
        data,
        stage,
        isUpper,
        sliderVal
    } = props

    const [gumData, setGumData] = useState(null)


    /**
     * @description   set data
     * */

    useEffect(()=> {
        let copyGumData = cloneDeep(getGumData(data, isUpper))

        setGumData(copyGumData)

    }, [])


    /**
     * @description adjust model opacity
     * */
        // slider control model opacity
    const [opacity, setOpacity] = useState(1)  // model opacity

    const [depthWrite, setDepthWrite] = useState(true);
    useEffect(() => {
        if(!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal)) * 0.01)
            setDepthWrite(false)
        }else if(isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal) * 0.01)
            setDepthWrite(false)
        }else {
            setOpacity(1)
            setDepthWrite(true)
        }
    }, [sliderVal]);



    return (
        gumData !== null ? (
            <Gum
                url={getGumUrl(gumData, stage)}
                matrix={getGumMatrix(gumData, stage)}
                fileType={getGumFileType(gumData, stage)}
                opacity={opacity}
                depthWrite={depthWrite}
            />
        ) : <></>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(GumDataPro)
