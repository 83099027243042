import {getModalApi, getPatientsListApi, getStageInfoApi, uploadPatientFileApi} from "../api/webviewerApi";
import {getOrthoFxModalApi, getOrthoFxPatientsListApi, getOrthoFxStageInfoApi} from "../api/orthoFx";
import {getByteModalApi, getBytePatientsListApi, getByteStageInfoApi} from "../api/byteApi";
import {uploadWebviewrPatientFileApi} from "../api/webviewer_mainApi"
import {message} from "antd";
import AWS from "aws-sdk";
import {Amplify} from "aws-amplify";
import {getEncryptDataFromPath} from "../../utils";

const _AWS_BUCKET = "archform-webviewer-data-v2";

/**
 * @description  data tool interface
 * */
const getModelAdaption = async (params) => {
    try {
        const response = await getModalApi(params);
        const data= response;


        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get modal:', error);
        throw error;

        return null;
    }
};

const getPatientsListAdaption = async (params) => {
    try {
        const response = await getPatientsListApi(params);
        const {data}= response;


        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get list:', error);
        throw error;

        return null;
    }
};

const getStageInfoAdaption = async (params) => {
    try {
        const response = await getStageInfoApi(params);
        const {data}= response;


        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get stage info:', error);
        throw error;

        return null;
    }
};

const getOrthoFxStageInfoAdaption = async (params) => {
    try {
        const response = await getOrthoFxStageInfoApi(params);
        const {data, code}= response;

        const adaptedData = {data, code};

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get stage info:', error);
        throw error;

        return null;
    }
};

const getOrthoFxPatientsListAdaption = async (params) => {
    try {
        const response = await getOrthoFxPatientsListApi(params);
        const {data, code}= response;


        const adaptedData = {data, code};

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get list:', error);
        throw error;

        return null;
    }
};


const getOrthoFxModelAdaption = async (params) => {
    try {
        const response = await getOrthoFxModalApi(params);
        const data= response;


        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get modal:', error);
        throw error;

        return null;
    }
};


const getBytePatientsListAdaption = async (params) => {
    try {
        const response = await getBytePatientsListApi(params);
        const {data, code}= response;


        const adaptedData = {data, code};

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get list:', error);
        throw error;

        return null;
    }
};

const getByteFxStageInfoAdaption = async (params) => {
    try {
        const response = await getByteStageInfoApi(params);
        const {data, code}= response;

        const adaptedData = {data, code};

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get stage info:', error);
        throw error;

        return null;
    }
};

const getByteModelAdaption = async (params) => {
    try {
        const response = await getByteModalApi(params);
        const data= response;


        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get modal:', error);
        throw error;

        return null;
    }
};

const uploadPatientFileAdaption = async (params, name) => {
    try {
        const response = await uploadPatientFileApi(params);

        const {code} = response

        if(code == 200) {
            message.success("Successful upload! " + name)
        }
        else {
            message.error("Fail to upload! Failed file: " + name)
        }



        return true;
    } catch (error) {
        message.error("Fail to upload! Failed file: " + name)
        console.error('Fail to upload! Failed file: ', error);
        throw error;
        return false;
    }
}

// TODO: REMOVE Mock Data
const getWebviewerModelAdaption = async (params) => {
    // console.log('....params', params)
    try {
        // let data = await (await fetch("./sample/Sample-00.zip")).arrayBuffer()
        // let data = await (await fetch(`./sample/${params.fileName}.zip`)).arrayBuffer()
        const zipUrl = params.fileName === 'Sample-00' ? './sample/Sample-00.zip' : `./sample/combine/${params.fileName}.zip`
        let data = await (await fetch(zipUrl)).arrayBuffer()

        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get modal:', error);
        throw error;

        return null;
    }
};

const getWebviewerModelAdaptionZip = async (params) => {
    try {
      let data;
  
      const { userId, path, downloadUrl } = getEncryptDataFromPath();
  
      if (userId && path) {
        data = await downloadFileByS3(userId, path);
      }
  
      if (downloadUrl) {
        data = await downloadFileByDownloadUrl(downloadUrl);
      }
  
      return {
        data,
      };
    } catch (error) {
      console.error("Error while fetching get modal:", error);
      message.warn(`Failed to load data.`);
      // throw error;
      return null;
    }
  };
  
  const downloadFileByS3 = async (userId, path) => {
    const params = {
      Bucket: _AWS_BUCKET,
      Key: `${userId}/${path}`,
    };
    const s3 = new AWS.S3();
    // Only need when use username & password credential
    s3.config.credentials = await Amplify.Auth.currentCredentials();
    const res = await s3.getObject(params).promise();
    const data = res.Body.buffer;
    return data;
  };
  
  const downloadFileByDownloadUrl = async (downloadUrl) => {
    const decodeUrl = atob(downloadUrl);
    const data = await (await fetch(decodeUrl)).arrayBuffer();
    return data;
  };

// TODO: REMOVE Mock Data
const getWebviewerPatientsListAdaption = async (params) => {
    try {
        let data = await (await fetch("./sample/patientList.json")).json()
        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get list:', error);
        throw error;

        return null;
    }
};

// TODO: REMOVE Mock Data
const getWebviewerStageInfoAdaption = async (params) => {
    try {
        let data = await (await fetch("./sample/stage.json")).json()


        const adaptedData = {
            data,
        };

        return adaptedData;
    } catch (error) {
        console.error('Error while fetching get stage info:', error);
        throw error;

        return null;
    }
};

const uploadWebviewerPatientFileAdaption = async (params, name) => {
    try {
        const response = await uploadWebviewrPatientFileApi(params);

        const {code} = response

        if(code == 200) {
            message.success("Successful upload! " + name)
        }
        else {
            message.error("Fail to upload! Failed file: " + name)
        }



        return code;
    } catch (error) {
        message.error("Fail to upload! Failed file: " + name)
        console.error('Fail to upload! Failed file: ', error);
        throw error;
        return false;
    }
}


export {
    getModelAdaption,
    getPatientsListAdaption,
    getStageInfoAdaption,
    getOrthoFxModelAdaption,
    getOrthoFxStageInfoAdaption,
    getOrthoFxPatientsListAdaption,
    getByteModelAdaption,
    getBytePatientsListAdaption,
    getByteFxStageInfoAdaption,
    uploadPatientFileAdaption,
    getWebviewerModelAdaption,
    getWebviewerModelAdaptionZip,
    uploadWebviewerPatientFileAdaption,
    getWebviewerStageInfoAdaption,
    getWebviewerPatientsListAdaption
};








