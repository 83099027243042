import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";
import action from "../../store/action";
import "./index.less"




/**
 * @description control panel. Used to change config state and change 3D model
 * */

function Slider(props) {
    const {
        range=[0, 100],
        value=0,
        snapped=false,
        snapNumber=0,
        className="",
        onChange
    } = props

    const controllerRef = useRef()
    const railRef = useRef()
    const [controlLeft, setControlLeft] = useState(8)
    const [isDragging, setIsDragging] = useState(false)



    const mousedownHandler = (e)=> {
        setIsDragging(true)

        const railWidth = railRef.current.offsetWidth

        let initLeft = controllerRef.current.offsetLeft

        let startX = e.clientX - initLeft

        let flag = false

        let isFirst = true

        let length = range[1] - range[0]

        let railSnapLeftValue = ((snapNumber + length / 2) / length) * railWidth

        let railSnapValue = Math.abs(railWidth / 2 - railSnapLeftValue)

        document.onmousemove = (e)=> {

            if(flag) {
                return
            }
            let left = e.clientX - startX

            if(left < 0) {
                left = 0
            }
            else if(left > railWidth) {
                left = railWidth
            }


            //
            if(snapped) {
                if((railWidth / 2 - railSnapValue) > initLeft || initLeft > (railWidth / 2 + railSnapValue)) {
                    isFirst = false
                }

                const distanceX = Math.abs(left - railWidth / 2)

                if(isFirst && distanceX > railSnapValue) {
                    isFirst = false
                }



                if(distanceX < railSnapValue && !isFirst) {
                    left = railWidth / 2;
                    flag = true
                }
            }


            if (left >= 0 && left + controllerRef.current.offsetWidth <= railWidth) {
                setControlLeft(left)

                let value = length * (left / railWidth) - (length / 2)

                onChange(Math.floor(value))
            }

        }

        document.onmouseup = (e) => {
            document.onmousemove = null
            document.onmouseup = null

            setTimeout(()=> {
                setIsDragging(false)
            }, 100)
        }
    }

    const touchSatrtHandler = (e)=> {
        console.log("start", e)
        setIsDragging(true)

        const railWidth = railRef.current.offsetWidth

        let initLeft = controllerRef.current.offsetLeft

        let startX = e.touches[0].clientX - initLeft

        let flag = false

        let isFirst = true

        let length = range[1] - range[0]

        let railSnapLeftValue = ((snapNumber + length / 2) / length) * railWidth

        let railSnapValue = Math.abs(railWidth / 2 - railSnapLeftValue)
        console.log(startX, railWidth, initLeft, length, railSnapValue, railSnapLeftValue)
        document.ontouchmove = (e)=> {

            if(flag) {
                return
            }
            let left = e.touches[0].clientX - startX

            if(left < 0) {
                left = 0
            }
            else if(left > railWidth) {
                left = railWidth
            }


            //
            if(snapped) {
                if((railWidth / 2 - railSnapValue) > initLeft || initLeft > (railWidth / 2 + railSnapValue)) {
                    isFirst = false
                }

                const distanceX = Math.abs(left - railWidth / 2)

                if(isFirst && distanceX > railSnapValue) {
                    isFirst = false
                }



                if(distanceX < railSnapValue && !isFirst) {
                    left = railWidth / 2;
                    flag = true
                }
            }


            if (left >= 0 && left + controllerRef.current.offsetWidth <= railWidth) {
                setControlLeft(left)

                let value = length * (left / railWidth) - (length / 2)

                onChange(Math.floor(value))
            }

        }

        document.ontouchend = (e) => {
            document.ontouchmove = null
            document.ontouchend = null

            setTimeout(()=> {
                setIsDragging(false)
            }, 100)
        }
    }

    useEffect(() => {
        let length = range[1] - range[0]
        const railWidth = railRef.current.offsetWidth

        let left = (value + length / 2) / length * railWidth
        console.log(left, "left", railWidth, length)
        setControlLeft(left)
    }, [value]);



    const clickRail = (e) => {
        if(isDragging) {
            return
        }

        let wrapperDom = document.querySelector(".archSliderWrapper")
        let wrapperOffsetLeft = wrapperDom.offsetLeft
        let parentOffsetLeft = wrapperDom.parentNode.offsetLeft

        let posX = e.clientX - wrapperOffsetLeft - parentOffsetLeft

        setControlLeft(posX)
        let length = range[1] - range[0]
        const railWidth = railRef.current.offsetWidth
        let value = length * (posX / railWidth) - (length / 2)

        onChange(Math.floor(value))

    }



    return (
        <div style={{color: "white"}} className={"archSliderWrapper " + className}>
            <div className={"rail"} ref={railRef} onClick={clickRail}>
                <div className={"controller"} style={{left: `${controlLeft}px`}} ref={controllerRef} onMouseDown={mousedownHandler} onTouchStart={touchSatrtHandler}>
                    <span className={"bigCircle"}></span>
                    <span className={"smallCircle"}></span>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    currentClickItemId: state.webViewer.currentClickItemId,
    isMobile: state.webViewer.isMobile,
    isLoading: state.webViewer.isLoading,
    currentDataTool: state.dataTool.currentDataTool,
})

const mapDispatchToProps = dispatch => ({
    setSliderValue: val => dispatch(action.setSliderValue(val)),
    setIsMobile: val => dispatch(action.setIsMobile(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Slider)
