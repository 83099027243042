import _Buffer from 'buffer'
import * as THREE from 'three'


/**
 * @description This file is mainly a utility class that implements data conversion
 * */

// Note that we need to read the data in a small way.  In nodeJs, we need use 'LE'. For example,  readDoubleLE or readFloatLE

// If it's float32, it reads in four bytes. We need to use readFloatLE
// If it's double64, it reads in eight bytes. We need to use readDoubleLE


const Buffer = _Buffer.Buffer


/**
 * @description   Transformation matrix for gum at this stage
 *
 *     (float32*4)(m00)(m01)(m02)(m03)         (no extra values between m03 and m10)
 *     (float32*4)(m10)(m11)(m12)(m13)         (no extra values between m13 and m20)
 *     (float32*4)(m20)(m21)(m22)(m23)         (no extra values between m23 and m30)
 *     (float32*4)(m30)(m31)(m32)(m33)
 *
 *
 *
 * */

export const createGumMatrix = (str) => {
    let buf = Buffer.from(str, 'base64')
    const mat = new THREE.Matrix4();
    mat.elements.splice(0);
    for (let ii = 0; ii < 16; ii++) {
        mat.elements[ii] = buf.readFloatLE(ii * 4);
    }
    mat.transpose();
    return mat
}



/**
 * @description    Transformation matrices for all teeth
 *
 *      (int32)<tooth_N>
 *      (float32*16)(m00)(m01)(m02)(m03)(m10)(m11)(m12)(m13)(m20)(m21)(m22)(m23)(m30)(m31)(m32)(m33)
 *      ......
 *      (<tooth_N> items)
 * */

export const createTeethMatrices = (arr) => {
    let buf = Buffer.from(arr, 'base64')
    // console.log(buf, 'buf!!!!!!!!!!!!')

    const mats = [];
    for (let ii = 0; ii < buf.readInt32LE(0); ii++) {
        let mat = new THREE.Matrix4();
        let elems = new Array(16);
        for (let mm = 0; mm < 16; mm++) {
            elems[mm] = parseFloat(buf.readFloatLE(4 + mm * 4 + ii * 16 * 4));
        }
        mat.fromArray(elems);
        mat.transpose();
        mats.push(mat);
    }

    return mats
}


/**
 * @description    IPR result file for upper/lower model
 *
 *      (int32)<ipr_item_N>
 *          (int32)<tooth_1_ID>
 *          (int32)<tooth_2_ID>
 *          (double64)<IPR_value>
 *          (double64*3)<collision_center_xyz>
 *          (int32)<point_NUM>
 *              (double64*3)<segment_1_point_1_xyz>
 *              (double64*3)<segment_1_point_2_xyz>
 *              (doulbe64*3)<segment_2_point_1_xyz>
 *              (doulbe64*3)<segment_2_point_2_xyz>
 *              ...
 *              (<point_NUM> items)
 *              ...
 *           ...
 *      ...
 *      (<ipr_item_N> items)
 *      ...
 *
 *
 *
 * <tooth_1_ID> and <tooth_2_ID> => IDs for adjacent teeth (tooth ID starts from 1).
 * <IPR_value> => IPR value.
 * <collision_center_xyz> => approximate center of collision area.
 * <point_NUM> => number of segment points, each segment consists of two points in 3d space. all segments form the highlighted area (red circles) in Archform software.
 * <segment_1_point_1_xyz>, <segment_1_point_2_xyz> => two points that form a segment. each point is given by x,y,z coordinates.
 *
 * NOTE: if <point_NUM>=n, expect n segment end-points (n/2 segments). there is NO guarrantee that the vertices are arranged in sequential order.
 * */

export const createIPRData = (arr) => {
    let buf = Buffer.from(arr, 'base64')
    // console.log(buf)
    const ipr_data = [];
    let byte_offset = 0;

    for (let ii = 0; ii < buf.readInt32LE(0); ii++) {
        const ipr = {};
        ipr.tooth1 = buf.readInt32LE(byte_offset + 4);
        ipr.tooth2 = buf.readInt32LE(byte_offset + 8);
        ipr.IPRValue = buf.readDoubleLE(byte_offset + 12);

        // TODO: convert to threejs vertex
        ipr.centerPosition = new THREE.Vector3(
            buf.readDoubleLE(byte_offset + 20),
            buf.readDoubleLE(byte_offset + 28),
            buf.readDoubleLE(byte_offset + 36)
        );

        const num_points = buf.readInt32LE(byte_offset + 44);

        // vertices are used to create collection faces
        ipr.vertices = new Array(num_points);
        for (let pp = 0; pp < buf.readInt32LE(byte_offset + 44); pp++) {
            ipr.vertices[pp * 3] = buf.readDoubleLE(byte_offset + 48 + pp * 24);
            ipr.vertices[pp * 3 + 1] = buf.readDoubleLE(byte_offset + 56 + pp * 24);
            ipr.vertices[pp * 3 + 2] = buf.readDoubleLE(byte_offset + 64 + pp * 24);
        }
        ipr.vertices = new Float32Array(ipr.vertices)
        ipr_data.push(ipr);
        byte_offset += 68 + (num_points - 1) * 24;
    }

    return ipr_data
}



/**
 * @description    Tooth.js visibility file for upper/lower model  ("<Case_Name>__<UPPER/LOWER>__VISIBILITY.raw")
 *
 *      (int32)<tooth_N>
 *      (int32)<tooth_1_visibility>
 *      ...
 *      (<tooth_N> items)
 *      ...
 *
 *      (1 - visible, 0 - invisible)
 * */

export const createVisibilityData = (arr) => {
    let buf = Buffer.from(arr, 'base64')

    const visibilityData = [];

    for (let ii = 1; ii <= buf.readInt32LE(0); ii++) {
        visibilityData.push(buf.readInt32LE(ii * 4));
    }

    return visibilityData;
}





/**
 * @description    Tooth.js numbers ("<Case_Name>__<UPPER/LOWER>__TOOTH_NUMBERS.raw")
 *
 *      (int32)<tooth_N>
 *         (int32)<tooth_number_for_tooth_1>
 *         ...
 *         (<tooth_N> items)
 *         ...
 * */

export const createNumbersData = (arr) => {
    let buf = Buffer.from(arr, 'base64')

    const NumbersData = [];

    for (let ii = 1; ii <= buf.readInt32LE(0); ii++) {
        NumbersData.push(buf.readInt32LE(ii * 4));
    }

    return NumbersData;
}





/**
 * @description    (Optional) Pontic mesh files for upper/lower model ("<Case_Name>__<UPPER/LOWER>__PONTIC_<pontic_ID>.stl"), (transformed and saved in scene coordinates, no extra transformation required.)
 *
 * -> Each transformation matrix (4x4) is stored in a separate *.raw file, matrix elements stored as binary float values, row-major, 16 elements stored in sequential order.
 *
 *      matrix format (32-bit float type): (float32*4)(m00)(m01)(m02)(m03)         (no extra values between m03 and m10)
 *                                            (float32*4)(m10)(m11)(m12)(m13)         (no extra values between m13 and m20)
 *                                            (float32*4)(m20)(m21)(m22)(m23)         (no extra values between m23 and m30)
 *                                            (float32*4)(m30)(m31)(m32)(m33)
 *
 *      (ps: m03, m13, m23 are for translation)
 *
 * -> A transformed vertex is obtained by: V' = M * V, where M is the matrix read from the .raw file, V is a column-vector of vertex location.
 * */





/**
 * @description    process IPR data
 *
 * */

const checkValideIPRData = (data) => {
    if (data.length === parseInt(data[6]) * 3 + 7) return true;
    else {
        console.error("No IPR Data or Invalid IPR Data");
        return false;
    }
}

export const processIPRData = (allText) => {
    let IPRData = [];
    let allTextLines = allText.split(/\r\n|\n/);

    allTextLines.forEach((item, index)=> {
        let segmentData = item.split(",");
        if (checkValideIPRData(segmentData)) {
            let vertices = [];
            for (let i = 7; i < segmentData.length; i++) {
                vertices.push(segmentData[i]);
            }
            let pos = new THREE.Vector3(
                segmentData[3],
                segmentData[4],
                segmentData[5]
            );
            let proxValue = "" + segmentData[2];
            IPRData.push({
                tooth1: segmentData[0],
                tooth2: segmentData[1],
                IPRValue: proxValue,
                centerPosition: pos,
                vertices: vertices,
            });
        }
    })

}
