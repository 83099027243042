import React, {useRef, useState, useEffect, useCallback, useMemo} from "react";
import {Line, PivotControls, Center} from "@react-three/drei";
import {connect} from "react-redux";
import action from "../../../../store/action";

import * as THREE from 'three'

import store from "../../../../store";


import {
    getGumData,
    getPonticData,
    getPonticStartStage, getPonticUrl,
    getPonticUrlList
} from '../../../../dataInterface/index'
import Gum from "../../../../Components/CreateModal/Gum";
import {cloneDeep} from "lodash";
import Pontic from "../../../../Components/CreateModal/Pontic";


function PonticDataPro(props) {

    const {
        data,
        stage,
        isUpper,
        sliderVal,
        ponticCheckboxValue
    } = props

    const [ponticData, setPonticData] = useState([])

    const [ponticUrlList, setPonticUrlList] = useState([])

    /**
     * @description   set data
     * */

    useEffect(()=> {
        let gumData = getGumData(data, isUpper)

        let copyPonticData = cloneDeep(getPonticData(gumData))

        // console.log(copyPonticData, 'copyPonticDatacopyPonticData')

        setPonticData(copyPonticData)

    }, [])


    /**
     * @description adjust model opacity
     * */
        // slider control model opacity
    const [opacity, setOpacity] = useState(1)  // model opacity

    useEffect(() => {
        if(!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal)) * 0.01)

        }else if(isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal) * 0.01)

        }else {
            setOpacity(1)

        }

    }, [sliderVal]);



    return (
        <group visible={ponticCheckboxValue} name={'pontic'}>
            {
                ponticData.map((ponticItem, ponticIndex) => {
                    return(
                        <group name={'pontic' + ponticItem.number}>
                            {
                                getPonticUrlList(ponticData, ponticIndex).map((val,ponticUrlIndex)=> {
                                    return (
                                        val !== undefined ? (
                                            <Pontic
                                                itemId={'pontic' + '-' + ponticItem.number}
                                                url={val.url}
                                                fileType={val.fileType}
                                                stageNum={ponticUrlIndex + getPonticStartStage(ponticData, ponticIndex)}
                                                key={'pontic' + '-' + ponticItem.number + '-' + ponticUrlIndex}
                                                opacity={opacity}
                                            />
                                        ) : <></>
                                    )
                                })
                            }
                        </group>
                    )
                })
            }
        </group>
    )
}

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    ponticCheckboxValue: state.webViewer.ponticCheckboxValue,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(PonticDataPro)
