import './style/global.less'
import {Provider} from 'react-redux'
import store from './store/index'
import DataProcessing from './View/DataProcessing/index'
import {useEffect, useState} from "react";
import {Amplify} from "aws-amplify";
import {getEncryptDataFromPath, getAWSS3ServiceClient, handleRefreshToken} from "./utils";
import {message} from "antd";
import {config, readonly_config} from './config/amplify'
import { getMode } from './utils'

Amplify.configure(config);

function App() {
  const [buildTarget, setBuildTarget] = useState("")
  const { readOnlyMode } = getMode()

  const fetchBuildConfig = () => {
    fetch('./buildConfig.json')
      .then(response => response.json())
      .then(data => {
        if (data !== null) {
          setBuildTarget(data.buildTarget)
        }

      })
      .catch(error => {

        console.error('Error:', error);
      });
  }

  const reLogin = async () => {
    try {
      Amplify.configure(config);
      const { username, password } = getEncryptDataFromPath();
      await Amplify.Auth.signIn(username, password);
      console.log('Re-login successful');
      fetchBuildConfig()
    } catch(err) {
      console.error('Failed to re-login:', err);
      message.warn(`Failed to Re-login`)
    }
  }

  /**
   * use username & password
   */
  const onLoad = async () => {
    try {
      // await Amplify.Auth.currentAuthenticatedUser()
      fetchBuildConfig()
    } catch {
      reLogin()
    }
  }

  /**
   * 
   */
  const anonymousAccountLogin = async () => {
    try {
      // Amplify.configure(readonly_config);
      // const username = "61ab55f0-d0d1-7080-d73f-050b5a37fa14";
      // const password = "WebViewer2Guest20240413!";
      // await Amplify.Auth.signIn(username, password);
      // console.log('Anonymous Account Login Success');
      fetchBuildConfig()
    } catch(err) {
      console.log('Anonymous Account Login Failed:', err);
      // message.warn(`Failed to Re-login`)
      // In order to be compatible with old dashboard version 
      reLogin()
    }
  }

  /**
   * use jwtToken credential
   */
  const onLoadWithJwtToken = async () => {
    if(readOnlyMode) {
      // TODO: use anonymous account credential
    }
    const { token } = getEncryptDataFromPath()
    await getAWSS3ServiceClient(token)
    fetchBuildConfig()
  }

  /**
   * use refreshToken to request new accessToken credential
   */
  const onLoadWithRefreshToken = async () => {
    // TODO: ERR: NotAuthorizedException: Invalid login token. Missing a required claim: aud
    const { refreshToken } = getEncryptDataFromPath()
    const { newAccessToken } = await handleRefreshToken(refreshToken, config.Auth.userPoolWebClientId)
    // console.log("🚀 ~ .then ~ newAccessToken:", newAccessToken)
    await getAWSS3ServiceClient(newAccessToken)
    fetchBuildConfig()
  }

  useEffect(() => {
    if (readOnlyMode) {
      anonymousAccountLogin()
    } else {
      onLoad()
    }
  }, []);

  return (
    <Provider store={store}>
      <div className="App" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {
          buildTarget === "" ? <></> : <DataProcessing buildTarget={buildTarget}/>
        }
      </div>
    </Provider>
  );
}

export default App;


