import React, { useRef, useState, useEffect, useContext } from 'react'
import './index.less'
import { connect } from 'react-redux'
import action from '../../../../store/action/index'
import DragModal from '../../../../Components/DragModal/index'
import { cloneDeep } from "lodash";
import SvgIcon from "../../../../Components/SvgIcon";

import { getGumData, getIPRData, getTableViewMovementData, getToothData } from '../../../../dataInterface/index'

import { jawOrientationDetector, panelDataToString } from '../../../../lib/threeLib'
import { DentalNotationHeadRowData, DentalNotationType } from './data'

// Set current "Dental Notation Type"
const currentDentalNotationType = DentalNotationType.Palmer

function Grid(props) {

    const { keyframeNumber, currentClickItemId, visible, closeModal, dentalData, setDentalData, isUpdated } = props


    const [selectType, setSelectType] = useState(true) // true: upper, false: lower

    const [movementData, setMovementData] = useState([])

    const [iprData, setIprData] = useState([])

    // primary data structure
    let defaultData = [
        {
            name: 'headRow',
            data: [],
        },
        {
            name: 'buccal',
            data: [
                {
                    value: 'Lingual (-) / Buccal (+)',
                    className: 'firstCell',
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }],
            unit: 'mm'
        },
        {
            name: 'mesial',
            data: [
                {
                    value: 'Mesial (-) / Distal (+)',
                    className: 'firstCell',
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }],
            unit: 'mm'
        },
        {
            name: 'intrusion',
            data: [
                {
                    value: 'Intrusion (-) / Extrusion (+)',
                    className: 'firstCell',
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }],
            unit: 'mm'
        },
        {
            name: 'tip',
            data: [
                {
                    value: 'Tip Mesial (-) / Distal (+)',
                    className: 'firstCell',
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }],
            unit: '°'
        },
        {
            name: 'torque',
            data: [
                {
                    value: 'Torque Buccal (-) / Lingual (+)',
                    className: 'firstCell',
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }],
            unit: '°'
        },
        {
            name: 'rotation',
            data: [
                {
                    value: 'Rotation Mesial (-) / Distal (+)',
                    className: 'firstCell',
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }, {
                    value: '',
                    className: 'data'
                }],
            unit: '°'
        },
        {
            name: 'IPR',
            data: [
                {
                    value: 'Gap (-) / IPR (+)',
                    className: 'firstCell',
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }, {
                    value: '0.0',
                    className: 'data'
                }],
            unit: 'mm'
        },
    ]

    const [renderData, setRenderData] = useState(cloneDeep(defaultData))

    useEffect(() => {

        if (dentalData.length !== 0) {

            let gumData = getGumData(dentalData, selectType)

            let toothData = cloneDeep(getToothData(gumData))

            let data1 = getTableViewMovementData(toothData, keyframeNumber)

            let data2 = cloneDeep(getIPRData(gumData))

            // console.log(gumData, toothData)

            setMovementData(data1)

            setIprData(data2[keyframeNumber])

        }
    }, [dentalData, keyframeNumber, selectType]);


    /**
     *
     * */

    const getPos = (target, arr, attr) => {
        let pos = 0

        arr.map((item, i) => {
            if (item[attr] == target) {
                pos = i
            }

        })

        return pos

    }

    const headPos = getPos('headRow', defaultData, 'name')
    const buccalPos = getPos('buccal', defaultData, 'name')
    const mesialPos = getPos('mesial', defaultData, 'name')
    const intrusionPos = getPos('intrusion', defaultData, 'name')
    const tipPos = getPos('tip', defaultData, 'name')
    const torquePos = getPos('torque', defaultData, 'name')
    const rotationPos = getPos('rotation', defaultData, 'name')
    const iprPos = getPos('IPR', defaultData, 'name')

    useEffect(() => {
        if (movementData.length !== 0) {
            let copyRenderData = cloneDeep(defaultData)

            const headRowData = DentalNotationHeadRowData[currentDentalNotationType].map(i => ({
                value: i, 
                className: 'head'
            }))
            copyRenderData[headPos].data = selectType ? headRowData.slice(0, 16) :headRowData.slice(16)
            // Work with the header column 1, changing the header text up and down
            copyRenderData[headPos].data.unshift({
                value: keyframeNumber < 10 ? 'STAGE ' + '0' + keyframeNumber : 'STAGE ' + keyframeNumber,
                className: 'head firstCell'
            })

            let toothNumArr = new Array(16)

            // movement data
            movementData.map(item => {
                let subscript = 1

                if (item.number < 20) {
                    subscript = -item.number + 19
                } else if (item.number > 20 && item.number < 30) {
                    subscript = item.number - 12
                } else if (item.number > 30 && item.number < 40) {
                    subscript = item.number - 22
                } else if (item.number > 40) {
                    subscript = -item.number + 49
                }

                // fill toothNumArr. In order to pave the way for the acquisition of ipr data later
                if (item.number >= 11 && item.number <= 18) {
                    toothNumArr[18 - item.number] = 1
                } else if (item.number >= 21 && item.number <= 28) {
                    toothNumArr[item.number - 13] = 1
                } else if (item.number >= 41 && item.number <= 48) {
                    toothNumArr[48 - item.number] = 1
                } else if (item.number >= 31 && item.number <= 38) {
                    toothNumArr[item.number - 23] = 1
                }
                copyRenderData[buccalPos].data[subscript].value = item.visible ? panelDataToString(item.movement.buccal, 2) : '-'
                copyRenderData[mesialPos].data[subscript].value = item.visible ? panelDataToString(item.movement.meisal, 2) : '-'
                copyRenderData[intrusionPos].data[subscript].value = item.visible ? panelDataToString(item.movement.intrusion, 2) : '-'
                copyRenderData[tipPos].data[subscript].value = item.visible ? panelDataToString(item.movement.tip, 2) : '-'
                copyRenderData[torquePos].data[subscript].value = item.visible ? panelDataToString(item.movement.torque, 2) : '-'
                copyRenderData[rotationPos].data[subscript].value = item.visible ? panelDataToString(item.movement.rotation, 2) : '-'
            })

            copyRenderData[1].data.map((item, i) => {
                // console.log(i, "========================", item.value)
                if (i === 1 && (item.value === '' || item.value === '-')) {
                    copyRenderData[iprPos].data[1].value = '-'
                } else if (i > 1 && i < 16 && item.value === '-') {
                    copyRenderData[iprPos].data[i].value = '-'
                    copyRenderData[iprPos].data[i - 1].value = '-'
                } else if (i === 16 && (item.value === '' || item.value === '-')) {
                    copyRenderData[iprPos].data[i - 1].value = '-'
                }
            })

            const checkTooth = () => {
                let arr = []

                toothNumArr.map((item, i) => {
                    if (item !== undefined) {
                        arr.push(i)
                    }
                })

                return arr
            }
            let emptyArr = checkTooth()


            // ipr data
            iprData.map((item, i) => {

                let pos = emptyArr[item.tooth1]
                let value = (item.IPRValue).toFixed(1)

                copyRenderData[iprPos].data[pos].value = value
            })
            setRenderData(copyRenderData)
        }
    }, [movementData])

    useEffect(() => {
        if (iprData.length !== 0) {

        }
    }, [iprData]);


    useEffect(() => {
        if (currentClickItemId && currentClickItemId < 30) {
            setSelectType(true)
        } else if (currentClickItemId && currentClickItemId > 30) {
            setSelectType(false)
        }
    }, [currentClickItemId]);



    // click upper or lower button
    const clickSelectBtn = (bool) => {
        setSelectType(bool)
    }

    // model box body dom
    const domElement = (
        <div style={{ position: 'relative', padding: "13px 21px 11px 21px" }}>
            <div className={'buttonStyle ' + (selectType ? 'activeBtn' : '')} onClick={() => clickSelectBtn(true)}>UPPER</div>
            <div className={'buttonStyle ' + (!selectType ? 'activeBtn' : '')} style={{ marginLeft: '12px' }} onClick={() => clickSelectBtn(false)}>LOWER</div>
        </div>
    )


    return (
        <div style={{ position: 'relative', width: '100%' }}>
            {
                !isUpdated ? (
                    <DragModal visible={visible} wrapClassName={'gridModal'} width={'989px'} titleText={domElement} closeModal={closeModal} target={'.gridModal .ant-modal-content'}>
                        <div className={'tableWrapper'}>
                            {
                                renderData.map((item, i) => {
                                    return (
                                        <div className={'row ' + item.name}>

                                            {
                                                item.data.map((val, j) => {
                                                    return (
                                                        <>
                                                            {
                                                                (item.name === 'IPR' || item.name === 'Gap') && j == 1 ? (
                                                                    <div className={'placeholder1'}></div>
                                                                ) : <></>
                                                            }
                                                            <div className={val.className + (val.value === '-' ? ' empty' : '')}>
                                                                <>
                                                                    {(item.name === 'IPR') && j > 0 ? (
                                                                        <SvgIcon iconClass={'triangle'} style={{ fontSize: '8px', position: 'absolute', top: '-6px', left: '50%', transform: 'translateX(-50%)' }} />
                                                                    ) : <></>
                                                                    }
                                                                    <span style={{ fontWeight: j === 0 ? 'bold' : '400' }}>
                                                                        {val.value === "" ? "-" : val.value}
                                                                    </span>
                                                                    {
                                                                        item.unit && j === 0 ? (
                                                                            <span style={{ transform: '', float: 'right', marginRight: '4px', color: 'lightgray' }}>{item.unit}</span>
                                                                        ) : <></>
                                                                    }
                                                                </>
                                                            </div>
                                                            {
                                                                (item.name === 'IPR' || item.name === 'Gap') && j == 15 ? (
                                                                    <div className={'placeholder2'}></div>
                                                                ) : <></>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }

                                        </div>
                                    )
                                })
                            }

                        </div>
                    </DragModal>
                ) : <></>
            }

        </div>
    );
}

const mapStateToProps = state => ({
    keyframeNumber: state.webViewer.keyframeNumber,
    currentClickItemId: state.webViewer.currentClickItemId,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Grid)
