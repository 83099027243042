import Types from '../../action/types';

// Define a default value for the state, and be careful to be consistent with the values of the data structures here
const defaultState = {
    sliderVal: 0,
    view: 'home',  // 6 kinds of state (front back lower upper right left), empty string is init state. When we change model rotation, we need to set empty string
    gridCheckboxValue: false,
    overlayCheckboxValue: false,
    collisionCheckboxValue: true,
    IPRCheckboxValue: true,
    attachmentCheckboxValue: true,
    cutoutsCheckboxValue: true,
    ponticCheckboxValue: true,
    biteStopCheckboxValue: true,
    currentClickItemId: null,
    currentClickPontic: null,
    currentClickItemRef: null,
    currentClickInitItemRef: null,
    keyframeNumber: 0,
    stageCount: 1,
    toothData: [],
    dragging: false, // pivotControl drag
    isDragCamera: false, // drag camera
    isLoading: false,
    pivotData: {
        current: '',
        mat: ''
    },
    panelData: [],
    iprData: [],
    attachmentData: {},
    cutoutsData: {},
    hooksData: {},
    ponticData: [],
    biteStopData: {},
    gumData: [],
    cameraControlRef: null,
    currentPatient: {},
    loadingData: true,
    popoverVisible: false,
    editMode: false, //
    movementMode: false,
    isMobile: false,
    reduxMovementData: [],
    dentalModels: [],
    movementData: {},
    isCombine: true
}

/**
 * action handlers，The processing action returns the state
 */
export default function webViewer(state = defaultState, action) {
    switch (action.type) {
        case Types.RESET_APP:
            return defaultState;
        case Types.GRID_CHECKBOX_VALUE_SET:
            return {
                ...state,
                gridCheckboxValue: action.gridCheckboxValue
            };
        case Types.OVERLAT_CHECKBOX_VALUE_SET:
            return {
                ...state,
                overlayCheckboxValue: action.overlayCheckboxValue
            };
        case Types.COLLISION_CHECKBOX_VALUE_SET:
            return {
                ...state,
                collisionCheckboxValue: action.collisionCheckboxValue
            };
        case Types.IPR_CHECKBOX_VALUE_SET:
            return {
                ...state,
                IPRCheckboxValue: action.IPRCheckboxValue
            };
        case Types.ATTACHMENT_CHECKBOX_VALUE_SET:
            return {
                ...state,
                attachmentCheckboxValue: action.attachmentCheckboxValue
            };
        case Types.CUTOUTS_CHECKBOX_VALUE_SET:
            return {
                ...state,
                cutoutsCheckboxValue: action.cutoutsCheckboxValue
            };
        case Types.PONTIC_CHECKBOX_VALUE_SET:
            return {
                ...state,
                ponticCheckboxValue: action.ponticCheckboxValue
            };
        case Types.BITESYOP_CHECKBOX_VALUE_SET:
            return {
                ...state,
                biteStopCheckboxValue: action.biteStopCheckboxValue
            };

        case Types.SLIDER_VALUE:
            return {
                ...state,
                sliderVal: action.sliderVal
            };
        case Types.VIEW:
            return {
                ...state,
                view: action.view
            };
        case Types.CURRENT_CLICK_ITEM_ID:
            return  {
                ...state,
                currentClickItemId: action.currentClickItemId
            }
        case Types.CURRENT_CLICK_PONTIC:
            return  {
                ...state,
                currentClickPontic: action.currentClickPontic
            }
        case Types.CURRENT_CLICK_ITEM_REF:
            return  {
                ...state,
                currentClickItemRef: action.currentClickItemRef
            }
        case Types.CURRENT_CLICK_INIT_ITEM_REF:
            return  {
                ...state,
                currentClickInitItemRef: action.currentClickInitItemRef
            }
        case Types.KEYFRAME_NUMBER:
            return  {
                ...state,
                keyframeNumber: action.keyframeNumber
            }
        case Types.TOOTH_DATA:
            return  {
                ...state,
                toothData: action.toothData
            }
        case Types.DRAGGING:
            return  {
                ...state,
                dragging: action.dragging
            }
        case Types.IS_DRAG_CAMERA:
            return  {
                ...state,
                isDragCamera: action.isDragCamera
            }
        case Types.IS_Loading:
            return  {
                ...state,
                isLoading: action.isLoading
            }
        case Types.PIVOT_DATA:
            return  {
                ...state,
                pivotData: action.pivotData
            }
        case Types.PANEL_DATA:
            return  {
                ...state,
                panelData: action.panelData
            }
        case Types.IPR_DATA:
            return  {
                ...state,
                iprData: action.iprData
            }
        case Types.ATTACHMENT_DATA:
            return  {
                ...state,
                attachmentData: action.attachmentData
            }
        case Types.CUTOUTS_DATA:
            return  {
                ...state,
                cutoutsData: action.cutoutsData
            }
        case Types.HOOKS_DATA:
            return  {
                ...state,
                hooksData: action.hooksData
            }
        case Types.BITESTOP_DATA:
            return  {
                ...state,
                biteStopData: action.biteStopData
            }
        case Types.PONTIC_DATA:
            return  {
                ...state,
                ponticData: action.ponticData
            }
        case Types.GUM_DATA:
            return  {
                ...state,
                gumData: action.gumData
            }
        case Types.CAMERA_CONTROL_REF:
            return  {
                ...state,
                cameraControlRef: action.cameraControlRef
            }
        case Types.CURRENT_PATIENT:
            return  {
                ...state,
                currentPatient: action.currentPatient
            }
        case Types.LOADING_DATA:
            return  {
                ...state,
                loadingData: action.loadingData
            }
        case Types.POPOVER_VISIBLE:
            return  {
                ...state,
                popoverVisible: action.popoverVisible
            }
        case Types.EDIT_MODE:
            return  {
                ...state,
                editMode: action.editMode
            }
        case Types.MOVEMENT_MODE:
            return  {
                ...state,
                movementMode: action.movementMode
            }
        case Types.ISMOBILE:
            return  {
                ...state,
                isMobile: action.isMobile
            }
        case Types.REDUX_MOVEMENT_DATA:
            return  {
                ...state,
                reduxMovementData: action.reduxMovementData
            }
        case Types.STAGE_COUNT:
            return  {
                ...state,
                stageCount: action.stageCount
            }
        case Types.DENTAL_MODELS:
            return  {
                ...state,
                dentalModels: action.dentalModels
            }
        case Types.MOVEMENT_DATA:
            return  {
                ...state,
                movementData: action.movementData
            }
        case Types.IS_COMBINE:
            return  {
                ...state,
                isCombine: action.isCombine
            }
        default:
            return state;
    }
}
