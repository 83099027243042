import React, { useRef, useState, useMemo } from 'react'
import LayersStyle from './index.less'
import SvgIcon from "../../../../Components/SvgIcon/index";
import {connect} from 'react-redux'
import { Checkbox } from 'antd';
import action from '../../../../store/action/index'
import {
    setAttachmentCheckboxValue, setBiteStopCheckboxValue,
    setCollisionCheckboxValue, setCutoutsCheckboxValue,
    setIPRCheckboxValue,
    setOverlayCheckboxValue, setPonticCheckboxValue
} from "../../../../store/action/webViewer";
import ArchPopover from '../../../../Components/ArchPopover/index'


function Layers(props) {

    const {
        setGridCheckboxValue,
        gridCheckboxValue,
        setOverlayCheckboxValue,
        overlayCheckboxValue,
        cutoutsCheckboxValue,
        collisionCheckboxValue,
        IPRCheckboxValue,
        attachmentCheckboxValue,
        ponticCheckboxValue,
        biteStopCheckboxValue,
        setCollisionCheckboxValue,
        setIPRCheckboxValue,
        setAttachmentCheckboxValue,
        setCutoutsCheckboxValue,
        setPonticCheckboxValue,
        setBiteStopCheckboxValue,
        isMobile
    } = props

    const [visible, setVisible] = useState(true) // control panel display or hidden

    const onChange = (e, id) => {

        if(id == 'Grid') {
            setGridCheckboxValue(e.target.checked)
        }else if(id == 'Overlay') {
            setOverlayCheckboxValue(e.target.checked)
        }else if(id == 'Collisions') {
            setCollisionCheckboxValue(e.target.checked)
        }else if(id == 'IPR') {
            setIPRCheckboxValue(e.target.checked)
        }else if(id == 'Attachment') {
            setAttachmentCheckboxValue(e.target.checked)
        }else if(id == 'Cutouts') {
            setCutoutsCheckboxValue(e.target.checked)
        }else if(id == 'Pontic') {
            setPonticCheckboxValue(e.target.checked)
        }else if(id == 'biteStop') {
            setBiteStopCheckboxValue(e.target.checked)
        }
    };

    /**
     * @description   control panel display or hidden
     * */
    const controlPanelHandler = () => {
        setVisible(!visible)
    }

    const content = (
        <div>
            <p>Content</p>
            <p>Content</p>
        </div>
    );


    return (
        <div className={'layersWrapper'} style={{height: visible ? '372px' : '41px', display: isMobile ? 'none' : 'block'}}>
            <div className={'layersTitle'} onClick={controlPanelHandler}>
                <span style={{color: 'black'}}>Layers</span>
            </div>
            <ArchPopover right={'10px'} top={'10px'} width={'260px'} height={'23px'} placement={'topLeft'} text={["Choose the visibility of different layers."]} overlayClassName={'layerPopover'} />
            <div className={'checkBoxWrapper'} style={{display: visible ? 'block' : 'none'}}>
                <hr className={'divider'} />
                <div className={'checkBoxPanel'}>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={gridCheckboxValue} onChange={(e)=> onChange(e, 'Grid')}>Grid</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={IPRCheckboxValue} onChange={(e)=> onChange(e, 'IPR')}>IPR</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={attachmentCheckboxValue} onChange={(e)=> onChange(e, 'Attachment')}>Attachment</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={cutoutsCheckboxValue} onChange={(e)=> onChange(e, 'Cutouts')}>Cutouts & Hooks</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={overlayCheckboxValue} onChange={(e)=> onChange(e, 'Overlay')}>Superimposition</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={collisionCheckboxValue} onChange={(e)=> onChange(e, 'Collisions')}>Collisions</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={ponticCheckboxValue} onChange={(e)=> onChange(e, 'Pontic')}>Pontic</Checkbox>
                    </div>
                    <div className={'checkboxItem'}>
                        <Checkbox checked={biteStopCheckboxValue} onChange={(e)=> onChange(e, 'biteStop')}>Bite Stop</Checkbox>
                    </div>
                </div>
                <button className={'doneBtn'} onClick={controlPanelHandler}>
                    <span>DONE</span>
                </button>
            </div>

        </div>
    );
}

const mapStateToProps = state => ({
    gridCheckboxValue: state.webViewer.gridCheckboxValue,
    overlayCheckboxValue: state.webViewer.overlayCheckboxValue,
    cutoutsCheckboxValue: state.webViewer.cutoutsCheckboxValue,
    collisionCheckboxValue: state.webViewer.collisionCheckboxValue,
    IPRCheckboxValue: state.webViewer.IPRCheckboxValue,
    attachmentCheckboxValue: state.webViewer.attachmentCheckboxValue,
    ponticCheckboxValue: state.webViewer.ponticCheckboxValue,
    biteStopCheckboxValue: state.webViewer.biteStopCheckboxValue,
    isMobile: state.webViewer.isMobile,
})

const mapDispatchToProps = dispatch => ({
    setGridCheckboxValue: val => dispatch(action.setGridCheckboxValue(val)),
    setOverlayCheckboxValue: val => dispatch(action.setOverlayCheckboxValue(val)),
    setCollisionCheckboxValue: val => dispatch(action.setCollisionCheckboxValue(val)),
    setIPRCheckboxValue: val => dispatch(action.setIPRCheckboxValue(val)),
    setAttachmentCheckboxValue: val => dispatch(action.setAttachmentCheckboxValue(val)),
    setCutoutsCheckboxValue: val => dispatch(action.setCutoutsCheckboxValue(val)),
    setPonticCheckboxValue: val => dispatch(action.setPonticCheckboxValue(val)),
    setBiteStopCheckboxValue: val => dispatch(action.setBiteStopCheckboxValue(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layers)
