/*
* action     create function
*/
import Types from '../types';


/**
 * set confirm modal visible
 * @param {Boolean} val
 */
export function setConfirmModalVisible(val) {
    return { type: Types.CONFIRM_MODAL_VISIBLE, confirmModalVisible: val };
}

/**
 * set setup modal visible
 * @param {Boolean} val
 */
export function setSetupModalVisible(val) {
    return { type: Types.SETUP_MODAL_VISIBLE, setupModalVisible: val };
}

/**
 * set text area value
 * @param {String} val
 */
export function setTextAreaValue(val) {
    return { type: Types.TEXTAREA_VALUE, textAreaValue: val };
}






