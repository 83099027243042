// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-content {
  border-radius: 8px;
}
.react-draggable {
  border-radius: 8px;
}
.ant-modal {
  animation-duration: 0s !important;
}
.ant-modal .ant-modal-content {
  border-radius: 8px;
}
.ant-modal .ant-modal-header {
  border-radius: 8px 8px 0 0;
}
.ant-modal .ant-modal-body {
  border-radius: 0 0 8px 8px;
}
.ant-modal-wrap {
  z-index: 20 !important;
}
.dragAndResize {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Components/DragModal/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EAEE,kBAAA;AADF;AAKA;EACE,iCAAA;AAHF;AAEA;EAKI,kBAAA;AAJJ;AADA;EAQI,0BAAA;AAJJ;AAJA;EAYI,0BAAA;AALJ;AAWA;EACE,sBAAA;AATF;AAYA;EACE,gBAAA;AAVF","sourcesContent":[".ant-modal-content {\n  border-radius: 8px;\n}\n\n.react-draggable {\n  //position: unset !important;\n  border-radius: 8px;\n}\n\n\n.ant-modal {\n  animation-duration: 0s !important;\n\n  .ant-modal-content {\n    //background-color: unset !important;\n    border-radius: 8px;\n  }\n  .ant-modal-header {\n    border-radius: 8px 8px 0 0;\n  }\n\n  .ant-modal-body {\n    border-radius: 0 0 8px 8px;\n  }\n\n}\n\n\n.ant-modal-wrap {\n  z-index: 20 !important;\n}\n\n.dragAndResize {\n  overflow: hidden;\n  //height: 75vh !important;\n  //width: 100% !important;\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
