import React, {useRef, useState, useEffect} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action/index'
import {setKeyframeNumber, setStageCount} from "../../../../store/action/webViewer";
import {getStageInfoApi} from "../../../../http/api/webviewerApi";
import {getByteStageInfoApi} from '../../../../http/api/byteApi'
import SvgIcon from "../../../../Components/SvgIcon";
import ArchPopover from "../../../../Components/ArchPopover";
import {setUpperOrLower} from "../../../../store/action/dataTool";
import {cloneDeep} from "lodash";


/**
 * @description   stage component
 * */
const ByteControl = React.memo(function ByteControl(props) {
    /**
     * @param {Number} keyframeNumber
     * @param {Function} setKeyframeNumber
     * @param {Object} currentPatient
     * */
    const {
        keyframeNumber,
        setKeyframeNumber,
        currentPatient,
        isMobile,
        setStageCount,
        currentDataTool,
        setUpperOrLower,
        upperOrLower,
        byteStageList,
        renderData=[],
        setIsCombine,
        isCombine,
        clickCombineOrSeparate
    } = props

    const iconArr = [
        {
            fileName: '171',
            id: 'firstKeyframe'
        },
        {
            fileName: '174',
            id: 'previousKeyframe'
        },
        {
            fileName: '172',
            id: 'playOrPause'
        },
        {
            fileName: '173',
            id: 'nextKeyframe'
        },
        {
            fileName: '175',
            id: 'lastKeyframe'
        }]  // save icon files name

    const [playBtnValue, setPlayBtnValue] = useState(true)
    const [isDrag, setIsDrag] = useState(false);  // Determines whether it is the keyframe drag Event

    const text = ['Use play buttons to view the treatment process.', 'Click on stages to switch.']

    const [mousedown, setMousedown] = useState(false);

    const [byteRenderData, setByteRenderData] = useState(cloneDeep(renderData))


    /**
     * @description click handler
     * */
    const clickControlItem = (id) => {
        switch (id) {
            case 'nextKeyframe': nextKeyframeBtnHandler();
                break;
            case 'previousKeyframe': previousKeyframeBtnHandler();
                break;
            case 'firstKeyframe': firstKeyframeBtnHandler();
                break;
            case 'lastKeyframe': lastKeyframeBtnHandler();
                break;
            case 'playOrPause': playOrPauseBtnHandler();
                break;
        }
    }

    /**
     * @description   next button handler. For jump to the next keyframe
     * */
    const nextKeyframeBtnHandler = () => {
        console.log(byteRenderData.length)
        if(byteRenderData.length - 1 === keyframeNumber) {
            setKeyframeNumber(0)
            return
        }
        setKeyframeNumber(keyframeNumber + 1)

    }

    /**
     * @description   previous button handler. For jump to the previous keyframe
     * */
    const previousKeyframeBtnHandler = () => {
        if(keyframeNumber == 0) {
            setKeyframeNumber(byteRenderData.length - 1)
            return
        }
        setKeyframeNumber(keyframeNumber - 1)

    }

    /**
     * @description   first keyframe button handler. For jump to the first keyframe
     * */
    const firstKeyframeBtnHandler = () => {
        setKeyframeNumber(0)

    }

    /**
     * @description   last keyframe button handler. For jump to the last keyframe
     * */
    const lastKeyframeBtnHandler = () => {
        setKeyframeNumber(byteRenderData.length - 1)

    }

    /**
     * @description play button Handler. play or pause animation
     * */
    const playOrPauseBtnHandler = () => {
        setPlayBtnValue(!playBtnValue)
    }

    /**
     * @description    watch playBtnValue state. Timers are turned on or off depending on the playBtnValue status
     * */
    useEffect(() => {
        let timer = null // timer init
        let keyframeNum = keyframeNumber  // record keyframe number

        if(!playBtnValue) {
            // If the current keyframe is the last, start over
            if(keyframeNum == byteRenderData.length - 1) {

                setKeyframeNumber(0)
                keyframeNum = 0
            }
            timer = setInterval(()=> {

                setKeyframeNumber(++keyframeNum)
                // Stop playing if it reaches the last frame
                if(keyframeNum >= byteRenderData.length - 1) {
                    clearInterval(timer)
                    setPlayBtnValue(true)
                }
            }, 1000)
        }else {
            clearInterval(timer)
        }

        return () => clearInterval(timer)
    }, [playBtnValue]);

    /**
     * @description  Jumps to the current click keyframe
     * @param val Number
     * */
    const clickKeyframeHandler = (val) =>{
        setKeyframeNumber(val)
    }

    /**
     * @description  drag keyfame function
     * */
    const overKeyframe = (e, val) => {
        if (!isDrag) {
            return
        }

        setKeyframeNumber(val)
    }

    const mouseDownHandler = (e) => {
        setIsDrag(true)
    }

    /**
     * @description   The function used in mobile mode
     * */
    const pointerdown = () => {
        setMousedown(true);
    };

    const pointerup = () => {
        setMousedown(false);
    };

    // Move side slider drag function callback
    const handleNumbers = (index, action) => {
        if (action === "click" || (action === "drag" && mousedown)) {
            setKeyframeNumber(index);
        }
    };

    /**
     * @description   When the mouse is released globally, reset isDrag param
     * */
    window.addEventListener('mouseup', ()=> {
        setIsDrag(false)
    })


    useEffect(() => {
        if(currentDataTool.key === 1 && Object.getOwnPropertyNames(byteStageList).length !== 0) {
            const attrName = (isCombine ? 'combine' : 'separate') + (upperOrLower ? 'UpperStage' : 'LowerStage')

            let list = byteStageList[attrName] === undefined ? [] : byteStageList[attrName]

            if(upperOrLower) {

                setByteRenderData(list)
                setKeyframeNumber(list.length - 1)
            }else {
                setByteRenderData(list)
                setKeyframeNumber(list.length - 1)
            }
        }
    }, [byteStageList, upperOrLower]);


    useEffect(() => {
        if(currentDataTool.key === 0) {
            const attrName = (isCombine ? 'combine' : 'separate') + (upperOrLower ? 'UpperStage' : 'LowerStage')
            setKeyframeNumber(Object.getOwnPropertyNames(byteStageList)[attrName].length - 1)
        }

    }, [byteStageList]);

    useEffect(()=> {
        window.addEventListener("pointerdown", pointerdown, false);
        window.addEventListener("pointerup", pointerup, false);

        return () => {
            window.removeEventListener("pointerdown", pointerdown);
            window.removeEventListener("pointerup", pointerup);
        };
    }, [])

    const selectUpperOrLower = (bool) => {
        setUpperOrLower(bool)
    }

    useEffect(() => {
        if(!currentPatient.combineUpperStage) {
            setIsCombine(false)
        }else if(!currentPatient.separateUpperStage) {
            setIsCombine(true)
        }
    }, [currentPatient]);


    return (
        <div className={'playBackControlWrapper'}>
            <div className={'combineOrSeparateWrap'}>
                <div className={'combine wrap ' + ((currentPatient.combineUpperStage || currentPatient.combineLowerStage) ? (isCombine ? 'active' : '') : 'disable' )} onClick={()=> {
                    setIsCombine(true)
                    clickCombineOrSeparate(true)
                }}>
                    <span>Combine</span>
                </div>
                <div className={'separate wrap ' + ((currentPatient.separateUpperStage || currentPatient.separateLowerStage) ? (isCombine ? '' : 'active') : 'disable' )} onClick={()=> {
                    setIsCombine(false)
                    clickCombineOrSeparate(false)
                }}>
                    <span>Separate</span>
                </div>
            </div>
            <div className={'playBack'}>
                {
                    iconArr.map(item => {
                        let btnClassName = ''
                        let fileName = item.fileName

                        if(item.fileName == '171') {
                            btnClassName = 'buttonStyle btnItemStyle1'
                        } else if(item.fileName == '175') {
                            btnClassName = 'buttonStyle btnItemStyle3'
                        } else {
                            btnClassName = 'buttonStyle btnItemStyle2'
                        }

                        if(item.fileName == 172 && !playBtnValue) {
                            item.fileName = 176
                        }

                        return (
                            <button className={btnClassName} onClick={() => clickControlItem(item.id)}>
                                <span className={'buttonLabelStyle'}>
                                    {/*<img src={require('../../../../icons/png/Frame ' + item.fileName + '.png')} width={24} height={24}/>*/}
                                    <SvgIcon iconClass={item.fileName} style={item.fileName == 173 || item.fileName == 174 ? {fontSize: 20} : {}} />
                                </span>
                            </button>

                        )
                    })
                }
                <ArchPopover right={'calc(50% - 132px)'} top={'8px'} width={'310px'} height={'43px'} placement={'topLeft'} text={text} overlayClassName={'playerPopover'} />
            </div>
            {
                currentDataTool.key !== 0 ? (
                    <div className={'selectByteUpperOrLower'}>
                        <div className={'upper wrap ' + (upperOrLower ? 'active' : '')} onClick={()=> selectUpperOrLower(true)}>
                            <span>Upper</span>
                        </div>
                        <div className={'lower wrap ' + (upperOrLower ? '' : 'active')} onClick={()=> selectUpperOrLower(false)}>
                            <span>Lower</span>
                        </div>
                    </div>
                ) : <></>
            }
            <div className={'keyframeControl'}>
                <div className={'keyframePlane'}>
                    {
                        Object.getOwnPropertyNames(byteStageList).length === 0 ? <></> : (
                            byteRenderData.map((item, i)=> {

                                return(
                                    <div className={'keyframe'} onClick={() => clickKeyframeHandler(i)} key={item} onMouseOver={(e)=> overKeyframe(e, i)} onMouseDown={mouseDownHandler}>
                                        <div className={'keyframeIconBox'} style={{backgroundColor: keyframeNumber == i ? '#DCDDE0' : "transparent"}}>
                                            <div className={'keyframeIcon'} style={{backgroundColor: keyframeNumber == i ? '#8a8f98' : '#dcdde0', visibility: upperOrLower ? 'visible' : 'hidden'}}></div>
                                            <div className={'keyframeIcon'} style={{backgroundColor: keyframeNumber == i ? '#8a8f98' : '#dcdde0', visibility: !upperOrLower ? 'visible' : 'hidden'}}></div>
                                        </div>
                                        <span className={'keyframeNum'}>{item}</span>
                                    </div>
                                )
                            })
                        )
                    }
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = state => ({
    keyframeNumber: state.webViewer.keyframeNumber,
    currentPatient: state.webViewer.currentPatient,
    isMobile: state.webViewer.isMobile,
    currentDataTool: state.dataTool.currentDataTool,
    upperOrLower: state.dataTool.upperOrLower,
    isCombine: state.webViewer.isCombine
})

const mapDispatchToProps = dispatch => ({
    setKeyframeNumber: val => dispatch(action.setKeyframeNumber(val)),
    setStageCount: val => dispatch(action.setStageCount(val)),
    setUpperOrLower: val => dispatch(action.setUpperOrLower(val)),
    setIsCombine: val => dispatch(action.setIsCombine(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ByteControl)
