import React, {useEffect, useRef, useState, useMemo} from "react";
import {useLoader, useThree} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {connect} from "react-redux";
import action from "../../../store/action";
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import PropTypes from "prop-types";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import * as THREE from "three";


/**
 * @description create gum function. In order to batch creation gum
 * @param props The parameter passed by the attribute within the tag
 * */
function Gum(props) {
    const {depthWrite, opacity, url, matrix, fileType} = props
    const ref = useRef()

    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }

    })


    /**
     * @description    index is missing due to normal information, we need to recalculate index
     * */

    useEffect(()=> {
        if(ref.current) {

            ref.current.geometry.deleteAttribute("normal")
            ref.current.geometry = mergeVertices(ref.current.geometry, 0.1);
            ref.current.geometry.computeVertexNormals(true);

        }
    }, [])

    return (
        <mesh
            {...props}
            ref={ref}
            geometry={model}
            matrix={matrix}
            matrixAutoUpdate={false}
            renderOrder={0}
            // visible={keyframeNumber == itemId}
        >
            {/*<meshPhysicalMaterial*/}
            {/*    attach="material"*/}
            {/*    transparent={true}*/}
            {/*    color={'#ff9999'}*/}
            {/*    opacity={opacity}*/}
            {/*    depthWrite={depthWrite}*/}
            {/*    depthTest={true}*/}
            {/*    roughness={0.2}*/}
            {/*    metalness={0}*/}
            {/*    clearcoat={0.1}*/}
            {/*    clearcoatRoughness={0.3}*/}
            {/*    reflectivity={0.1}*/}
            {/*    emissive={'#6a1653'}*/}
            {/*    emissiveIntensity={0.2}*/}
            {/*    flatShading={false}*/}
            {/*/>*/}
            <meshStandardMaterial
                color={'#ff8888'}
                opacity={opacity}
                transparent={true}
                depthWrite={depthWrite}
                depthTest={true}
                roughness={0.4}
                metalness={0}
                reflectivity={0.1}
                emissive={'#6a1653'}
                emissiveIntensity={0.1}
            />

        </mesh>

    )
}

Gum.propTypes = {
    /**
     * ant design slider component value
     * */
    sliderVal: PropTypes.number.isRequired,
    /**
     * type is the upper or lower gum. There are two fields which is 'upper' and 'lower'
     * */
    type: PropTypes.string,
    keyframeNumber: PropTypes.number.isRequired,
    /**
     * current tooth id. For example: 'upper1' ...... 'lower1' ......
     * */
    itemId: PropTypes.number.isRequired

};

Gum.defaultProps = {
    sliderVal: 0,
    type: 'upper',
    itemId: null
};

const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    keyframeNumber: state.webViewer.keyframeNumber,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val))
})


export default connect(mapStateToProps, mapDispatchToProps)(Gum)
