const fragmentShader = `
  uniform float linewidth;
  uniform vec3 color;
  varying vec2 vUv;
  void main() {
    gl_FragColor = vec4(color, 1.0);
    gl_FragColor.a = (1.0 - step(vUv.x, linewidth)) * (1.0 - step(1.0 - vUv.x, linewidth));
  }
`;

export default fragmentShader
