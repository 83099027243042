import * as THREE from "three";
import {cloneDeep} from "lodash";


/**
 * @description
 *
 * @param {Object} mat1 (Matrix4)    The initial matrix of teeth
 * @param {Object} mat2 (Matrix4)    The current matrix of teeth
 * @param {Number} num   The current tooth number
 * @param {Object} initGumMat (Matrix4)   The initial matrix of the gum
 * @param {Object} gumMat (Matrix4)   The current matrix of the gum
 * */
export const computeModalData = (mat1, mat2, num, initGumMat,gumMat) => {
    // Due to the existence of biteshift, we need to convert the current tooth coordinate
    // system to the gum coordinate system
    let initMat4 = cloneDeep(initGumMat).invert().multiply(cloneDeep(mat1))
    let currentMat4 = cloneDeep(gumMat).invert().multiply(cloneDeep(mat2))

    // console.log(initMat4, currentMat4, 'kkkkkkkkk')

    let signObj = plusAndMinusSign(num)

    // recording init transform information
    let pos1 = new THREE.Vector3();
    let quat1 = new THREE.Quaternion();
    let scale1 = new THREE.Vector3();

    initMat4.decompose(pos1, quat1, scale1)

    // -> Local frame axes:
    // ————————————————————
    // initX = [(m00), (m10), (m20)]
    // initY = [(m01), (m11), (m21)]
    // initZ = [(m02), (m12), (m22)]

    let translateInitMatrix = pos1
    let initX = new THREE.Vector3(initMat4.elements[0], initMat4.elements[1], initMat4.elements[2])
    let initY = new THREE.Vector3(initMat4.elements[4], initMat4.elements[5], initMat4.elements[6])
    let initZ = new THREE.Vector3(initMat4.elements[8], initMat4.elements[9], initMat4.elements[10])

    // recording current transform information
    let pos2 = new THREE.Vector3();
    let quat2 = new THREE.Quaternion();
    let scale2 = new THREE.Vector3();
    currentMat4.decompose(pos2, quat2, scale2)

    let translateCurrentMatrix = pos2
    // console.log("ggggggggggg")
    /**
     * @description    calculate rotate (Rotational movement (quat1 for initial pose, quat2 for current pose))
     *
     *      euler = rotateInvertMat * quat2
     *
     *      set order 'YXZ'. In order to make the calculation consistent with unity.
     * */

    let initRotationMatrix = new THREE.Matrix4().extractRotation(initMat4);
    let currentRotationMatrix = new THREE.Matrix4().extractRotation(currentMat4);

    let invertMat = initRotationMatrix.clone().invert()

    let diffRotationMatrix = invertMat.clone().multiply(currentRotationMatrix);


    // let rotateInvertMat = quat1.invert()

    let eu = new THREE.Euler().setFromRotationMatrix(diffRotationMatrix, 'YXZ')


    /**
     * @description     calculate translate
     *
     * -> translateInitMatrix for initial pose, translateCurrentMatrix for current pose; initX, initY, initZ local frame axes for initial pose
     *
     * -> x = (translateCurrentMatrix - translateInitMatrix) dot initX
     * -> y = (translateCurrentMatrix - translateInitMatrix) dot initY
     * -> z = (translateCurrentMatrix - translateInitMatrix) dot initZ
     * */

    let mat = new THREE.Vector3(translateCurrentMatrix.x, translateCurrentMatrix.y, translateCurrentMatrix.z)

    let subs = mat.sub(translateInitMatrix)


    let dotX = subs.dot(initX)
    let dotY = subs.dot(initY)
    let dotZ = subs.dot(initZ)

    const radToEul = 180 / Math.PI
    //-7.49 1.53 5.77 lr1          2 0 0  ur1
    if(num == 21) {
        var eu1 = new THREE.Euler().setFromRotationMatrix(diffRotationMatrix, 'YXZ')

        var quatt = new THREE.Quaternion().multiplyQuaternions(quat1.invert(), quat2)

        var eu2 = new THREE.Euler().setFromQuaternion(quatt, 'YXZ')


        // console.log(diffRotationMatrix, quatt)
        // console.log('------------')
    }



    const getVal = (item, sign, rad, name) => {
        let data = sign * item  * (rad ? rad : 1)

        return data
    }

    // it calculates the plus/minus signs for the positional and rotational data using the plusAndMinusSign function.
    let obj = {
        buccal: getVal(dotX, signObj.inOutSign),
        meisal: getVal(dotY, signObj.leftRightSign),
        intrusion: getVal(dotZ, signObj.upDownSign),
        tip: getVal(eu.x, signObj.rootTipSign, radToEul),
        torque: getVal(eu.y, signObj.torqueSign, radToEul),
        rotation: getVal(eu.z, signObj.rotationSign, radToEul),
    }


    // console.log(obj)

    return obj

};

/**
 * @description   This function verifies and updates the current data
 *
 * @param {any} data
 * @param {Number} num
 * */
export const panelDataToString = (data, num=2) => {

    let val;

    if(typeof (data) !== 'number') {
        val = '-'
    }else {
        val = data.toFixed(num)
    }

    val = (val === '0.00' || val === '-0.00')  ? '0.00' : val

    return val
}

/**
 * @description This is a function called plusAndMinusSign which takes in a number as input and returns an object with
 * six properties: inOutSign, leftRightSign, upDownSign, rootTipSign, torqueSign, and rotationSign.
 *
 * The purpose of the function is to determine the signs (positive or negative) for various dental measurements based on
 * the tooth number. The function checks if the input number falls within a specific range of tooth numbers for each quadrant
 * of the mouth and sets the signs accordingly.
 *
 * For example, if the input number is between 11 and 18 (inclusive), the function assumes it is an upper right tooth and
 * sets inOutSign to -1 (meaning movement inwards), leftRightSign to 1 (meaning movement to the right), and upDownSign to 1
 * (meaning movement upwards), as well as other sign properties based on dental measurements.
 *
 * If the input number does not fall within any of the specified ranges, the function sets all sign properties to 1,
 * which represents no movement or change.
 *
 * This function can be useful in dental software or applications where the user needs to determine the correct signs for
 * different measurements based on the tooth number.
 *
 *
 *
 * @param {Number} num  Current tooth number
 * */

export const plusAndMinusSign = (num) => {
    const obj = {
        inOutSign: 1,
        leftRightSign: 1,
        upDownSign: 1,
        rootTipSign: 1,
        torqueSign: 1,
        rotationSign: 1,
    }

    if (num <= 18 && num >= 11)
    {
        //UpperRight
        obj.inOutSign = 1;
        obj.leftRightSign = 1;
        obj.upDownSign = 1;

        obj.rootTipSign = 1;
        obj.torqueSign = 1;
        obj.rotationSign = 1;
    }
    else if (num >= 21 && num <= 28)
    {
        //UpperLeft
        obj.inOutSign = 1;
        obj.leftRightSign = -1;
        obj.upDownSign = 1;

        obj.rootTipSign = -1;
        obj.torqueSign = 1;
        obj.rotationSign = -1;
    }
    else if (num >= 31 && num <= 38)
    {
        //LowerLeft
        obj.inOutSign = 1;
        obj.leftRightSign = -1;
        obj.upDownSign = -1;

        obj.rootTipSign = 1;
        obj.torqueSign = -1;
        obj.rotationSign = -1;
    }
    else if (num <= 48 && num >= 41)
    {
        //LowerRight
        obj.inOutSign = 1;
        obj.leftRightSign = 1;
        obj.upDownSign = -1;

        obj.rootTipSign = -1;
        obj.torqueSign = -1;
        obj.rotationSign = 1;
    }
    else
    {
        // wrong number
        obj.inOutSign = 1;
        obj.leftRightSign = 1;
        obj.upDownSign = 1;

        obj.rootTipSign = 1;
        obj.torqueSign = 1;
        obj.rotationSign = 1;
    }

    return obj
}

/**
 * @description The method used to calculate the end of the ipr line
 *
 * @param {Object} firstTargetMat Matrix information for the first tooth
 * @param {Object} secondTargetMat Matrix information for the second tooth
 * @param {Object} centerPosition The center point between two teeth
 * @param {Boolean} isUpper
 *
 * @return {Array}
 * */
export const getIPREndPoint = (firstTargetMat, secondTargetMat, centerPosition, isUpper) => {
    const worldMatrixInverse = new THREE.Matrix4()
    worldMatrixInverse.copy(firstTargetMat)
    const localZAxis = new THREE.Vector3(0, 0, 1).applyMatrix4(worldMatrixInverse);
    localZAxis.setY(centerPosition.y)

    const worldMatrixInverse2 = new THREE.Matrix4()
    worldMatrixInverse2.copy(secondTargetMat)
    const localZAxis2 = new THREE.Vector3(0, 0, 1).applyMatrix4(worldMatrixInverse2);
    localZAxis2.setY(centerPosition.y)

    const averageVec = new THREE.Vector3()
    averageVec.addVectors(localZAxis, localZAxis2).multiplyScalar(0.5)
    averageVec.normalize()

    let handler_center = centerPosition.clone()
    // console.log(handler_center, 'getdaze')

    handler_center.add(isUpper ? new THREE.Vector3(0,1,0).multiplyScalar(10) : new THREE.Vector3(0,-1,0).multiplyScalar(10))

    handler_center = handler_center.add(averageVec.multiplyScalar(15))

    return [handler_center.x, handler_center.y, handler_center.z]

}


export const jawOrientationDetector = (number) => {
    if(number < 30) {
        return true
    }else {
        return  false
    }
}
