// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popStyle {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #6C6D72;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.popStyle span {
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: center;
}
.ant-popover-content {
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 8px;
}
.popContentContainer {
  display: flex;
  flexDirection: row !important;
  height: 20px;
}
.popContentContainer span:nth-of-type(2) {
  line-height: 20px;
}
.ant-popover-arrow {
  display: none !important;
}
.ant-popover-inner-content {
  padding: 5px !important;
}
.ant-popover-inner-content .content {
  color: black !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  display: inline-block !important;
}
.ant-popover-inner-content .dot {
  font-weight: bolder !important;
  font-size: 18px !important;
  display: inline-block !important;
  width: 3px !important;
  bottom: 3px !important;
  left: 1px !important;
  position: relative !important;
  margin-right: 14px;
}
.ant-popover-inner {
  border-radius: 8px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ArchPopover/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AACF;AATA;EAYI,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAIA;EACE,iCAAA;EACA,kBAAA;AAFF;AAKA;EACE,aAAA;EACA,6BAAA;EACA,YAAA;AAHF;AAMI;EACE,iBAAA;AAJN;AASA;EACE,wBAAA;AAPF;AAWA;EACE,uBAAA;AATF;AAQA;EAKI,uBAAA;EACA,0BAAA;EACA,2BAAA;EAEA,gCAAA;AAXJ;AAEA;EAaI,8BAAA;EACA,0BAAA;EACA,gCAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;EACA,6BAAA;EACA,kBAAA;AAZJ;AAiBA;EACE,6BAAA;AAfF","sourcesContent":[".popStyle {\n  position: absolute;\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  background-color: #6C6D72;\n  border-radius: 50%;\n  text-align: center;\n  cursor: pointer;\n\n\n  span {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    color: white;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n.ant-popover-content {\n  box-shadow: 0 4px 4px 0 #00000040;\n  border-radius: 8px;\n}\n\n.popContentContainer {\n  display: flex;\n  flexDirection: row !important;\n  height: 20px;\n\n  span {\n    &:nth-of-type(2) {\n      line-height: 20px;\n    }\n  }\n}\n\n.ant-popover-arrow {\n  display: none !important;\n}\n\n\n.ant-popover-inner-content {\n  padding: 5px !important;\n\n\n  .content {\n    color: black !important;\n    font-size: 14px !important;\n    font-weight: 400 !important;\n    //letter-spacing: -4px !important;\n    display: inline-block !important;\n  }\n\n  .dot {\n    font-weight: bolder !important;\n    font-size: 18px !important;\n    display: inline-block !important;\n    width: 3px !important;\n    bottom: 3px !important;\n    left: 1px !important;\n    position: relative !important;\n    margin-right: 14px;\n  }\n\n}\n\n.ant-popover-inner {\n  border-radius: 8px !important;\n}\n\n@primary-color: black;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
