import React, {useRef, useState, useEffect, useContext} from 'react'
import './index.less'
import {connect} from 'react-redux'
import action from '../../../../store/action/index'
import * as THREE from 'three'
import { Input } from 'antd';
import {cloneDeep} from "lodash";
import Decimal from 'decimal.js';
import {computeModalData, jawOrientationDetector, panelDataToString, plusAndMinusSign} from '../../../../lib/threeLib'

import {
    getToothMovement,
    getGumData,
    getToothData,
    getToothMatrixByNumber,
    getGumMatrix,
    setToothMatrixAndMovementHandler,
} from '../../../../dataInterface/index'

import {useDetectedPanelData} from "../../../../dataInterface/panelData";
import eventBus from "../../../../lib/eventEmitter";
import ArchPopover from "../../../../Components/ArchPopover";

function DataPanel(props) {
    const {
        currentClickItemId,
        currentClickItemRef,
        currentClickInitItemRef,
        keyframeNumber,
        setMovementMode,
        movementMode,
        isMobile,
        editMode,
        dentalData,
        setDentalData,
        redux_movementData
    } = props

    const [euler, setEuler] = useState(new THREE.Euler())
    const [visible, setVisible] = useState(false)

    const [data, setData] = useState([])

    const [gumData, setGumData] = useState([])

    const [toothData, setToothData] = useState([])

    const [movement, setMovement] = useState([
        {
            name: 'Lingual/Buccal',
            value: '0.00',
            data: '0.00',
            type: 'translate'
        },
        {
            name: 'Mesial/Distal',
            value: '0.00',
            data: '0.00',
            type: 'translate'
        },
        {
            name: 'Intrusion/Extrusion',
            value: '0.00',
            data: '0.00',
            type: 'translate'
        },
        {
            name: 'Tip',
            value: '0.00',
            data: '0.00',
            type: 'rotate'
        },
        {
            name: 'Torque',
            value: '0.00',
            data: '0.00',
            type: 'rotate'
        },
        {
            name: 'Rotation',
            value: '0.00',
            data: '0.00',
            type: 'rotate'
        },
    ])


    useEffect(()=> {

        if(dentalData) {
            setData(cloneDeep(dentalData))
        }
    }, [dentalData])

    useEffect(() => {

        if(data && currentClickItemId !== null) {

            let isUpper = jawOrientationDetector(currentClickItemId)

            let copyGumData = cloneDeep(getGumData(data, isUpper))

            let copyToothData = cloneDeep(getToothData(copyGumData))

            setGumData(copyGumData)

            setToothData(copyToothData)

        }

    }, [data, currentClickItemId]);



    useEffect(()=> {
        if(toothData.length !== 0 && currentClickItemId) {
            let copyArr = cloneDeep(movement)

            let movementData = getToothMovement(toothData, keyframeNumber, currentClickItemId)

            copyArr.map(item=> {
                if(item.name == 'Lingual/Buccal') {
                    item.value = panelDataToString(movementData.buccal)
                    item.data = movementData.buccal

                }else if(item.name == 'Mesial/Distal') {
                    item.value = panelDataToString(movementData.meisal)
                    item.data =  movementData.meisal

                }else if(item.name == 'Intrusion/Extrusion') {
                    item.value = panelDataToString(movementData.intrusion)
                    item.data = movementData.intrusion

                }else if(item.name == 'Tip') {
                    item.value = panelDataToString(movementData.tip)
                    item.data =  movementData.tip

                }else if(item.name == 'Torque') {
                    item.value = panelDataToString(movementData.torque)
                    item.data =  movementData.torque

                }else if(item.name == 'Rotation') {
                    item.value = panelDataToString(movementData.rotation)
                    item.data =  movementData.rotation

                }
            })

            setMovement(copyArr)

        }
    }, [toothData, currentClickItemId, keyframeNumber])


    useEffect(() => {
        let copyArr = cloneDeep(movement)

        let movementData = redux_movementData

        console.log(movementData)

        copyArr.map(item=> {
            if(item.name == 'Lingual/Buccal') {
                item.value = panelDataToString(movementData.buccal)
                item.data = movementData.buccal

            }else if(item.name == 'Mesial/Distal') {
                item.value = panelDataToString(movementData.meisal)
                item.data =  movementData.meisal

            }else if(item.name == 'Intrusion/Extrusion') {
                item.value = panelDataToString(movementData.intrusion)
                item.data = movementData.intrusion

            }else if(item.name == 'Tip') {
                item.value = panelDataToString(movementData.tip)
                item.data =  movementData.tip

            }else if(item.name == 'Torque') {
                item.value = panelDataToString(movementData.torque)
                item.data =  movementData.torque

            }else if(item.name == 'Rotation') {
                item.value = panelDataToString(movementData.rotation)
                item.data =  movementData.rotation

            }
        })

        setMovement(copyArr)
    }, [redux_movementData]);



    /**
     * @description    Converts the data in the input into a matrix and saves it
     * */
    const getMatrixHandler = async (array) => {


        let initMat4 = getToothMatrixByNumber(toothData, 0, currentClickItemId).clone()

        // The initMat4 matrix is then decomposed into its translation, rotation, and scale components,
        // which are respectively assigned to pos, quat, and scale.
        let pos = new THREE.Vector3();
        let quat = new THREE.Quaternion();
        let scale = new THREE.Vector3();

        initMat4.decompose(pos, quat, scale)

        // The positive and negative signs of the data were obtained according to the tooth number.
        // Since the computeModalData function in threeLib.js converts the signs once, we need to convert
        // the signs of the data back to their original form so that we can synthesize the new matrix.
        const signObj = plusAndMinusSign(currentClickItemId)

        const vec = new THREE.Vector3(signObj.inOutSign * array[0].data, signObj.leftRightSign * array[1].data, signObj.upDownSign * array[2].data)

        // Converts local coordinates to world coordinates
        let position = vec.applyMatrix4(initMat4)

        const radToEul = 180 / Math.PI

        // We need to pay attention to order here. We use 'YXZ'.
        let euler = new THREE.Euler().set(signObj.rootTipSign * Number(array[3].data) / radToEul, signObj.torqueSign * Number(array[4].data) / radToEul, signObj.rotationSign * Number(array[5].data) / radToEul, 'YXZ')

        let quaternion = new THREE.Quaternion().setFromEuler(euler)

        quaternion = quaternion.multiplyQuaternions(quat, quaternion)

        let newMatrix = new THREE.Matrix4().compose(position, quaternion, scale)

        let obj = computeModalData(initMat4, newMatrix, currentClickItemId, getGumMatrix(gumData, 0), getGumMatrix(gumData, keyframeNumber))

        let newData = setToothMatrixAndMovementHandler(data, currentClickItemId, keyframeNumber, newMatrix, obj)


        setDentalData(cloneDeep(newData))

    }

    /**
     * @description    This function is called whenever the input value changes. It limits the characters that can be
     *  entered in the input field to digits, '.', and '-', and allows only two digits after the decimal point. It then
     *  updates the movement state with the new value.
     * */
    const onChange = (e, obj) => {
        // Limit characters that can only contain digits, '.' and '-'.
        let val = e.target.value.replace(/[^(\-\.\d) | (\-\d\.)]/g, '');


        let isContainDot = false

        for(let i = 0; i < val.length; i++) {
            // '-' can only be in the first place, if not replaced with a null character
            if(val[i] == '-' && i > 0) {
                val[i] = ''
            }

            // If there is already have a '.', delete the extra '.'
            if(isContainDot && val[i] == '.') {
                val[i] = ''
            }

            // Mark the first time '.' appears
            if(val[i] == '.') {
                isContainDot = true
            }

        }

        // User can only enter two digits after the decimal point
        if(isContainDot) {
            let length = val.split('.')[1].length

            if(length > 2) {

                const pos = val.indexOf('.')

                val = val.slice(0, pos + 3)
            }
        }


        let copyArr = cloneDeep(movement)

        copyArr.map(item=> {
            if(item.name == obj.name) {
                item.value = val
            }
        })

        setMovement(copyArr)
    }

    /**
     * @description   This function is called when the input field loses focus. It performs several checks on
     * the input value to ensure that it is in the correct format, adds decimal places if necessary, and limits
     * the input value to certain ranges. It then updates the movement state with the new value and calls the
     * getMatrixHandler function.
     * */
    const onBlur = async (obj) => {
        let val = obj.value

        if(val == '') {
            val = '0.00'
        }

        // If there is no decimal point, add two decimal places.(High priority)
        if(!val.includes('.')) {
            val = val + '.00'
        }

        let pos = val.indexOf('.')

        // If the character starts with a decimal point, a 0 is added in front of the decimal point
        if(val[0] == '.') {
            val = 0 + val
        }

        // If the decimal point is not followed by a number, add two decimal places
        if(pos + 1 == val.length) {
            val = val + '00'
        }

        // If the decimal point is followed by only one decimal place, complete it with two decimal places
        if(pos + 1 == val.length - 1) {
            val = val + '0'
        }

        // If the first character is a minus sign and the second is a decimal point, you need to add a 0 between them
        if(val[0] == '-') {
            if(val[1] == '.') {
                val = val[0] + '0' + val.slice(1)
            }
        }

        if(val == '-0.00') {
            val = '0.00'
        }

        let str = val.split('.')[0]

        // If there are any extra zeros in the character, delete them. For example, 00000080.00 -> 80.00
        if((val[0] == '-' && str.length > 2) || (val[0] !== '-' && str.length > 1)) {
            let recordPos = 0

            for(let i = 0; i < str.length; i++) {
                if(str[i] == '0') {
                    recordPos = i
                }

                if(str[i] !== '-' && str[i] !== '0') {
                    break
                }
            }

            if(val.includes('-')) {
                val = '-' + val.slice(recordPos + 1)
            }else if(val[0] === '0') {
                val = val.slice(recordPos + 1)
            }
        }

        // Limited input value
        if(Number(val) > 15 && obj.type === 'translate') {
            val = '15.00'
        }else if(Number(val) < -15 && obj.type === 'translate') {
            val = '-15.00'
        }else if(Number(val) < -80 && obj.type === 'rotate') {
            val = '-80.00'
        }else if(Number(val) > 80 && obj.type === 'rotate') {
            val = '80.00'
        }

        let copyArr = cloneDeep(movement)

        copyArr.map(item=> {
            if(item.name == obj.name) {
                item.value = Number(val).toFixed(2)
                item.data = Number(val)
            }
        })

        getMatrixHandler(copyArr)

        setMovement(copyArr)

    }


    /**
     * @description    This function is called when the user presses the Enter key. It simply calls the onBlur function.
     * */
    const onPressEnter = (e, obj) => {
        e.target.blur()
    }

    /**
     * @description    This function is called when the user clicks on a subtract button. It decreases the value of
     * the input field by 0.10 and limits the input value to certain ranges. It then updates the movement state with
     * the new value.
     * */
    const subtractHandler = async (obj) => {
        if(isMobile) {
            return
        }else {
            if(!editMode) {
                return
            }
        }



        // Disable button
        if(Number(obj.value) <= -15 && obj.type === 'translate' || Number(obj.value) <= -80 && obj.type === 'rotate') {
            return
        }

        let copyArr = cloneDeep(movement)

        copyArr.map(item=> {
            if(item.name == obj.name) {
                let val1 = item.value
                let val2 = item.value

                if(obj.type == 'translate') {
                    item.value = new Decimal(val1).minus(0.10).toFixed(2)
                    item.data = Number(new Decimal(val2).minus(0.1))
                }else {
                    item.value = (Number(val1) - 0.5).toFixed(2)
                    item.data = Number(val2) - 0.5
                }
            }
        })

        setMovement(copyArr)

        getMatrixHandler(copyArr)

    }

    /**
     * @description    The plusHandler function is called when the user clicks on a plus button. It adds the value
     * of the input field by 0.10 and limits the input value to certain ranges. Then it updates the movement
     * state with the new value.
     * */
    const plusHandler = (obj) => {

        if(isMobile) {
            return
        }else {
            if(!editMode) {
                return
            }
        }

        // Disable button
        if(Number(obj.value) >= 15 && obj.type === 'translate' || Number(obj.value) >= 80 && obj.type === 'rotate') {
            return
        }

        let copyArr = cloneDeep(movement)

        copyArr.map(item=> {
            if(item.name == obj.name) {
                let val1 = item.value
                let val2 = item.value

                if(obj.type == 'translate') {

                    item.value = new Decimal(val1).plus(0.10).toFixed(2)
                    item.data = Number(new Decimal(val2).plus(0.1))
                }else {
                    item.value = new Decimal(val1).plus(0.50).toFixed(2)
                    item.data = Number(new Decimal(val2).plus(0.5))
                }
            }
        })

        setMovement(copyArr)

        getMatrixHandler(copyArr)
    }

    const openModal = () => {
        setVisible(true)
    }

    const closeModal = (value) => {
        setVisible(value)
    }

    return (
        <div className={'dataPanelWrapper'} style={{display: currentClickItemId ? 'block' : "none"}}>
            <div className={'dataPanelContainer'}>
                <div className={'dataPanelTitle'}>
                    <div style={{display: 'inline-block'}}>Movement</div>
                    <ArchPopover right={'10px'} top={'10px'} width={'260px'} height={'23px'} overlayClassName={"dataPanelPopover"} placement={'topLeft'} text={["View/edit tooth movement."]} />
                </div>
                <ul className={'dataPanel'}>
                    {
                        movement.map((item,i)=> {
                            return(
                                <li className={'rowData'}>
                                    <span>{item.name}</span>
                                    <div style={{display: "flex", alignItems: 'center'}}>
                                        <div
                                            className={'button subtract'}
                                            onClick={()=> subtractHandler(item)}
                                            style={{
                                                backgroundColor: isMobile || !editMode ? '#f5f5f5' : 'white',
                                                cursor: isMobile || !editMode ? 'not-allowed' : 'pointer',
                                            }}
                                        >
                                            <span>-</span>
                                        </div>
                                        <Input  defaultValue={''} value={item.value} disabled={isMobile || !editMode} onChange={(e)=> onChange(e, item)} onPressEnter={(e)=> onPressEnter(e, item)} onBlur={()=> onBlur(item)} />
                                        <div
                                            className={'button plus'}
                                            onClick={()=> plusHandler(item)}
                                            style={{
                                                backgroundColor: isMobile || !editMode ? '#f5f5f5' : 'white',
                                                cursor: isMobile || !editMode ? 'not-allowed' : 'pointer',
                                            }}
                                        >
                                            <span>+</span>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    currentClickItemId: state.webViewer.currentClickItemId,
    panelData: state.webViewer.panelData,
    toothData: state.webViewer.toothData,
    gumData: state.webViewer.gumData,
    keyframeNumber: state.webViewer.keyframeNumber,
    currentClickItemRef: state.webViewer.currentClickItemRef,
    currentClickInitItemRef: state.webViewer.currentClickInitItemRef,
    movementMode: state.webViewer.movementMode,
    isMobile: state.webViewer.isMobile,
    editMode: state.webViewer.editMode,
    redux_movementData: state.webViewer.movementData
})

const mapDispatchToProps = dispatch => ({
    setPanelData: val => dispatch(action.setPanelData(val)),
    setToothData: val => dispatch(action.setToothData(val)),
    setMovementMode: val => dispatch(action.setMovementMode(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataPanel)
