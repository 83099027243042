import axios from "axios";
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

const instance = axios.create({
    baseURL: 'https://localhost:3308',
    timeout: 60000,
    headers: {
        Authorization: 'Waiting for setup'
    }
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // The action before the request is sent

    return config;
}, function (error) {
    // Request error

    return Promise.reject(error);
});


// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Action when the response is successful
    return response.data;
}, function (error) {
    // Response error

    // // When an error occurs, pop up a modal to let the user know
    // const modal = () => {
    //     const [isModalOpen, setIsModalOpen] = useState(false);
    //     const showModal = () => {
    //         setIsModalOpen(true);
    //     };
    //     const handleOk = () => {
    //         setIsModalOpen(false);
    //     };
    //     const handleCancel = () => {
    //         setIsModalOpen(false);
    //     };
    //     return (
    //         <>
    //             <Button type="primary" onClick={showModal}>
    //                 Open Modal
    //             </Button>
    //             <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
    //                 <p>Some contents...</p>
    //                 <p>Some contents...</p>
    //                 <p>Some contents...</p>
    //             </Modal>
    //         </>
    //     );
    // };
    // modal()
    return Promise.reject(error);
});



export default instance
