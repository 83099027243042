import React, {useEffect, useRef, useState, useMemo} from "react";
import {useLoader, useThree} from "@react-three/fiber";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {OBJLoader} from "three/addons/loaders/OBJLoader";
import {connect} from "react-redux";
import action from "../../../store/action";
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import PropTypes from "prop-types";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import * as THREE from 'three'


/**
 * @description create gum function. In order to batch creation gum
 * @param props The parameter passed by the attribute within the tag
 * */

const SmarteeOrByteModel = React.memo(function Model(props) {

    const {depthWrite, url, visible, fileType, isUpper, sliderVal} = props

    const ref = useRef()

    let loader = STLLoader

    if(fileType === 'drc') {
        loader = DRACOLoader
    }else if(fileType === 'obj') {
        loader = OBJLoader
    }

    let model = useLoader(loader, url, loaderInstance=> {

        if(loaderInstance instanceof DRACOLoader) {

            loaderInstance.setDecoderPath('/draco/');
            loaderInstance.setDecoderConfig({ type: 'js' });
        }
    })

    /**
     * @description adjust model opacity
     * */
    const [opacity, setOpacity] = useState(1)

    useEffect(() => {
        if (!isUpper && Number(sliderVal) < 0) {
            setOpacity((100 - Math.abs(sliderVal)) * 0.01)
        } else if (isUpper && Number(sliderVal) > 0) {
            setOpacity((100 - sliderVal) * 0.01)
        } else {
            setOpacity(1)
        }
    }, [sliderVal]);

    if(fileType === 'obj') {
        let material = new THREE.MeshPhysicalMaterial({
            attach:"material",
            transparent: true,
            color: 'lightgrey',
            opacity: opacity,
            depthWrite: depthWrite,
            depthTest: true,
            roughness: 0.2,
            metalness: 0,
            clearcoat: 0.1,
            clearcoatRoughness: 0.3,
            reflectivity: 0.1,
            emissive: '#6a1653',
            emissiveIntensity: 0.2,
            flatShading: false,
        })

        model.traverse((child) => {
            console.log(child)
            if (child.isMesh) {
                child.material = material;
            }
        });
    }


    /**
     * @description    index is missing due to normal information, we need to recalculate index
     * */

    useEffect(()=> {
        if(ref.current) {

            ref.current.geometry.deleteAttribute("normal")
            ref.current.geometry = mergeVertices(ref.current.geometry, 0.1);
            ref.current.geometry.computeVertexNormals(true);

        }
    }, [])

    useEffect(() => {
        console.log(ref.current)
    }, [ref.current]);




    return (
        fileType === 'obj' ? (
            <primitive object={model} visible={visible} rotation={[-Math.PI / 2, 0, 0]} />
        ) : (
            <mesh
                {...props}
                ref={ref}
                geometry={model}
                rotation={isUpper ? [Math.PI / 2, 0, Math.PI] : [-Math.PI / 2, 0, 0]}
                // matrixAutoUpdate={false}
                renderOrder={0}
                visible={visible}
                // visible={keyframeNumber == itemId}
            >
                <meshPhysicalMaterial
                    attach="material"
                    transparent={true}
                    color={'lightgrey'}
                    opacity={opacity}
                    depthWrite={depthWrite}
                    depthTest={true}
                    roughness={1}
                    metalness={0}
                    clearcoat={0.1}
                    clearcoatRoughness={0.3}
                    reflectivity={0.1}
                    emissive={'#6a1653'}
                    emissiveIntensity={0.2}
                    flatShading={false}
                />

            </mesh>
        )

    )
})


const mapStateToProps = state => ({
    sliderVal: state.webViewer.sliderVal,
    view: state.webViewer.view,
    keyframeNumber: state.webViewer.keyframeNumber,
})

const mapDispatchToProps = dispatch => ({
    setView: val => dispatch(action.setView(val))
})


export default connect(mapStateToProps, mapDispatchToProps)(SmarteeOrByteModel)
