import Types from '../../action/types';

// Define a default value for the state, and be careful to be consistent with the values of the data structures here
const defaultState = {
    currentDataTool: {
        label: 'WebViewer data tool',
        key: 0,
    },
    upperOrLower: false,
    currentToothInformation: {}
}

/**
 * action handlers，The processing action returns the state
 */
export default function dataTool(state = defaultState, action) {
    switch (action.type) {
        case Types.DATA_TOOL:
            return  {
                ...state,
                currentDataTool: action.currentDataTool
            }
        case Types.UPPER_OR_LOWER:
            return  {
                ...state,
                upperOrLower: action.upperOrLower
            }
        case Types.CURRENT_TOOTH_INFORMATION:
            return  {
                ...state,
                currentToothInformation: action.currentToothInformation
            }
        default:
            return state;
    }
}
