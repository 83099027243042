import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";
import { motion } from "framer-motion";
import action from "../../../../../store/action";
import {connect} from "react-redux";

const StyledSlider = styled(ReactSlider)`
  width: 260px;
  height: 14px;
  //background: rgba(138, 143, 152, 0.41);
`;

const StyledThumb = styled(motion.div)`
  height: 30px;
  line-height: 25px;
  width: 30px;
  top: -8px;
  text-align: center;
  background-color: #ffffff;
  color: #8a8f98;
  font-weight: 700;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: 3px solid #696e78;
`;

const Thumb = (props, state) => (
    <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const getColor = (props, state) => {
    if (props.value >= 8) return "linear-gradient(to left, #333399, #ff00cc)";
    return props.index === 1 ? "rgba(255, 255, 255, 0.4)" : "#00e1ff";
};

const StyledTrack = styled(motion.div)`
  top: 0;
  bottom: 0;
  
  background: #A9ADB4;
  border-radius: 999px;
`;

const Track = (props, state) => (
    <StyledTrack {...props} {...state} index={state.index} />
);

function MobileSlider(props) {
    const { className, currentStep, ...sliderProps } = props;
    return (
        <div className={className}>
            <StyledSlider
                {...sliderProps}
                marks
                renderTrack={Track}
                renderThumb={Thumb}
            />
            <div
                style={{
                    display: "flex",
                    fontWeight: 500,
                    justifyContent: "space-between",
                    marginTop: 10,
                }}
            >
                <div>Start</div>
                <div>
                    Step {currentStep - 1}/{props.max}
                </div>
                <div>Finish</div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    setKeyframeNumber: val => dispatch(action.setKeyframeNumber(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSlider)
