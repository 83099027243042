import React, {useRef, useState} from 'react'
import {Modal} from 'antd';
import Draggable from 'react-draggable';
import { Resizable } from "re-resizable";
import { Rnd } from "react-rnd";
import './index.less'

function DragModal(props) {
    /**
     * @description    This component is a draggable modal box.
     *
     * */

    const {
        visible=false,
        closeModal,
        width="450px",
        wrapClassName,
        titleText="Draggable Modal",
        onResize,
        onResizeStart,
        onResizeStop,
        height,
        target=null,
        needResize=false,
        minWidth=window.innerWidth / 4,
        centered=false,
        footer=null
    } = props


    // Set the drag method
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const [disabled, setDisabled] = useState(false);

    const [resizableWidth, setResizableWidth] = useState(width || 0);
    const defaultMinSize = 400

    const draggleRef = useRef(null);

    // Close the mode box
    const handleCancel = () => {
        closeModal(false);
    };

    const onStart = (_event, uiData) => {
        console.log(_event)
        _event.preventDefault()
        _event.stopPropagation()

        const { clientWidth, clientHeight } = window.document.documentElement;

        let targetRect;

        if(target !== null) {
            targetRect = document.querySelector(target).getBoundingClientRect();
        }else {
            targetRect = draggleRef.current?.getBoundingClientRect()
        }



        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });

        // console.log( -targetRect.left + uiData.x, clientWidth - (targetRect.right - uiData.x), -targetRect.top + uiData.y, clientHeight - (targetRect.bottom - uiData.y),)
    };






    return (
        <>

            <Modal
                wrapClassName={wrapClassName}
                transitionName={''}
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                    >
                        {titleText}
                    </div>
                }
                mask={false}
                maskClosable={false}
                footer={footer}
                width={width}

                open={visible}
                onCancel={handleCancel}
                centered={centered}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                        onDrag={(e, d)=> {e.preventDefault()}}
                        onStop={(e, d)=> {e.preventDefault()}}
                    >
                        <div ref={draggleRef}>
                            {
                                needResize ? (
                                    <Rnd
                                        default={{
                                            x: 0,
                                            y: 0,
                                            width: '100%',
                                            height: window.innerWidth / 3.3 + 70
                                        }}
                                        disableDragging={true}
                                        className={'dragAndResize'}
                                        minWidth={minWidth}
                                        onResize={onResize}
                                        onResizeStart={onResizeStart}
                                        onResizeStop={onResizeStop}
                                    >
                                        {modal}
                                    </Rnd>
                                ) : (modal)
                            }

                        </div>

                    </Draggable>
                )}
            >
                {props.children}
            </Modal>
        </>
    );
}

export default DragModal
